import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Directive({
  selector: '[appParseCurrency]'
})
export class ParseCurrencyDirective {
  @Input() fraction = 0;
  @Input() allowNegative = false;
  private lastValue = '';

  constructor(
    private decimalPipe: DecimalPipe,
    private elementRef: ElementRef,
    private ngControl: NgControl
  ) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const negativeRegex = this.allowNegative ? '-?' : '';
    const regexStr =
      this.fraction > 0
        ? `^$|^${negativeRegex}[0-9]*(,[0-9]{0,${this.fraction}})?$`
        : `^$|^${negativeRegex}[0-9]*([0-9]{0,${this.fraction}})?$`;

    const regex = new RegExp(regexStr);
    if (!value.match(regex)) {
      this.ngControl.control?.patchValue(this.lastValue);
      return;
    }

    this.lastValue = value;
  }

  @HostListener('focus', ['$event.target.value'])
  onfocus(value: string) {
    this.element.value = this.parse(value, this.fraction);
  }

  @HostListener('blur', ['$event.target.value'])
  onblur(value: string) {
    let parseValue = this.parseLocaleNumber(value);

    if (this.fraction === 0) {
      parseValue = !isNaN(parseValue) ? Math.trunc(parseValue) : parseValue;
    }

    this.element.value = !isNaN(parseValue)
      ? this.decimalPipe.transform(
          parseValue,
          `1.${this.fraction}-${this.fraction}`
        )
      : '';
  }

  get element() {
    return this.elementRef.nativeElement;
  }

  private parseLocaleNumber(number: string) {
    return parseFloat(String(number).replace(/\./g, '').replace(',', '.'));
  }

  private parse(value: string, fractionSize: number = 2): string {
    const PADDING = '000000';
    const THOUSANDS_SEPARATOR = '.';
    const DECIMAL_SEPARATOR = ',';
    let [integer, fraction = ''] = (value || '').split(DECIMAL_SEPARATOR);

    integer = integer.replace(new RegExp(`\\${THOUSANDS_SEPARATOR}`, 'g'), '');

    fraction =
      parseInt(fraction, 10) > 0 && fractionSize > 0
        ? DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
        : '';

    return integer + fraction;
  }
}
