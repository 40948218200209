export class ErrorHandler {
  message: string;
  type: string;
  errorCode?: string;
  btnText?: string;

  constructor(
    message: string,
    type: string,
    errorCode: string,
    btnText?: string
  ) {
    this.message = message;
    this.type = type;
    this.errorCode = errorCode;
    this.btnText = btnText;
  }
}
