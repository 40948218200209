import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  HttpRequest,
  HttpResponse,
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { CustomHttpParams } from './custom-http-params.class';
import { LoaderService } from '../components/loader/loader.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class HttpService implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let loaderUse = false;

    if (req.params instanceof CustomHttpParams && req.params.loaderUse) {
      loaderUse = req.params.loaderUse;
      this.showLoader();
    }

    return next.handle(req).pipe(
      tap((event) => {
        if (
          event instanceof HttpResponse &&
          !req.url.startsWith('/assets/') &&
          loaderUse
        ) {
          this.hideLoader();
        }
      }),
      catchError((error: any, caught: any) => {
        // if (error instanceof HttpErrorResponse) {
        //     this.hideLoader();
        // } else if (error.name === 'TimeoutError') {
        // }
        this.hideLoader();

        return throwError(error);
      })
    );
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
