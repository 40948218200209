import { Component, OnDestroy, OnInit } from '@angular/core';
import { PichBaseComponent } from '../pichincha-base.component';
import { Router } from '@angular/router';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { MiddlewareService } from '../../../shared/services/middleware.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { PersonalDataService } from '../../services/personal.data.service';
import { ToolboxService } from '../../../shared/services/toolbox/toolbox.service';
import {
  ACTION,
  ERROR_REQUIRED_ORIGINS,
  PATHS
} from './../../../shared/constants';
import { PersonalInfoModel } from '../../model/params.model';
import { CountryModel } from '../../../shared/model/api/country.model';
import { OptionsType } from '../../../shared/model/option-type.model';

@Component({
  selector: 'app-pich-origen-destiny',
  templateUrl: './pich-origen-destiny.component.html',
  styleUrls: ['./pich-origen-destiny.component.scss']
})
export class PichOrigenDestinyComponent
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  public prevStage = PATHS.personalData1;
  personalData: PersonalInfoModel;

  formData: FormGroup;

  countries: CountryModel[] = [];
  relationshipList: OptionsType[] = [];
  movementTypeList: OptionsType[] = [];
  idOriDest: any = [];

  HTML_VALIDATORS = {
    minLength: 2,
    maxLength: {
      fullName: 50,
      country: 50,
      relationship: 50,
      sendOrReception: 200,
      conceptOriDest: 80
    }
  };
  isPanama: boolean;

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private formbuilder: FormBuilder,
    public commonDataService: CommonDataService,
    private readonly toolboxService: ToolboxService,
    private middlewareService: MiddlewareService,
    protected confirmationService: ConfirmationService,
    private personalDataService: PersonalDataService
  ) {
    super(routerC, pichCService, confirmationService);
    this.relationshipList = [
      { id: 'Titular', name: 'onboarding_relationship_type_1' },
      { id: 'Cotitular', name: 'onboarding_relationship_type_2' },
      { id: 'Lugar de Trabajo', name: 'onboarding_relationship_type_3' },
      { id: 'Negocio Propio', name: 'onboarding_relationship_type_4' },
      { id: 'Familiar', name: 'onboarding_relationship_type_5' },
      { id: 'Proveedor', name: 'onboarding_relationship_type_6' },
      { id: 'Cliente', name: 'onboarding_relationship_type_7' }
    ];
    this.movementTypeList = [
      { id: 'Envía', name: 'onboarding_movement_type_1' },
      { id: 'Recibe', name: 'onboarding_movement_type_2' },
      { id: 'Envía y Recibe', name: 'onboarding_movement_type_3' }
    ];
  }

  ngOnInit(): void {
    this.createForm();
    this.loadDropDownData();
    this.canNavigateToBack(false);
  }

  ngOnDestroy() {
    this.canNavigateToBack(true);
  }

  get control() {
    return this.formData.controls;
  }

  public getAction(): string {
    return ACTION.savePersonalData;
  }

  private createForm() {
    this.formData = this.formbuilder.group({
      fullName: [
        '',
        [
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.fullName)
        ]
      ],
      country: [
        '',
        [
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.country)
        ]
      ],
      relationship: [
        '',
        [
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.relationship)
        ]
      ],
      sendOrReception: [
        '',
        [
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.sendOrReception)
        ]
      ],
      conceptOriDest: [
        '',
        [
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.conceptOriDest)
        ]
      ]
    });
  }

  addMovement() {
    if (this.formData.valid) {
      if (this.idOriDest.length < 5) {
        this.idOriDest = [...this.idOriDest, this.formData.value];

        this.formData.reset();
      }
    } else {
      this.formData.markAllAsTouched();
    }
  }

  deleteMovement(index: any) {
    const orisDesData = [...this.idOriDest];
    orisDesData.splice(index, 1);
    this.idOriDest = orisDesData;
  }

  private getPersonalInfo(): PersonalInfoModel {
    return {
      firstName: this.personalData.firstName,
      middleName: ' ',
      lastName: this.personalData.lastName,
      secondLastName: this.personalData.secondLastName,
      birthDate: this.personalData.birthDate,
      cityOfResidence: this.personalData.cityOfResidence,
      countryOfResidence: this.personalData.countryOfResidence,
      residentialAddress: this.personalData.residentialAddress,
      countryOfBirth: this.personalData.countryOfBirth,
      nationality: this.personalData.nationality,
      passportNumber: this.personalData.passportNumber,
      expiryDate: this.personalData.expiryDate,
      countryOfExpedition: this.personalData.countryOfExpedition,
      documentType: 'PASAPORTE',
      documentNumber: this.personalData.documentNumber,
      taxDocumentNumber: this.personalData.taxDocumentNumber,
      gender: this.personalData?.gender,
      maritalStatus: this.personalData?.maritalStatus,
      oriDesTable: [...this.idOriDest],
      step: '3',
      createBeneficiaryTable: this.personalData?.createBeneficiaryTable,
      requiresBen: this.personalData?.requiresBen
    };
  }

  loadDropDownData() {
    this.toolboxService.getCountries().subscribe(
      (data) => {
        this.countries = data;
        this.loadData();
      },
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  loadData() {
    this.middlewareService.getUsersLookup().subscribe((data) => {
      if (data?.targetCountry == 'L_PANBM') {
        this.isPanama = true;
      } else {
        this.isPanama = false;
      }
      this.idOriDest = data?.oriDesTable ?? [];

      return (this.personalData = data);
    });
    this.pichCService.getDocumentsStatus().subscribe(
      (data) => {},
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  //RESOLVE
  onNext() {
    if (this.idOriDest.length > 0 && this.idOriDest.length <= 5) {
      this.middlewareService.setPersonalInfo(this.getPersonalInfo()).subscribe(
        (data) => {
          this.personalDataService.setPersonalData(this.getPersonalInfo());
          this.routerC.navigate(['onboarding', PATHS.activity]);
        },
        (error) => {
          this.setErrorMessages(error.responseCode);
        }
      );
    } else {
      this.setErrorMessages(ERROR_REQUIRED_ORIGINS.ORIGIN_NOT_FOUND_ERROR);
    }
  }

  onBackStage() {
    this.routerC.navigate(['onboarding', PATHS.personalData2]);
  }
}
