import { Injectable } from '@angular/core';
import { combineLatest, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import {
  L1Model,
  L2Model,
  SecureLoginDataModel
} from '../../model/crypto/crypto.models';

declare global {
  interface Document {
    documentMode?: any;
  }
  interface Window {
    msCrypto?: any;
  }
}

const WebCrypto: Crypto = window.crypto || window['msCrypto'];

@Injectable()
export class CryptoService {
  private readonly publicKey = environment.publicKey;

  private sKeyL1: string;
  private sKeyL2: string;

  constructor() {
    this.sKeyL1 = this.generateRandomKey();
    this.sKeyL2 = this.generateRandomKey();
  }

  public getSecureLogin(
    username: string,
    password: string,
    timestamp: string
  ): Observable<SecureLoginDataModel> {
    const sKeyL1$ = this.encryptRSA(this.sKeyL1);
    const sKeyL2$ = this.encryptRSA(this.sKeyL2);
    const l1Model = this.generateL1Model(username, password, timestamp);
    const l1Encrypted$ = this.encryptAES(l1Model, this.sKeyL1);

    const l2Encrypted$ = combineLatest(l1Encrypted$, sKeyL1$).pipe(
      map<any, any>((values: Array<string>) =>
        this.generateL2Model(username, values[0], values[1], timestamp)
      ),
      switchMap((l2Model: L2Model) => this.encryptAES(l2Model, this.sKeyL2))
    );

    const secureLoginData$ = combineLatest(l2Encrypted$, sKeyL2$).pipe(
      map<any, any>(
        (values: Array<string>) =>
          <SecureLoginDataModel>{
            keyToken2: values[1],
            body: {
              encryptedTokenL2: values[0]
            }
          }
      )
    );

    return secureLoginData$;
  }

  private generateRandomKey() {
    let text = '';
    const possible = '0123456789abcdef';

    for (let i = 0; i < 16; i++) {
      let array = new Uint32Array(1);
      const max = Math.pow(2, 32);
      text += possible.charAt(
        Math.floor((crypto.getRandomValues(array)[0] / max) * possible.length)
      );
    }

    return text;
  }

  /**
   * AES
   */
  encryptAES(model: L1Model | L2Model, randomKey: string) {
    const serializedModel = JSON.stringify(model);
    const encrypt = (key: CryptoKey) =>
      toObservable<ArrayBuffer>(
        WebCrypto.subtle.encrypt(
          <any>{
            name: 'AES-CBC',
            iv: str2ab('RandomInitVector'),
            hash: { name: 'SHA-1' }
          },
          key,
          str2ab(serializedModel)
        )
      );

    return this.importAESKey(randomKey).pipe(
      switchMap(encrypt),
      map((result) => btoa(ab2str(result)))
    );
  }

  private importAESKey(randomKey: string) {
    const publicKeyBuffer = str2ab(randomKey);
    const algorithm = { name: 'AES-CBC', hash: { name: 'SHA-1' } };
    const promise = WebCrypto.subtle.importKey(
      'raw',
      publicKeyBuffer,
      algorithm,
      true,
      ['encrypt', 'decrypt']
    );

    return toObservable<CryptoKey>(promise);
  }

  private encryptRSA(value: string) {
    const encrypt = (key: CryptoKey) => this.generateEncrypt(value, key);

    return this.importRSAPublicKey().pipe(
      switchMap(encrypt),
      map((buffer) => btoa(ab2str(buffer)))
    );
  }

  private generateEncrypt(value: string, key: CryptoKey) {
    const valueBuffer: ArrayBuffer = str2ab(value);
    const promise = WebCrypto.subtle.encrypt(
      <any>{ name: 'RSA-OAEP', hash: { name: 'SHA-1' } },
      key,
      valueBuffer
    );

    return toObservable<ArrayBuffer>(promise);
  }

  private importRSAPublicKey() {
    const publicKeyBuffer = str2ab(atob(this.publicKey));

    const algorithm = {
      name: 'RSA-OAEP',
      hash: { name: 'SHA-1' }
    };

    const promise = WebCrypto.subtle.importKey(
      'spki',
      publicKeyBuffer,
      algorithm,
      false,
      ['encrypt']
    );

    return toObservable<CryptoKey>(promise);
  }

  /**
   * Generate models
   */
  private generateL1Model(
    username: string,
    password: string,
    timestamp: string
  ): L1Model {
    return {
      grantType: 'password',
      username,
      password,
      timestamp,
      trackingInfo: {
        loginMode: 0,
        deviceId: '',
        pibankVersionCode: '',
        pibankVersionName: '',
        timeZone: '',
        versionSO: ''
      }
    };
  }

  private generateL2Model(
    username: string,
    encryptedL1: string,
    sKeyL1RSA: string,
    timestamp: string
  ): L2Model {
    return {
      key1: sKeyL1RSA,
      username,
      token1: encryptedL1,
      timestamp
    };
  }
}

/**
 * UTILS
 */
export function str2ab(str: string): ArrayBuffer {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);

  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export function ab2str(buf: any): string {
  const listNum: any = new Uint8Array(buf);
  return String.fromCharCode.apply(null, listNum);
}

export function toObservable<T>(object: Promise<T> | PromiseLike<T> | any) {
  let result$;
  const isIE = !!document['documentMode'];

  if (isIE) {
    result$ = Observable.create(
      (observer: {
        next: (arg0: any) => void;
        complete: () => void;
        error: (arg0: any) => void;
      }) => {
        object.oncomplete = (event: { target: { result: any } }) => {
          observer.next(event.target.result);
          observer.complete();
        };

        object.onerror = (event: { target: { result: any } }) => {
          observer.error(event.target.result);
          observer.complete();
        };
      }
    );
  } else {
    result$ = from(object);
  }

  return <Observable<T>>result$;
}
