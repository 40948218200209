import { VideoIdentificationComponent } from './components/video-identification/video-identification.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PichinchaContainerRoutingModule } from './pichincha-container-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PichinchaContainerComponent } from './containers/pichincha-container.component';
import { PichWelcomeComponent } from './components/pich-welcome/pich-welcome.component';
import { PichStagesComponent } from './components/pich-stages/pich-stages.component';
import { PichInitialDataComponent } from './components/pich-initial-data/pich-initial-data.component';
import { CommonModule } from '@angular/common';
import { PichContactDataComponent } from './components/pich-contact-data/pich-contact-data.component';
import { PichDocumentTypeComponent } from './components/pich-document-type/pich-document-type.component';
import { PichIdentityManualConfirmationComponent } from './components/pich-identity-manual-confirmation/pich-identity-manual-confirmation.component';
import { PichAddressDataComponent } from './components/pich-address-data/pich-address-data.component';
import { PichActivityComponent } from './components/pich-activity/pich-activity.component';
import { PichSignContractComponent } from './components/pich-sign-contract/pich-sign-contract.component';
import { PichContractViewComponent } from './components/pich-contract-view/pich-contract-view.component';
import { PichCompletedComponent } from './components/pich-completed/pich-completed.component';
import { PichCommonHttpService } from './services/pichincha-common-http.service';
import { PichBaseComponent } from './components/pichincha-base.component';

import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PichModalGenericComponent } from '../shared/components/modals/pich-modal-generic/pich-modal-generic.component';
import { PichPersonalData1Component } from './components/pich-personal-data-1/pich-personal-data-1.component';
import { PichPersonalData2Component } from './components/pich-personal-data-2/pich-personal-data-2.component';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AccountOwnerComponent } from './components/account-owner/account-owner.component';
import { InstructionIdentityManualComponent } from './shared/modals/instruction-identity-manual/instruction-identity-manual.component';
import { PichModalConfirmSignatureComponent } from './components/pich-contract-view/modals/pich-modal-confirm-signature/pich-modal-confirm-signature.component';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { OtpModalComponent } from './components/otp-modal/otp-modal.component';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { RiskMatrixErrorComponent } from './components/risk-matrix-error/risk-matrix-error.component';
import { NgxMaskModule } from 'ngx-mask';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from './../../environments/environment';
import { EmailModalComponent } from './components/email-modal/email-modal.component';
import { PichActivity3Component } from './components/pich-activity-3/pich-activity-3.component';
import { PichActivity2Component } from './components/pich-activity-2/pich-activity-2.component';
import { PersonalDataService } from './services/personal.data.service';
import { KYCDataService } from './services/kyc.data.service';
import { PichContractUploadComponent } from './components/pich-contract-upload/pich-contract-upload.component';
import { PichFinalStepComponent } from './components/pich-final-step/pich-final-step.component';
import { DocumentReviewComponent } from './components/pich-document-review/pich.document.review.component';
import { PichAdobeSignViewComponent } from './components/pich-adobe-sign/pich-adobe-sign.component';
import { PichOrigenDestinyComponent } from './components/pich-origen-destiny/pich-origen-destiny.component';
import { PichCalculatedTransactionalProfileComponent } from './components/pich-calculated-transactional-profile/pich-calculated-transactional-profile.component';
import { PichPersonalModalComponent } from './components/pich-personal-modal/pich-personal-modal.component';

import { RadioButtonModule } from 'primeng/radiobutton';

import { TooltipModule } from 'primeng/tooltip';
import { PichStatusModalComponent } from './components/pich-welcome/pich-status-modal/pich-status-modal.component';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  declarations: [
    PichinchaContainerComponent,
    PichWelcomeComponent,
    PichStagesComponent,
    PichInitialDataComponent,
    AccountOwnerComponent,
    PichContactDataComponent,
    PichDocumentTypeComponent,
    PichIdentityManualConfirmationComponent,
    PichAddressDataComponent,
    PichActivityComponent,
    PichActivity2Component,
    PichActivity3Component,
    PichSignContractComponent,
    PichContractViewComponent,
    PichCompletedComponent,
    PichPersonalData1Component,
    PichPersonalData2Component,
    InstructionIdentityManualComponent,
    PichModalConfirmSignatureComponent,
    OtpModalComponent,
    EmailModalComponent,
    CreatePasswordComponent,
    RiskMatrixErrorComponent,
    VideoIdentificationComponent,
    PichContractUploadComponent,
    DocumentReviewComponent,
    PichFinalStepComponent,
    PichAdobeSignViewComponent,
    PichOrigenDestinyComponent,
    PichCalculatedTransactionalProfileComponent,
    PichPersonalModalComponent,
    PichStatusModalComponent
  ],
  imports: [
    CommonModule,
    PichinchaContainerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    ClipboardModule,
    NgxMaskModule.forRoot({ validation: false }),
    SharedModule,
    CalendarModule,
    PdfViewerModule,
    GooglePlaceModule,
    MultiSelectModule,
    NgxIntlTelInputModule,
    AutoCompleteModule,
    DialogModule,
    RecaptchaV3Module,
    RadioButtonModule,
    TooltipModule,
    MessagesModule
  ],
  exports: [PichWelcomeComponent, PichStagesComponent],
  providers: [
    PichCommonHttpService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RECAPTCHA_KEY
    },
    PersonalDataService,
    KYCDataService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    PichModalGenericComponent,
    InstructionIdentityManualComponent,
    PichModalConfirmSignatureComponent
  ]
})
export class PichinchaContainerModule {}
