import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { KYCData } from '../model/params.model';

@Injectable()
export class KYCDataService {
  private kycData$ = new BehaviorSubject<KYCData>({
    occupation: '',
    position: '',
    companyJob: '',
    economicActivity: '',
    companyYears: 0,
    officeAddress: '',
    annualRevenue: 0,
    mainSourceRevenue: '',
    annualSalary: 0,
    annualRent: 0,
    annualInvestments: 0,
    otherAnnualRevenue: 0,
    detailOtherRevenue: '',
    detailAssets: '',
    accountPurpose: '',
    incomingTransfersMonth: 0,
    balanceIncomingTransfersMonth: 0,
    outcomingTransfersMonth: 0,
    balanceOutcomingTransfersMonth: 0,
    expectedInitialDeposit: 0,
    sourceWealth: '',
    startDate: '',
    typeOfDeposit: '',
    amountLimit: 0,
    taxResidence: '',
    anotherReceptionReason: '',
    purposeSendTrans: '',
    receptionReason: '',
    anotherPurposeSendTrans: '',
    transfAchTxCred: 0,
    transfAchAmountCred: 0,
    transfAchTxDeb: 0,
    transfAchAmountDeb: 0,
    transfInterTxCred: 0,
    transfInterAmountCred: 0,
    transfInterTxDeb: 0,
    transfInterAmountDeb: 0,
    transfBppTxCred: 0,
    transfBppAmountCred: 0,
    transfBppTxDeb: 0,
    transfBppAmountDeb: 0,
    totalTxCred: 0,
    totalAmountCred: 0,
    totalTxDeb: 0,
    totalAmountDeb: 0,
    accountDetail: '',
    step: '',
    profession: ''
  });

  setKYCData(value: KYCData) {
    this.kycData$.next(value);
  }

  getKYCData(): Observable<KYCData> {
    return this.kycData$.asObservable();
  }
}
