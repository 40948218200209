import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Dialog } from 'primeng/dialog';
import { ApiError } from '../../../model/api/api-error';
import { OTP_ERROR } from '../../../../shared/constants';

@Component({
  selector: 'app-pich-status-modal',
  templateUrl: './pich-status-modal.component.html',
  styleUrls: ['./pich-status-modal.component.scss']
})
export class PichStatusModalComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() contactUs: boolean = false;
  @Input() title: string;
  @Output() onHide = new EventEmitter<void>();
  @Output() onGenerateOtp = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<void>();
  @ViewChild('dialog') dialog: Dialog;
  e: boolean;

  @Input() set error(error: ApiError) {
    if (error) {
      this.handlerErrorOtp(error);
    }
  }
  errorMessage = '';

  constructor() {}

  ngOnInit(): void {}

  closeModal(e: boolean): boolean {
    if (e) {
      return this.visible;
    }
    this.onHide.emit();
    return e;
  }

  confirmar() {
    this.onConfirm.emit();
  }

  generateOtp() {
    this.onGenerateOtp.emit();
  }

  private handlerErrorOtp(error: ApiError) {
    if (error.message === OTP_ERROR.INVALID) {
      this.errorMessage = 'onboarding_incomplete_record_error';
    } else if (error.message === OTP_ERROR.NUM_RETRIES_EXCEEDED) {
      this.errorMessage = 'onboarding_otp_error_num_retries_exceeded';
      this.generateOtp();
    } else {
      setTimeout(() => {
        this.closeModal(false);
      }, 100);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    // Verifica si la variable "isVisible" ha cambiado
    if (changes.isVisible) {
      this.closeModal(changes.visible.currentValue);
    }
  }
}
