import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { GtmService } from './../../../shared/services/gtm/gtm.service';
import { AuthService } from './../../../shared/services/auth/auth.service';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { DetectedIdService } from '../../../shared/services/detected-id/detected-id.service';

import { PichBaseComponent } from '../pichincha-base.component';

import { CASE_STAGES, HOLDER, PASSWORD_REGXP } from '../../../shared/constants';
import { OpportunityResponse } from '../../../shared/model/api/opportunity-response.model';
import { ValidateOtpOpportunityRequest } from '../../../shared/model/api/validate-otp-opportunity-request.model';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  caseStage = CASE_STAGES.success;

  readonly passMaxLength = 8;
  showOtpModal = false;
  opportunity: OpportunityResponse;
  errorOtp = '';

  form: FormGroup;
  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private commonDataService: CommonDataService,
    private formBuilder: FormBuilder,
    private readonly authService: AuthService,
    private readonly detectedIdService: DetectedIdService,
    private gtmService: GtmService,
    protected confirmationService: ConfirmationService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit(): void {
    this.showOtpModal = false;
    this.errorOtp = '';

    this.form = this.formBuilder.group(
      {
        password: [
          '',
          [Validators.required, Validators.pattern(PASSWORD_REGXP)]
        ],
        repeatPassword: [
          '',
          [Validators.required, Validators.pattern(PASSWORD_REGXP)]
        ]
      },
      { validators: this.matchingPassword }
    );
  }

  get password() {
    return this.form.get('password');
  }

  get repeatPassword() {
    return this.form.get('repeatPassword');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  matchingPassword(form: FormGroup) {
    const password = form?.value?.password;
    const repeatPassword = form?.value?.repeatPassword;

    return password === repeatPassword ? null : { invalidRepeatPass: true };
  }

  sendForm() {
    if (this.form.valid) {
      this.generateOtp();
    } else {
      this.form.markAllAsTouched();
    }
  }

  generateOtp() {
    this.subscription.add(
      this.detectedIdService
        .getOpportunity(this.userData.holder === HOLDER.SECONDARY)
        .pipe(
          switchMap((opportunity) => {
            this.opportunity = opportunity;
            return this.authService.generatePassword({
              integrationId: this.opportunity.integrationId,
              timeStamp: this.opportunity.timeStamp
            });
          })
        )
        .subscribe((data) => (this.showOtpModal = true))
    );
  }

  validateOtp(otp: ValidateOtpOpportunityRequest) {
    this.subscription.add(
      this.authService
        .savePassword({
          password: this.password.value,
          integrationId: this.opportunity.integrationId,
          timeStamp: this.opportunity.timeStamp,
          otp: otp.otpKey,
          username: this.userData.clsResponseSavePersonalData.strDocumentNumber,
          holder: this.isSecondHolder ? HOLDER.SECONDARY : HOLDER.PRIMARY,
          strOppId: this.pichCService.getStrOppId()
        })
        .subscribe(
          (resp) => {
            if (this.isMultTitulares && !this.isSecondHolder) {
              this.commonDataService.setIsSecondHolder(true);
              return this.ngOnInit();
            }
            this.closeOtpModal();
            this.gtmService.sendVariableGTM({
              pageType: 'Contraseña'
            });
            this.gtmService.trackEvent('onboarding', 'paso 12', 'Contraseña');
            this.routerC.navigate(['onboarding', 'completed']);
          },
          (error) => (this.errorOtp = error)
        )
    );
  }

  closeOtpModal() {
    this.showOtpModal = false;
    this.errorOtp = '';
  }

  public onBackStage() {
    if (this.isMultTitulares) {
      if (this.isSecondHolder) {
        this.commonDataService.setIsSecondHolder(false);
        this.ngOnInit();
      }
    }
  }
}
