export interface ActionResponse {
  validated: boolean;
  strOppId: string;
  status: string;
  responseCode: string;
  message: string;
  error: boolean;

  // TODO: ESTOI SE USABA PARA RECUPERAR EL CASO
  strCaseStage?: string;
  strEmail?: string;
}
