import { Component, OnInit } from '@angular/core';

import { USER_DATA_CASE } from './../../../shared/constants';
import { CaseDataService } from '../../shared/services/case-data.service';

@Component({
  selector: 'app-risk-matrix-error',
  templateUrl: './risk-matrix-error.component.html',
  styleUrls: ['./risk-matrix-error.component.scss']
})
export class RiskMatrixErrorComponent implements OnInit {
  constructor(private caseDataSrv: CaseDataService) {}

  ngOnInit(): void {
    this.caseDataSrv.setStrOppId(undefined);
    sessionStorage.removeItem(USER_DATA_CASE);
  }
}
