import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { PichBaseComponent } from '../pichincha-base.component';

import { CommonDataService } from '../../../shared/services/common-data.service';
import { ToolboxService } from '../../../shared/services/toolbox/toolbox.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';

import { CountryModel } from '../../../shared/model/api/country.model';
import { PersonalInfoModel } from '../../model/params.model';
import { ACTION, CASE_STAGES, PATHS } from './../../../shared/constants';

import { OptionsType } from '../../../shared/model/option-type.model';
import { MiddlewareService } from '../../../shared/services/middleware.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { PersonalDataService } from '../../services/personal.data.service';

@Component({
  selector: 'app-pich-personal-data-2',
  templateUrl: './pich-personal-data-2.component.html',
  styleUrls: ['./pich-personal-data-2.component.scss'],
  providers: [ToolboxService]
})
export class PichPersonalData2Component
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  caseStage = CASE_STAGES.personalData2;
  formData: FormGroup;

  documentTypeLabel = 'onboarding_document_type_cc';

  // country$: Observable<Array<CountryModel>>;
  countries: CountryModel[] = [];

  nationality$: Observable<Array<CountryModel>>;
  expeditionDocumentCountry$: Observable<Array<CountryModel>>;
  countryResidence$: Observable<Array<CountryModel>>;
  personalData: PersonalInfoModel;
  // countryResidenceList: OptionsType[] = [];
  countryResidenceObj: OptionsType;
  countryResidenceId: string | number;
  setCountryResidence: string;
  placeholder: string;
  countryOfResidence: string;

  isPanama = false;
  noShow: boolean = false;
  readonly COLOMBIA_CODE = '170';
  applyStyle: boolean;

  HTML_VALIDATORS = {
    minLength: 2,
    maxLength: {
      nationality: 50,
      passportNumber: 20,
      identityDocumentNumber: 20,
      taxIdentificationNumber: 15
    }
  };
  maxDate = new Date();
  minDate = new Date(`01/01/${new Date().getFullYear() - 100}`);

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private formBuilder: FormBuilder,
    private commonDataService: CommonDataService,
    private readonly toolboxService: ToolboxService,
    private middlewareService: MiddlewareService,
    protected confirmationService: ConfirmationService,
    private personalDataService: PersonalDataService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.loadDropDownData();
    this.initForm();

    // this.countryResidenceList = [
    //   { id: 1, name: 'ECUADOR' },
    //   { id: 2, name: 'COLOMBIA' },
    //   { id: 3, name: 'PERÚ' }
    // ];
  }

  loadDropDownData() {
    this.toolboxService.getCountries().subscribe(
      (data) => {
        this.countries = data;
        this.loadData();
      },
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initForm(): void {
    this.formData = this.formBuilder.group({
      country: ['', [Validators.required]],
      nationality: [
        '',
        [
          Validators.required,
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.nationality)
        ]
      ],
      documentNumber: [
        '',
        [
          Validators.required,
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.passportNumber)
        ]
      ],
      passportExpirationDate: ['', [Validators.required, this.dateValidator]],
      expeditionDocumentCountry: [''],
      identityDocumentNumber: [''],

      taxIdentificationNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(
            this.HTML_VALIDATORS.maxLength.taxIdentificationNumber
          )
        ]
      ]
    });

    this.subscribeFormChanges();
  }

  get control() {
    return this.formData.controls;
  }
  get country() {
    return this.formData.get('country');
  }
  get nationality() {
    return this.formData.get('nationality');
  }
  get expeditionDocumentCountry() {
    return this.formData.get('expeditionDocumentCountry');
  }
  get passportExpirationDate() {
    return this.formData.get('passportExpirationDate');
  }

  getAction() {
    return ACTION.savePersonalData;
  }

  loadData() {
    this.middlewareService.getUsersLookup().subscribe(
      (data) => {
        this.personalData = data;
        this.validateUserTypeAccount(data);
        this.formData.patchValue({
          country: this.personalData.countryOfBirth
            ? this.personalData.countryOfBirth
            : '',
          nationality: this.personalData.nationality
            ? this.personalData.nationality
            : '',
          documentNumber: this.personalData.passportNumber,
          passportExpirationDate: this.personalData.expiryDate,
          expeditionDocumentCountry: this.personalData.countryOfExpedition
            ? this.personalData.countryOfExpedition
            : '',
          identityDocumentNumber: this.personalData.documentNumber,
          taxIdentificationNumber: this.personalData.taxDocumentNumber
        });
      },
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  validateUserTypeAccount(data: any) {
    if (
      data?.targetCountry !== undefined &&
      data?.targetCountry === 'L_PANBM'
    ) {
      // if(data?.targetCountry !== undefined && data?.targetCountry === "L_IBM"){
      this.isPanama = true;
      this.applyStyle = true;
      this.noShow;
    } else {
      this.noShow = true;
      this.applyStyle = true;
      this.formData.addControl(
        'identityDocumentNumber',
        new FormControl(data?.identityDocumentNumber ?? '', [
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(
            this.HTML_VALIDATORS.maxLength.identityDocumentNumber
          )
        ])
      );
      this.formData.addControl(
        'expeditionDocumentCountry',
        new FormControl(data?.expeditionDocumentCountry ?? '', [
          Validators.required
        ])
      );
    }
  }

  dateValidator(control: any) {
    if (control.value) {
      const regexDate = /^\d{4}-\d{2}-\d{2}$/;

      if (!regexDate.test(control.value)) {
        return { invalidDate: true };
      }
    }
    const [year, month, day] = control.value.split('-').map(Number);
    const date = new Date(year, month - 1, day);

    // this.passportDateValidator(control.vale);

    if (
      date.getFullYear() === year &&
      date.getMonth() + 1 === month &&
      date.getDate() === day
    ) {
      return null;
    } else {
      return { invalidDate: true };
    }
  }

  private subscribeFormChanges() {
    this.subscription.add(
      this.country.valueChanges.subscribe((value) => {
        if (value) {
          this.countryValidation(value, 'country');
        }
      })
    );
    this.subscription.add(
      this.nationality.valueChanges.subscribe((value) => {
        if (value) {
          this.countryValidation(value, 'nationality');
        }
      })
    );
    this.subscription.add(
      this.expeditionDocumentCountry.valueChanges.subscribe((value) => {
        if (value) {
          this.countryValidation(value, 'expeditionDocumentCountry');
        }
      })
    );
    this.subscription.add(
      this.passportExpirationDate.valueChanges.subscribe((value) => {
        if (value) {
          this.passportDateValidator(value, 'passportExpirationDate');
        }
      })
    );
  }

  passportDateValidator(date: any, controlName: string) {
    if (date) {
      const currentDate = new Date();
      const formattedDate = new Date(date);
      if (formattedDate < currentDate) {
        this.setFormError(
          controlName,
          'onboarding_error_passport_expiration_date'
        );
      }
    }
  }

  countryValidation(value: any, controlName: string) {
    if (value === '400') {
      this.setFormError(controlName, 'onboarding_error_country_validation');
    }
  }

  setFormError(controlName: string, errorMessage: string) {
    this.formData.controls[controlName].setErrors({
      errorMessage
    });
  }

  private getPersonalInfo(): PersonalInfoModel {
    return {
      firstName: this.personalData.firstName,
      middleName: '',
      lastName: this.personalData.lastName,
      secondLastName: this.personalData.secondLastName,
      birthDate: this.personalData.birthDate,
      cityOfResidence: this.personalData.cityOfResidence,
      residentialAddress: this.personalData.residentialAddress,
      countryOfBirth: this.formData.value.country,
      nationality: this.formData.value.nationality,
      documentType: 'PASAPORTE',
      passportNumber: this.formData.value.documentNumber,
      expiryDate: this.formData.value.passportExpirationDate,
      documentNumber: this.formData.value.identityDocumentNumber,
      countryOfExpedition: this.formData.value.expeditionDocumentCountry,
      countryOfResidence: this.personalData.countryOfResidence,
      taxDocumentNumber: this.formData.value.taxIdentificationNumber,
      gender: this.formData.value.gender,
      maritalStatus: this.formData.value.maritalStatus,
      oriDesTable: this.personalData.oriDesTable,
      step: '2',
      createBeneficiaryTable: this.personalData.createBeneficiaryTable,
      requiresBen: this.personalData.requiresBen
    };
  }

  private getPersonalInfo1(): PersonalInfoModel {
    return {
      firstName: this.personalData.firstName,
      middleName: '',
      lastName: this.personalData.lastName,
      secondLastName: this.personalData.secondLastName,
      birthDate: this.personalData.birthDate,
      cityOfResidence: this.personalData.cityOfResidence,
      residentialAddress: this.personalData.residentialAddress,
      countryOfBirth: this.formData.value.country,
      nationality: this.formData.value.nationality,
      documentType: 'PASAPORTE',
      passportNumber: this.formData.value.documentNumber,
      expiryDate: this.formData.value.passportExpirationDate,
      documentNumber: null,
      countryOfExpedition: null,
      countryOfResidence: this.personalData.countryOfResidence,
      taxDocumentNumber: this.formData.value.taxIdentificationNumber,
      gender: this.formData.value.gender,
      maritalStatus: this.formData.value.maritalStatus,
      oriDesTable: this.personalData.oriDesTable,
      step: '2',
      createBeneficiaryTable: this.personalData.createBeneficiaryTable,
      requiresBen: this.personalData.requiresBen
    };
  }

  onNext() {
    const path = this.isPanama
      ? PATHS.personalDataOrigenDestiny
      : PATHS.activity;
    if (this.isPanama == true) {
      if (this.formData.valid) {
        this.middlewareService
          .setPersonalInfo(this.getPersonalInfo1())
          .subscribe(
            (data) => {
              this.personalDataService.setPersonalData(this.getPersonalInfo1());
              this.routerC.navigate(['onboarding', path]);
            },
            (error) => {
              this.setErrorMessages(error.responseCode);
            }
          );
      } else {
        this.formData.markAllAsTouched();
      }
    } else {
      if (this.formData.valid) {
        this.middlewareService
          .setPersonalInfo(this.getPersonalInfo())
          .subscribe(
            (data) => {
              this.personalDataService.setPersonalData(this.getPersonalInfo());
              this.routerC.navigate(['onboarding', path]);
            },
            (error) => {
              this.setErrorMessages(error.responseCode);
            }
          );
      } else {
        this.formData.markAllAsTouched();
      }
    }
  }

  onBackStage() {
    this.routerC.navigate(['onboarding', PATHS.personalData1]);
  }
}
