import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  TranslateParser,
  TranslateDefaultParser,
  TranslateService,
  MissingTranslationHandler
} from '@ngx-translate/core';

import localeEsEs from '@angular/common/locales/es';

import { DeviceDetectorService } from 'ngx-device-detector';

import { CalendarModule } from 'primeng/calendar';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { PichinchaContainerModule } from './pichincha-onboarding/pichincha-container-module';
import { VassCoreModule } from './vass-core/vass-core.module';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './shared/services/interceptor.service';

// Services
import { CommonDataService } from './shared/services/common-data.service';

// Interceptors
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Routes
import { CaseDataService } from './pichincha-onboarding/shared/services/case-data.service';

// Components
import { AppComponent } from './app.component';
import { CustomMissingTranslationHandler } from './shared/utils/custom-missing-translation-handler';
import { CustomTranslateLoader } from './shared/utils/CustomTranslateLoader';

import { LocalStorageService, NgxWebstorageModule } from 'ngx-webstorage';

import { environment } from './../environments/environment';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { MocksInterceptor } from './vass-core/mocks/mock.interceptor';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';

// AoT requires an exported function for factories
export function customLoaderFactory(
  http: HttpClient,
  localStorage: LocalStorageService
) {
  return new CustomTranslateLoader(http, localStorage);
}

registerLocaleData(localeEsEs, 'es-ES');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    VassCoreModule,
    PichinchaContainerModule,
    SharedModule,
    GooglePlaceModule,
    TooltipModule,
    MessagesModule,
    NgxWebstorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: customLoaderFactory,
        deps: [HttpClient, LocalStorageService],
        useClass: CustomTranslateLoader
      },
      parser: {
        provide: TranslateParser,
        useClass: TranslateDefaultParser
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      }
    }),
    CalendarModule,
    GoogleTagManagerModule.forRoot({
      id: environment.GTM_ID
    })
  ],
  providers: [
    TranslateService,
    CommonDataService,
    CaseDataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    DeviceDetectorService,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: 'googleTagManagerId', useValue: environment.GTM_ID },
    { provide: HTTP_INTERCEPTORS, useClass: MocksInterceptor, multi: true }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
