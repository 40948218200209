import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { CommonDataService } from '../../../shared/services/common-data.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';

import { PichBaseComponent } from '../pichincha-base.component';

import { PATHS } from '../../../shared/constants';
import { OptionsType } from '../../../shared/model/option-type.model';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { InitialData } from '../../model/initial-data.model';

@Component({
  selector: 'app-account-owner',
  templateUrl: './account-owner.component.html',
  styleUrls: ['./account-owner.component.scss']
})
export class AccountOwnerComponent extends PichBaseComponent implements OnInit {
  public action = PATHS.accountOwner;
  public dataRegister = {} as InitialData;
  public countriesList: OptionsType[] = [];
  public accountsTypeList: OptionsType[] = [];
  public onBoardingformGroup: FormGroup;
  public countryObj: OptionsType;
  public accountTypeObj: OptionsType;

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private commonDataService: CommonDataService,
    protected confirmationService: ConfirmationService,
    private fb: FormBuilder
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.countriesList = [
      { id: 1, name: 'Ecuador' },
      { id: 2, name: 'Colombia' },
      { id: 3, name: 'Perú' }
    ];
    // this.accountsTypeList = [{ id: 1, name: 'onboarding_account_type_1' }];
    this.accountsTypeList = [
      { id: 'L_IBM', name: 'onboarding_account_type_1' },
      { id: 'L_PBM', name: 'onboarding_account_type_2' },
      { id: 'L_PANBM', name: 'onboarding_account_type_4' }
    ];

    Object.assign(this.dataRegister, this.commonDataService.getDataRegister());

    this.onBoardingformGroup = this.fb.group({
      country: [null, Validators.required],
      accountType: [null, Validators.required]
    });

    this.loadFormPatchValue();
  }

  private loadFormPatchValue() {
    const { sourceCountry, targetCountry } = this.dataRegister;

    this.countryObj = this.countriesList.find((f) => f.name === sourceCountry);
    this.accountTypeObj = this.accountsTypeList.find(
      (f) => f.id === targetCountry
    );

    this.onBoardingformGroup.patchValue({
      country: this.countryObj?.id,
      accountType: this.accountTypeObj?.id
    });
  }

  getAction() {
    return PATHS.accountOwner;
  }

  onNextStage() {
    if (this.onBoardingformGroup.valid) {
      const onBoardingformGroupValue = this.onBoardingformGroup.value;
      this.dataRegister.sourceCountry = onBoardingformGroupValue.country.name;

      this.dataRegister.targetCountry = onBoardingformGroupValue.accountType.id;
      this.dataRegister.privacyPolicy = onBoardingformGroupValue.accountType.id;
      this.routerC.navigate(['onboarding', PATHS.initialData]);
      this.commonDataService.setDataRegister(this.dataRegister);
    } else {
      this.onBoardingformGroup.markAllAsTouched();
    }
  }

  /** Sobre escribe el metodo del padre y navega a la pantalla anterior, guardando los datos en el servicio
   *  para poder recuperarlos.
   */
  onBackStage() {
    if (
      !this.onBoardingformGroup.controls['country'].value &&
      !this.onBoardingformGroup.controls['accountType'].value
    ) {
      this.routerC.navigate(['onboarding', PATHS.welcome]);
    } else {
      this.onBoardingformGroup.markAllAsTouched();
      const onBoardingformGroupValue = this.onBoardingformGroup.value;
      this.dataRegister.sourceCountry = onBoardingformGroupValue.country.id;
      this.dataRegister.targetCountry = onBoardingformGroupValue.accountType.id;
      this.routerC.navigate(['onboarding', PATHS.welcome]);
      this.commonDataService.setDataRegister(this.dataRegister);
    }
  }
}
