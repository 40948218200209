import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { OTP_ERROR } from '../../../shared/constants';
import { OperationId } from '../../../shared/model/api/operation-id.enum';
import { ValidateOtpOpportunityRequest } from '../../../shared/model/api/validate-otp-opportunity-request.model';
import { ApiError } from '../../model/api/api-error';
import { EMAIL_REGEXP } from '../../../shared/constants';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {
  readonly maxLengthCode = 6;
  public errorServer = '';
  showOtpModal = false;

  emailControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(80),
    Validators.pattern(EMAIL_REGEXP)
  ]);

  isVisible = true;
  @Input() title: string;
  @Input() set error(error: ApiError) {
    if (error) {
      this.handlerErrorOtp(error);
    }
  }
  @Input() integrationId: string;
  @Input() timeStamp: string;

  @Output() onHide = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<ValidateOtpOpportunityRequest>();
  @Output() onGenerateOtp = new EventEmitter<void>();
  @Output() onConfirmEmail = new EventEmitter<string>();
  errorMessage = '';

  captchaPending = false;

  constructor() {}

  ngOnInit(): void {}

  closeModal() {
    this.onHide.emit();
  }

  generateOtp() {
    this.onGenerateOtp.emit();
  }

  doLoginOtp() {
    if (this.emailControl.valid) {
      this.onConfirmEmail.emit(this.emailControl.value);
    } else {
      this.emailControl.markAllAsTouched();
    }
  }

  private handlerErrorOtp(error: ApiError) {
    if (error.message === OTP_ERROR.INVALID) {
      this.errorMessage = 'onboarding_incomplete_record_error';
    } else if (error.message === OTP_ERROR.NUM_RETRIES_EXCEEDED) {
      this.errorMessage = 'onboarding_otp_error_num_retries_exceeded';
      this.generateOtp();
    } else {
      setTimeout(() => {
        this.closeModal();
      }, 100);
    }
  }
}
