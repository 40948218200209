import { Component, OnDestroy, OnInit } from '@angular/core';
import { PichBaseComponent } from '../pichincha-base.component';
import { PATHS } from './../../../shared/constants';

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { StatusDocumentUpload } from '../../../shared/model/status-document-upload.model';
import { MiddlewareService } from '../../../shared/services/middleware.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import {
  DocumentStatus,
  FileDTO,
  PersonalInfoModel
} from '../../model/params.model';
import { InitialData } from '../../model/initial-data.model';

@Component({
  selector: 'app-pich-identity-manual-confirmation',
  templateUrl: './pich-identity-manual-confirmation.component.html',
  styleUrls: ['./pich-identity-manual-confirmation.component.scss']
})
export class PichIdentityManualConfirmationComponent
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  public prevStage = PATHS.contactData;
  public nextStage = PATHS.personalData1;

  public registerData = new InitialData();
  personalData: PersonalInfoModel;

  public identityManualForm: FormGroup;
  public failedAttempts = 0;

  readonly maxSize = 8;

  public errorMessage: boolean = false;
  public showError = false;
  readonly maxSizeUpload = 20_000_000;

  riskMatrixError = false;
  isPanama = false;

  opportunityId = new FormControl('', [Validators.required]);
  documents: DocumentStatus[] = [];

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private formBuilder: FormBuilder,
    private middlewareService: MiddlewareService,
    protected confirmationService: ConfirmationService,
    private commonDataService: CommonDataService,
    gtmService: GoogleTagManagerService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.loadData();
    Object.assign(this.registerData, this.commonDataService.getDataRegister());
    this.identityManualForm = this.formBuilder.group({});
    this.loadDocuments();
  }

  loadDocuments() {
    this.pichCService.getDocumentsStatus().subscribe(
      (data) => {
        this.documents = data.filter((doc) => doc.generationType === 'Manual');
        this.crearteForm();

        // if (
        //   this.documents.every(
        //     (doc) => doc.status !== 'Pending' && doc.status !== 'Rejected'
        //   )
        // ) {
        //   this.onNextStage(PATHS.personalData1);
        // }

        this.documents.forEach((docs) => {
          if (docs.status === 'Uploaded' || docs.status === 'Accepted')
            this.identityManualForm.controls[docs.documentCode].setValue(
              docs.status
            );

          switch (docs.documentCode) {
            case 'DOC-01':
              return (docs.documentTypeLegend =
                'onboarding_attach_passport_text');
            case 'DOC-02':
              return (docs.documentTypeLegend = 'onboarding_attach_front_text');
            case 'DOC-03':
              return (docs.documentTypeLegend = 'onboarding_attach_back_text');
            case 'DOC-04':
              return (docs.documentTypeLegend =
                'onboarding_attach_reference_bank_account_text1');
            case 'DOC-05':
              return (docs.documentTypeLegend =
                'onboarding_attach_reference_bank_account_text2');
            case 'DOC-06':
              return (docs.documentTypeLegend =
                'onboarding_attach_service_invoice_text');
          }
        });
      },
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  loadData() {
    this.middlewareService.getUsersLookup().subscribe((data) => {
      if (data.targetCountry === 'L_PANBM') {
        this.isPanama = true;
      }
      return (this.personalData = data);
    });
  }

  crearteForm(): void {
    for (const document of this.documents) {
      const formControlDocuments = document.documentCode;
      this.identityManualForm.addControl(
        formControlDocuments,
        new FormControl('', Validators.required)
      );
    }
  }

  get formDocuments() {
    return this.identityManualForm.controls['documentsForm'] as FormArray;
  }

  private parseFileDto(event: StatusDocumentUpload): FileDTO {
    return {
      filename: this.getDocType(event.documentCode),
      fileExtension: event.type,
      base64File: event.base64,
      documentType: event.documentCode
    };
  }

  private getDocType(type: string) {
    switch (type) {
      case 'DOC-01':
        return 'passport';
      case 'DOC-02':
        return 'anverso';
      case 'DOC-03':
        return 'reverso';
      case 'DOC-04':
        return 'bankAccount';
      case 'DOC-05':
        return 'bankAccount2';
      case 'DOC-06':
        return 'serviceInvoice';
      case 'DOC-07':
        return 'personalDocument';
      case 'DOC-08':
        return 'personalDocument';
      case 'DOC-09':
        return 'personalDocument';
      case 'DOC-10':
        return 'personalDocument';
    }
  }

  private handleError(event: StatusDocumentUpload) {
    this.identityManualForm.controls[event.documentCode].setValue('');
    this.identityManualForm.controls[event.documentCode].markAsTouched();

    this.identityManualForm.controls[event.documentCode].setErrors({
      errorMessage: event.errorMessage
    });
  }

  uploadImg(event: StatusDocumentUpload) {
    if (event.statusUpload === 'ok') {
      this.middlewareService.uploadFile(this.parseFileDto(event)).subscribe(
        (data) => {
          this.identityManualForm.controls[event.documentCode].setValue(
            'Uploaded'
          );
          this.identityManualForm.controls[event.documentCode].setErrors(null);
        },
        (error) => {
          this.handleError(event);
          this.setErrorMessages(error.responseCode);
        }
      );
    } else {
      this.handleError(event);
    }
  }

  uploadFiles() {
    if (this.identityManualForm.valid) {
      this.commonDataService.setDataRegister(this.registerData);
      this.routerC.navigate(['onboarding', PATHS.personalData1]);
    } else {
      this.identityManualForm.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openExplorerFile(code: string) {
    const element: any = document.getElementById(code);
    element.click();
  }
}
