/**
 * Class for validating spanish username
 */
export class UserValidationUtilES {
  /**
   * Validate User which can be a NIE
   * @param user
   */
  static validateNIE(user: string): boolean {
    const nieRegExp = new RegExp('^(X|Y|Z|x|y|z)\\d{4,8}[a-zA-Z]$');
    const dniRegExp = new RegExp('^\\d{5,8}[a-zA-Z]$');

    if (user.match(nieRegExp)) {
      user = this.convertNIEToDNI(user);
    } else {
      return false;
    }

    if (user.match(dniRegExp)) {
      user = this.correctDNI(user);
      return this.isValidDNI(user);
    }

    return false;
  }

  /**
   * Validate User which can be a DNI
   * @param user
   */
  static validateDNI(user: string): boolean {
    const dniRegExp = new RegExp('^\\d{5,8}[a-zA-Z]$');

    if (user.match(dniRegExp)) {
      user = this.correctDNI(user);
      return this.isValidDNI(user);
    }

    return false;
  }

  /**
   * Validate User which can be a DNI, CIF or NIE
   * @param user
   */
  static isValidDocument(user: string): boolean {
    const nieRegExp = new RegExp('^(X|Y|Z|x|y|z)\\d{4,8}[a-zA-Z]$');
    const dniRegExp = new RegExp('^\\d{5,8}[a-zA-Z]$');

    if (user.match(nieRegExp)) {
      user = this.convertNIEToDNI(user);
    }

    if (user.match(dniRegExp)) {
      user = this.correctDNI(user);
      return this.isValidDNI(user);
    }

    return false;
  }

  /**
   * Validate spanish dni version
   * @param dni
   */
  private static isValidDNI(dni: string): boolean {
    const numbers = this.getNumbers(dni);
    const letter = this.getLetter(dni);
    const letterIdentifier = this.getLetterIdentifier(numbers);

    if (letterIdentifier.toUpperCase() === letter.toUpperCase()) {
      return true;
    }

    return false;
  }

  /**
   * Function that takes the numbers which belongs to a spanish dni
   * @param dni
   */
  private static getNumbers(dni: string): number {
    // tslint:disable-next-line:radix
    return parseInt(dni.substring(0, dni.length - 1));
  }

  /**
   * Function that takes the final letter that belongs to a spanish dni
   * @param dni
   */
  private static getLetter(dni: string): string {
    return dni.substring(dni.length - 1, dni.length);
  }

  /**
   * Function that takes the letter associated to a particular dni number
   * @param numbers
   */
  private static getLetterIdentifier(numbers: number): string {
    const numberIdentifier = numbers % 23;
    const abc = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const letterIdentifier = abc.substring(
      numberIdentifier,
      numberIdentifier + 1
    );
    return letterIdentifier;
  }

  /**
   * Correct DNI if it has a lowercase letter or a number started with 0s which have not been written
   *
   * @param dni
   */
  private static correctDNI(dni: string): string {
    if (dni.length < 9) {
      const dif = 9 - dni.length;
      for (let i = 0; i < dif; i++) {
        dni = '0' + dni;
      }
    }
    return dni;
  }

  /**
   * Converts a NIE to DNI
   * @param nie
   */
  private static convertNIEToDNI(nie: string): string {
    const firstLetter = nie.substring(0, 1).toUpperCase();
    switch (firstLetter) {
      case 'X':
        nie = nie.replace(/^(X|Y|Z)/, '0');
        break;
      case 'Y':
        nie = nie.replace(/^(X|Y|Z)/, '1');
        break;
      case 'Z':
        nie = nie.replace(/^(X|Y|Z)/, '2');
        break;
    }
    return nie;
  }
}
