import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { OpportunityResponse } from '../../model/api/opportunity-response.model';
import { ChangePasswordRequest } from '../../model/api/change-password-request.model';
import { CustomHttpParams } from '../../../vass-core/services/custom-http-params.class';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessTokenObservable = new BehaviorSubject<string | null>(null);
  get accessToken$() {
    return this.accessTokenObservable.asObservable();
  }
  constructor(private http: HttpClient, private cryptoService: CryptoService) {}

  setAccessToken(token: string) {
    this.accessTokenObservable.next(token);
  }

  checkAccessTokenInSession() {
    const accessToken = sessionStorage.getItem('access_token');
    !!accessToken && this.setAccessToken(accessToken);
  }

  generatePassword(body: {
    integrationId: string;
    timeStamp: string;
  }): Observable<OpportunityResponse> {
    return this.http.post<OpportunityResponse>(
      `${environment.apiPath}/auth/${environment.version.auth}/password/generate`,
      body,
      { params: new CustomHttpParams(true), responseType: 'text' as 'json' }
    );
  }

  savePassword(body: ChangePasswordRequest) {
    return this.cryptoService
      .getSecureLogin(
        body.username,
        body.password,
        String(new Date().getTime())
      )
      .pipe(
        switchMap((secureLoginData) => {
          const basic = 'environment.api_key';

          let headers = new HttpHeaders();
          headers = headers
            .set('Authorization', `Basic ${basic}`)
            .set('KeyToken2', secureLoginData.keyToken2)
            .set('Content-type', 'application/json');
          return this.http.put(
            `${environment.apiPath}/auth/${environment.version.auth}/password/generate`,
            {
              encryptedTokenL2: secureLoginData.body.encryptedTokenL2,
              otp: body.otp,
              timeStamp: body.timeStamp,
              integrationId: body.integrationId,
              holder: body.holder,
              strOppId: body.strOppId
            },
            {
              headers,
              params: new CustomHttpParams(true),
              responseType: 'text' as 'json',
              observe: 'response'
            }
          );
        })
      );
  }
}
