import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { PATHS } from '../../constants';
import { isTest } from '../../utils/is-test';

@Injectable({
  providedIn: 'root'
})
export class IdentityManualConfirmGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // if(!isTest()){
    //  return this.router.navigate(['onboarding', PATHS.videoIdentification]);
    // } else {
    return true;
    // }
  }
}
