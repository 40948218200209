import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { CommonDataService } from '../../services/common-data.service';
import { environment } from '../../../../environments/environment';
import { PATHS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ContactDataGuardGuard implements CanActivate {
  constructor(
    private router: Router,
    private commonDataService: CommonDataService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user = this.commonDataService.getDataRegister();
    if (
      user.hasMultipleHolders !== null &&
      user.hasMultipleHolders !== undefined
    ) {
      return true;
    }

    this.router.navigate(['onboarding', PATHS.welcome]);
    return false;
  }
}
