import { Component } from '@angular/core';

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';

import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { map } from 'rxjs/operators';
import { NEXT_SCREEN, PATHS } from '../../../../app/shared/constants';
import { StatusDocumentUpload } from '../../../shared/model/status-document-upload.model';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { MiddlewareService } from '../../../shared/services/middleware.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { DocumentStatus, FileDTO } from '../../model/params.model';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { PichBaseComponent } from '../pichincha-base.component';

@Component({
  selector: 'app-pich-document-review',
  templateUrl: './pich.document.review.component.html',
  styleUrls: ['./pich.document.review.component.scss']
})
export class DocumentReviewComponent extends PichBaseComponent {
  public prevStage = PATHS.contactData;
  public nextStage = PATHS.personalData1;
  public identityManualForm: FormGroup;
  public failedAttempts = 0;
  readonly maxSize = 8;

  public errorMessage = 'error_validate_documents';
  public showError = false;
  readonly maxSizeUpload = 20_000_000;
  riskMatrixError = false;
  opportunityId = new FormControl('', [Validators.required]);
  documents: DocumentStatus[] = [];
  personalData;

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private formBuilder: FormBuilder,
    private middlewareService: MiddlewareService,
    private commonDataService: CommonDataService,
    protected confirmationService: ConfirmationService,
    gtmService: GoogleTagManagerService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.identityManualForm = this.formBuilder.group({});
    this.loadData();
    this.loadDocuments();
  }
  loadData() {
    this.middlewareService.getUsersLookup().subscribe((data) => {
      this.personalData = data;
    });
  }
  loadDocuments() {
    this.pichCService
      .getDocumentsStatus()
      .pipe(
        map((documents) =>
          documents.filter(
            (doc) =>
              doc.status === 'Rejected' && doc.generationType === 'Manual'
          )
        )
      )
      .subscribe(
        (data) => {
          this.documents = data;
          this.createForm();

          this.documents.forEach((docs) => {
            if (docs.status === 'Rejected') {
              this.identityManualForm.controls[docs.documentCode].setValue('');
            }

            switch (docs.documentCode) {
              case 'DOC-01':
                return (docs.documentTypeLegend =
                  'onboarding_attach_passport_text');
              case 'DOC-02':
                return (docs.documentTypeLegend =
                  'onboarding_attach_front_text');
              case 'DOC-03':
                return (docs.documentTypeLegend =
                  'onboarding_attach_back_text');
              case 'DOC-04':
                return (docs.documentTypeLegend =
                  'onboarding_attach_reference_bank_account_text');
              case 'DOC-05':
                return (docs.documentTypeLegend =
                  'onboarding_attach_reference_bank_account_text2');
              case 'DOC-06':
                return (docs.documentTypeLegend =
                  'onboarding_attach_service_invoice_text');
            }
          });
        },
        (error) => {
          this.setErrorMessages(error.responseCode);
        }
      );
  }

  createForm() {
    for (const document of this.documents) {
      const formControlDocuments = document.documentCode;
      this.identityManualForm.addControl(
        formControlDocuments,
        new FormControl('', Validators.required)
      );
    }
  }

  get formDocuments() {
    return this.identityManualForm.controls['documentsForm'] as FormArray;
  }
  private parseFileDto(event: StatusDocumentUpload): FileDTO {
    return {
      filename: this.getDocType(event.documentCode),
      fileExtension: event.type,
      base64File: event.base64,
      documentType: event.documentCode
    };
  }

  private getDocType(type: string) {
    switch (type) {
      case 'DOC-01':
        return 'passport';
      case 'DOC-02':
        return 'anverso';
      case 'DOC-03':
        return 'reverso';
      case 'DOC-04':
        return 'bankAccount';
      case 'DOC-05':
        return 'bankAccount2';
      case 'DOC-06':
        return 'serviceInvoice';
      case 'DOC-07':
        return 'personalDocument';
      case 'DOC-08':
        return 'personalDocument';
      case 'DOC-09':
        return 'personalDocument';
      case 'DOC-10':
        return 'personalDocument';
    }
  }

  private handleError(event: StatusDocumentUpload) {
    this.identityManualForm.controls[event.documentCode].setValue('');
    this.identityManualForm.controls[event.documentCode].markAsTouched();
    this.identityManualForm.controls[event.documentCode].setErrors({
      errorMessage: event.errorMessage
    });
  }

  uploadImg(event: StatusDocumentUpload) {
    if (event.statusUpload === 'ok') {
      this.middlewareService.uploadFile(this.parseFileDto(event)).subscribe(
        (data) => {
          this.identityManualForm.controls[event.documentCode].setValue(
            'Uploaded'
          );
          this.identityManualForm.controls[event.documentCode].setErrors(null);
          this.uploadFiles();
        },
        (error) => {
          this.handleError(event);
          this.setErrorMessages(error.responseCode);
        }
      );
    } else {
      this.handleError(event);
    }

    // try {
    //   if (JSON.stringify(this.params).length > 1000000) {
    //     this.errorMessage =
    //       'El tamaño de todas las imágenes no puede superar 1Mb, por favor reduce el tamaño de las imágenes.';
    //   } else {
    //     this.errorMessage = '';
    //   }
    // } catch (e) {}
  }

  uploadFiles() {
    if (this.identityManualForm.valid) {
      if (this.personalData.nextScreen === NEXT_SCREEN.FINAL_STEP) {
        this.routerC.navigate(['onboarding', PATHS.finalStep]);
      } else if (
        this.personalData.nextScreen === NEXT_SCREEN.DOCS_AND_PERSONAL
      ) {
        this.routerC.navigate(['onboarding', PATHS.personalData1]);
      }
    } else {
      this.identityManualForm.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openExplorerFile(code: string) {
    const element: any = document.getElementById(code);
    element.click();
  }
}
