import { Injectable } from '@angular/core';
import { Observable, iif } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { map } from 'rxjs/operators';
import { InitialData } from '../../pichincha-onboarding/model/initial-data.model';
import { CaseDataService } from '../../pichincha-onboarding/shared/services/case-data.service';
import { CommonService } from '../../vass-core/services/common.service';
import { ACTION, HOLDER } from '../constants';
import { environment } from './../../../environments/environment';
import {
  AddressResponse,
  BaseData,
  RecoverModel
} from './../../pichincha-onboarding/model/models';
import { AuthService } from './auth/auth.service';

@Injectable()
export class CommonDataService {
  private dataRegister: InitialData;
  private _isSecondHolder = false;
  private _blnMultTitulares = false;

  private _addressFirstHolder: AddressResponse;

  isCompatible: boolean;

  private _userIdFirstHolder: string;
  private _userIdSecondHolder: string;

  private _docNumberPrimaryHolder$ = new BehaviorSubject<string>('');

  constructor(
    private commonHttp: CommonService,
    private caseDataSrv: CaseDataService, // private sessionStorageSrv: SessionStorageService
    private authService: AuthService
  ) {
    this.dataRegister = new InitialData();
  }

  setDataRegister(data: InitialData) {
    Object.assign(this.dataRegister, data);
  }

  getDataRegister(): InitialData {
    return this.dataRegister;
  }

  get isSecondHolder(): boolean {
    return this._isSecondHolder;
  }

  setIsSecondHolder(isSecondHolder): void {
    this._isSecondHolder = isSecondHolder;
  }

  get addressFirstHolder(): AddressResponse {
    return this._addressFirstHolder;
  }

  setAddressFirstHolder(address: AddressResponse): void {
    this._addressFirstHolder = address;
  }

  /**
   * devuelve si el caso es de uno o dos titulares, para apuntar a un "action_method" u otro
   */
  get isMultTitulares(): boolean {
    return this._blnMultTitulares;
  }

  /**
   * fijar si el caso es de uno o dos titulares
   */
  set multTitulares(isMultTitulares: boolean) {
    this._blnMultTitulares = isMultTitulares;
  }

  public setUserIdFirstHolder(id: string): void {
    this._userIdFirstHolder = id;
  }

  get userIdFirstHolder(): string {
    return this._userIdFirstHolder;
  }

  public setUserIdSecondHolder(id: string): void {
    this._userIdSecondHolder = id;
  }

  get userIdSecondHolder(): string {
    return this._userIdSecondHolder;
  }

  getDocNumberPrimaryHolder() {
    return iif(
      () => !!this._docNumberPrimaryHolder$.getValue(),
      this._docNumberPrimaryHolder$
    );
  }

  setDocNumberPrimaryHolder(value: string) {
    this._docNumberPrimaryHolder$.next(value);
  }

  setOptAccessToken({
    access_token,
    refresh_token
  }: {
    access_token: string;
    refresh_token: string;
  }): void {
    sessionStorage.setItem('access_token', access_token);
    sessionStorage.setItem('refresh_token', refresh_token);
    this.authService.setAccessToken(access_token);
  }
}
