import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pich-personal-modal',
  templateUrl: './pich-personal-modal.component.html',
  styleUrls: ['./pich-personal-modal.component.scss']
})
export class PichPersonalModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
