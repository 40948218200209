import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { ModalDataType } from '../../../vass-core/components/modal/modal-data-type.interface';
import { ACTION, CASE_STAGES, PATHS } from './../../../shared/constants';

import { PichModalGenericComponent } from '../../../shared/components/modals/pich-modal-generic/pich-modal-generic.component';
import { PichBaseComponent } from '../pichincha-base.component';

import { CommonDataService } from '../../../shared/services/common-data.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { GtmService } from './../../../shared/services/gtm/gtm.service';

import { MiddlewareService } from '../../../shared/services/middleware.service';
import { isTest } from '../../../shared/utils/is-test';
import { InitialData } from '../../model/initial-data.model';

@Component({
  selector: 'app-pich-sign-contract',
  templateUrl: './pich-sign-contract.component.html',
  styleUrls: ['./pich-sign-contract.component.scss']
})
export class PichSignContractComponent
  extends PichBaseComponent
  implements OnInit
{
  public formSignContract: FormGroup;

  private nextStage = PATHS.adobeSignView;
  public prevStage = PATHS.activity4;
  caseStage = CASE_STAGES.kyc;

  public msgProtectionClauses: string;
  public msgUseMyData: string;
  public msgNoDataTransfer: string;
  public accountType: string;

  showLoadingMessage = false;
  isLoadingData = false;
  msgLoading = '';

  public isSecondHolder = false;
  public isMultTitulares = false;
  public isTest = false;

  public firstDocument: boolean;
  public secondDocument: boolean;
  public thirdDocument: boolean;
  public fourthDocument: boolean;
  public fifthDocument: boolean;

  privacyPolicy: string;

  dataRegister: InitialData;
  userName: string;
  documentCheckPassed: boolean;
  profession: any;
  showOn: boolean;
  otherBank: boolean;
  isPanama: boolean = false;

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    protected confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private commonDataService: CommonDataService,
    private gtmService: GtmService,
    private middlewareService: MiddlewareService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.initForm();
    this.isTest = isTest();
    this.isSecondHolder = this.commonDataService.isSecondHolder;
    this.isMultTitulares = this.commonDataService.isMultTitulares;
    this.loadData();
  }

  private initForm() {
    this.formSignContract = this.formBuilder.group({
      blnCorrectData: [false, Validators.requiredTrue],
      blnMobileBanking: [false, Validators.requiredTrue],
      blnPEPs: [false, Validators.requiredTrue],
      blnUsaResident: [false, Validators.requiredTrue],
      blnDownloadLegalDocuments: [false, Validators.requiredTrue]
    });

    this.subscribeValueChanges();
  }

  loadData() {
    this.middlewareService.getUsersLookup().subscribe(
      (data) => {
        if (data.targetCountry == 'L_IBM') {
          this.userName = data.firstName;
          this.privacyPolicy = data.targetCountry;
          this.privacyPolicy = 'L_IBM';
          this.otherBank = false;
        }

        if (data.targetCountry == 'L_PANBM') {
          this.userName = data.firstName;
          this.privacyPolicy = data.targetCountry;
          this.privacyPolicy = 'L_PANBM';
          this.profession = data.profession;
          this.otherBank = true;

          this.isPanama = true;
        }

        if (data.targetCountry == 'L_PBM') {
          this.userName = data.firstName;
          this.privacyPolicy = data.targetCountry;
          this.privacyPolicy = 'L_PBM';
          this.profession = data.profession;
          this.otherBank = false;
        }
      },
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  private subscribeValueChanges() {
    this.subscription.add(
      this.formSignContract.get('blnPEPs').valueChanges.subscribe((value) => {
        if (value) {
          this.openInfoCheckModal('blnPEPs');
        }
      })
    );
    this.subscription.add(
      this.formSignContract
        .get('blnUsaResident')
        .valueChanges.subscribe((value) => {
          if (value) {
            this.openInfoCheckModal('blnUsaResident');
          }
        })
    );
  }

  checkDocuments(formControl: string, typeDocument?: string): void {
    if (formControl === 'blnDownloadLegalDocuments') {
      if (this.privacyPolicy == 'L_IBM') {
        this.firstDocument =
          this.firstDocument || typeDocument === 'firstDocument';
        this.fourthDocument =
          this.fourthDocument || typeDocument === 'fourthDocument';

        this.documentCheckPassed = this.firstDocument && this.fourthDocument;
      }

      if (this.privacyPolicy === 'L_PBM') {
        this.firstDocument =
          this.firstDocument || typeDocument === 'firstDocument';
        this.secondDocument =
          this.secondDocument || typeDocument === 'secondDocument';
        this.thirdDocument =
          this.thirdDocument || typeDocument === 'thirdDocument';
        this.fourthDocument =
          this.fourthDocument || typeDocument === 'fourthDocument';
        this.fifthDocument =
          this.fifthDocument || typeDocument === 'fifthDocument';

        this.documentCheckPassed =
          this.firstDocument &&
          this.secondDocument &&
          this.thirdDocument &&
          this.fourthDocument &&
          this.fifthDocument;
      }

      if (this.privacyPolicy === 'L_PANBM') {
        this.firstDocument =
          this.firstDocument || typeDocument === 'firstDocument';
        this.secondDocument =
          this.secondDocument || typeDocument === 'secondDocument';

        if (
          this.profession == 'NOTARIO' ||
          this.profession == 'AGENTE INMOBILIARIO' ||
          this.profession == 'CONTADOR' ||
          this.profession == 'ABOGADO'
        ) {
          this.showOn = true;
          this.thirdDocument =
            this.thirdDocument || typeDocument === 'thirdDocument';
          this.documentCheckPassed =
            this.firstDocument && this.secondDocument && this.thirdDocument;
        } else {
          this.showOn = false;
          this.documentCheckPassed = this.firstDocument && this.secondDocument;
        }
      }

      if (this.documentCheckPassed) {
        this.formSignContract.get(formControl).setValue(true);
      }
    }
  }

  private openInfoCheckModal(check: string) {
    let message: string;
    let message2: string[] = [];
    switch (check) {
      case 'blnPEPs':
        this.msgLoading = 'onboarding_title_modal_check_blnPEPs';
        message = 'onboarding_signing_blnPEPs_info_modal';

        break;
      case 'blnUsaResident':
        this.msgLoading = 'onboarding_signing_blnUsaResident_info_modal';
        message = '';
        message2 = [
          'onboarding_signing_blnUsaResident_info_modal_li_1',
          'onboarding_signing_blnUsaResident_info_modal_li_2'
        ];
        break;
    }

    this.confirmationService.confirm(<ModalDataType>{
      component: PichModalGenericComponent,
      data: {
        title: this.msgLoading,
        message: message,
        message2: message2,
        txtButton: 'onboarding_popup_received_information_button'
      },
      reject: () => {
        this.formSignContract.get(check).setValue(false);
      }
    });
  }

  getAction() {
    return ACTION.createClient;
  }

  /* getBodyPost() {
    const body: SignContractScreen = {
      strOppId: this.pichCService.getStrOppId(),
      holders: [
        {
          holder: HOLDER.PRIMARY,
          ...this.formSignContract.value
        }
      ]
    };

    if (this.isMultTitulares) {
      body.holders.push({
        holder: HOLDER.SECONDARY,
        ...this.formSignContract.value
      });
    }

  }
  */

  onNext() {
    if (this.formSignContract.valid) {
      this.middlewareService
        .submitTerms({
          blnConfirmPersonalData: true,
          blnComercialComunications: true,
          blnNoPEP: true,
          blnNoEEUCityZent: true,
          blnConfirmContractualInformation: true
        })
        .subscribe((data) => {
          this.routerC.navigate(['onboarding', this.nextStage]);
        });
      // Call is made to generate the pdf documents that will be signed in the adobesign view
      this.middlewareService.postGeneratePdf().subscribe((data) => {});
    } else {
      for (const campo in this.formSignContract.controls) {
        if (this.formSignContract.controls.hasOwnProperty(campo)) {
          this.formSignContract.controls[campo].markAsTouched();
        }
      }
    }
  }

  private sendGTM() {
    this.gtmService.sendVariableGTM({
      pageType: 'Firma de contrato'
    });

    this.gtmService.trackEvent('onboarding', 'paso 10', 'Firma de contrato');
  }
}
