import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommonService } from './../../../vass-core/services/common.service';
import { environment } from './../../../../environments/environment';
import {
  VerifyVideoIdentificationRequest,
  VerifyVideoIdentificationResponse,
  VideoIdentificationRequest,
  VideoIdentificationResponse
} from '../../model/api/video-identification.model';

@Injectable({
  providedIn: 'root'
})
export class OlimpiaService {
  constructor(private _commonService: CommonService) {}

  startVideoIdentification(
    body: VideoIdentificationRequest
  ): Observable<VideoIdentificationResponse> {
    const url = `${environment.apiPath}/onboarding/${environment.version.onboarding}/person/ocrvalidation`;
    return this._commonService.postVerb(url, body);
  }

  verifyVideoIdentification(
    body: VerifyVideoIdentificationRequest
  ): Observable<VerifyVideoIdentificationResponse> {
    const url = `${environment.apiPath}/onboarding/${environment.version.onboarding}/person/ocrvalidation/${body.agreemenProcessGuid} `;
    return this._commonService.postVerb(url, body);
  }
}
