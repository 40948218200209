import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ibanFormat' })
export class IbanPipe implements PipeTransform {
  transform(value: string, args: string[]): any {
    let iban = '';
    let contador = 1;
    if (value) {
      for (let i = 0; i < value.length; i++) {
        iban = iban + value.charAt(i);
        if (contador === 4) {
          iban = iban + ' ';
          contador = 0;
        }
        contador++;
      }
      return iban;
    }
  }
}
