import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OpportunityResponse } from '../../model/api/opportunity-response.model';

@Injectable({
  providedIn: 'root'
})
export class DetectedIdService {
  private opportunityPrimaryOwner = new BehaviorSubject<OpportunityResponse>(
    undefined
  );
  private opportunityAdditionalOwner = new BehaviorSubject<OpportunityResponse>(
    undefined
  );

  constructor() {}

  setOpportunity(opportunity: OpportunityResponse, isSecondHolder: boolean) {
    if (isSecondHolder) {
      this.opportunityAdditionalOwner.next(opportunity);
    } else {
      this.opportunityPrimaryOwner.next(opportunity);
    }
  }

  getOpportunity(isSecondHolder: boolean) {
    if (isSecondHolder) {
      return this.opportunityAdditionalOwner.pipe(
        filter((value) => value !== undefined)
      );
    }
    return this.opportunityPrimaryOwner.pipe(
      filter((value) => value !== undefined)
    );
  }
}
