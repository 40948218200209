import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { PichModalGenericComponent } from '../../../shared/components/modals/pich-modal-generic/pich-modal-generic.component';
import { PichBaseComponent } from '../pichincha-base.component';

import { ParamsModel } from '../../../shared/model/api/params.model';
import { ModalDataType } from '../../../vass-core/components/modal/modal-data-type.interface';
import { BaseData } from '../../model/base-data.model';
import { KYCData, KycDataScreen } from '../../model/params.model';
import { EconomicActivityModel } from './../../../shared/model/economic-activity.model';
import { GenericModalData } from './../../../shared/model/generic-modal-data.model';

import {
  ACTION,
  CASE_STAGES,
  CURRENT_POSITION,
  HOLDER,
  PATHS
} from '../../../shared/constants';

import { CommonDataService } from '../../../shared/services/common-data.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { GtmService } from './../../../shared/services/gtm/gtm.service';
import { OnboardingService } from './../../services/onboarding/onboarding.service';

import { MiddlewareService } from '../../../shared/services/middleware.service';
import { KYCDataService } from '../../services/kyc.data.service';
import {
  filterList,
  filterListProfession
} from './../../../shared/utils/filter-list';
import { ProfessionModel } from '../../../shared/model/profession.model';

@Component({
  selector: 'app-pich-activity',
  templateUrl: './pich-activity.component.html',
  styleUrls: ['./pich-activity.component.scss']
})
export class PichActivityComponent extends PichBaseComponent implements OnInit {
  formActivity: FormGroup;

  currentPosition$: Observable<Array<ParamsModel>>;
  employSituation$: Observable<Array<ParamsModel>>;
  currentPositionList: ParamsModel[] = [];
  employSituationList: ParamsModel[] = [];

  listSectorProfessional: Array<EconomicActivityModel> = [];
  filteredSectorProfessional = [];
  getProfessionalSector: string;
  listProfesion: Array<ProfessionModel> = [];
  filteredProfession = [];
  listNewSector: Array<EconomicActivityModel> = [];
  filteredNewSector = [];
  prevStage = PATHS.personalData2;
  nextStage = PATHS.activity2;
  caseStage = CASE_STAGES.kyc;

  isPanama = false;

  countries: Array<{ value: string; label: string }>;
  kycData: KYCData;

  HTML_VALIDATORS = {
    minLength: 2,
    maxLength: {
      companyNameAndAddress: 200,
      employSituation: 100,
      laborOld: 3,
      taxIdentificationNumber: 15
    }
  };
  MAX_LENGTH_DECIMAL = 1;
  profesion: boolean;
  dataLookup: any;
  sectorPanama: boolean = false;
  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    protected confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private commonDataService: CommonDataService,
    private readonly onboardingService: OnboardingService,
    private gtmService: GtmService,
    private kycDataService: KYCDataService,
    private middlewareService: MiddlewareService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  get transfersInOtherCountries() {
    return this.formActivity.get('checkIntTransfer');
  }

  ngOnInit() {
    this.initForm();
    this.loadData();
    this.loadConfigSelects();
    this.loadProfession();
    this.loadConfigSelects1();
    this.onboardingService.getCurrentPositions().subscribe((data) => {
      this.currentPositionList = data;
    });
    this.onboardingService.getEmploySituations().subscribe((data) => {
      this.employSituationList = data;
    });
  }

  loadConfigSelects() {
    this.currentPosition$ = this.onboardingService.getCurrentPositions();
    this.employSituation$ = this.onboardingService.getEmploySituations();
    this.kycData;

    this.subscription.add(
      this.onboardingService
        .getProfessionalSector()
        .pipe(
          map((list) => {
            return list.map((e) => {
              return {
                name: e.name,
                code: e.code,
                label: `${e.code} - ${e.name}`
              };
            });
          })
        )
        .subscribe(
          (data) => {
            this.listSectorProfessional = data;
          },
          (error) => {
            this.setErrorMessages(error.responseCode);
          }
        )
    );
  }

  loadConfigSelects1() {
    this.currentPosition$ = this.onboardingService.getCurrentPositions();
    this.employSituation$ = this.onboardingService.getEmploySituations();
    this.kycData;

    this.subscription.add(
      this.onboardingService
        .getProfessionalSector1()
        .pipe(
          map((list) => {
            return list.map((e) => {
              return {
                name: e.name,
                code: e.code,
                label: `${e.name}`
              };
            });
          })
        )
        .subscribe(
          (data) => {
            this.listNewSector = data;
          },
          (error) => {
            this.setErrorMessages(error.responseCode);
          }
        )
    );
  }

  loadProfession() {
    this.subscription.add(
      this.onboardingService
        .getProfession()
        .pipe(
          map((list) => {
            return list.map((e) => {
              return {
                name: e.name,
                label: `${e.name}`
              };
            });
          })
        )
        .subscribe(
          (data) => {
            this.listProfesion = data;
          },
          (error) => {
            this.setErrorMessages(error.responseCode);
          }
        )
    );
  }

  dateValidator(control: any) {
    if (control.value) {
      const regexDate = /^\d{4}-\d{2}-\d{2}$/;

      if (!regexDate.test(control.value)) {
        return { invalidDate: true };
      }
    }
    const [year, month, day] = control.value.split('-').map(Number);
    const date = new Date(year, month - 1, day);

    if (
      date.getFullYear() === year &&
      date.getMonth() + 1 === month &&
      date.getDate() === day
    ) {
      return null;
    } else {
      return { invalidDate: true };
    }
  }

  initForm() {
    this.formActivity = this.formBuilder.group({
      currentPosition: ['', Validators.required],
      professionalSector: ['', Validators.required],
      employSituation: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.employSituation)
        ]
      ],
      currentCompany: [
        '',
        [
          Validators.required,
          Validators.maxLength(
            this.HTML_VALIDATORS.maxLength.companyNameAndAddress
          ),
          Validators.pattern('^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\\s]+$')
        ]
      ],
      companyAddress: [
        '',
        [
          Validators.required,
          Validators.maxLength(
            this.HTML_VALIDATORS.maxLength.companyNameAndAddress
          ),
          Validators.pattern(/^[a-zA-ZÁÉÍÓÚáéíóú0-9 .,]+$/)
        ]
      ],
      laborOld: [
        null,
        [
          Validators.required,
          Validators.max(999),
          Validators.pattern(/^\d+(\.\d{1,1})?$/)
        ]
      ],
      profession: ['']
    });

    this.subscribeFormChanges();
  }

  private getInfoActivity() {
    return (this.formActivity = this.formBuilder.group({
      currentPosition: ['', Validators.required],
      professionalSector: ['', Validators.required],
      employSituation: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.employSituation)
        ]
      ],
      currentCompany: [
        '',
        [
          Validators.required,
          Validators.maxLength(
            this.HTML_VALIDATORS.maxLength.companyNameAndAddress
          )
        ]
      ],
      companyAddress: [
        '',
        [
          Validators.required,
          Validators.maxLength(
            this.HTML_VALIDATORS.maxLength.companyNameAndAddress
          ),
          Validators.pattern(/^[a-zA-ZÁÉÍÓÚáéíóú0-9 .,]+$/)
        ]
      ],
      laborOld: [
        null,
        [
          Validators.required,
          Validators.max(999),
          Validators.pattern(/^\d+(\.\d{1,1})?$/)
        ]
      ]
    }));
  }

  get currentPosition() {
    return this.formActivity.get('currentPosition');
  }
  get employSituation() {
    return this.formActivity.get('employSituation');
  }
  get professionalSector() {
    return this.formActivity.get('professionalSector');
  }
  get currentCompany() {
    return this.formActivity.get('currentCompany');
  }
  get laborOld() {
    return this.formActivity.get('laborOld');
  }
  get companyAddress() {
    return this.formActivity.get('companyAddress');
  }
  get startDate() {
    return this.formActivity.get('startDate');
  }
  get profession() {
    return this.formActivity.get('profession');
  }

  filterSectorProfessional(event) {
    this.filteredSectorProfessional = filterList(
      this.listSectorProfessional,
      event,
      'label'
    );
  }

  filterNewSector(event) {
    this.filteredNewSector = filterList(this.listNewSector, event, 'label');
  }

  filterProfessional(event) {
    this.filteredProfession = filterListProfession(
      this.listProfesion,
      event,
      'label'
    );
  }

  getFilteredProfessionalName(profession: string) {
    return this.listProfesion.find((sector) => sector.name === profession);
  }

  getFilteredProfessionalSectorId(economicActivity: string) {
    return this.listSectorProfessional.find(
      (sector) => sector.name === economicActivity
    );
  }

  getFilteredNewSectorId(economicActivity: string) {
    return this.listNewSector.find(
      (sector) => sector.name === economicActivity
    );
  }

  private subscribeFormChanges() {
    this.subscription.add(
      this.currentPosition.valueChanges.subscribe((value) => {
        if (value) {
          this.updateFields(value);
        }
      })
    );
  }

  getAction() {
    return ACTION.saveKYC;
  }

  getBodyPost() {
    const form = this.formActivity.value;
    const activityParams: KycDataScreen = {
      holder: this.isSecondHolder ? HOLDER.SECONDARY : HOLDER.PRIMARY,
      strOppId: this.pichCService.getStrOppId(),
      strCurrentPosition: form.currentPosition?.name,
      strCurrentPositionCode: form.currentPosition?.id,
      strOccupation: form.employSituation?.name,
      strOccupationCode: form.employSituation?.id,
      strProfessionalSectorCode: form.professionalSector
        ? form.professionalSector?.code
        : '',
      strProfessionalSector: form.professionalSector
        ? form.professionalSector?.name
        : '',
      strActivityTypeCode: form.activityType ? form.activityType?.code : '',
      strActivityType: form.activityType ? form.activityType?.name : '',
      strAccountPurpose: form.accountPurpose,
      numAssets: form.numAssets,
      numLiabilities: form.numLiabilities,
      numMonthlyIncome: form.numMonthlyIncome,
      numMonthlyExpenses: form.numMonthlyExpenses,
      numOtherIncome: form.numOtherIncome ? form.numOtherIncome : null,
      strOtherIncomeDetails: form.strOtherIncomeDetails,
      blnHasAcceptFundsStatement: form.blnHasAcceptFundsStatement
    };
    return new BaseData(this.getAction(), JSON.stringify(activityParams));
  }

  updateFields(currentPosition: string) {
    switch (currentPosition) {
      case CURRENT_POSITION.EMPLOYMENT:
        this.employSituation.setValue('');
        this.professionalSector.setValue('');
        this.currentCompany.setValue('');
        this.laborOld.setValue('');
        this.companyAddress.setValue('');
        this.professionalSector.enable();
        this.professionalSector.setValue('');
        this.employSituation.enable();
        this.currentCompany.enable();
        this.laborOld.enable();
        this.companyAddress.enable();
        break;
      case CURRENT_POSITION.FREELANCE:
        this.employSituation.setValue('');
        this.professionalSector.setValue('');
        this.currentCompany.setValue('');
        this.laborOld.setValue('');
        this.companyAddress.setValue('');
        this.professionalSector.enable();
        this.employSituation.disable();
        this.employSituation.setValue('');
        this.currentCompany.enable();
        this.laborOld.enable();
        this.companyAddress.enable();
        break;
      case CURRENT_POSITION.RETIRED:
        this.employSituation.setValue('');
        this.professionalSector.setValue('');
        this.currentCompany.setValue('');
        this.laborOld.setValue('');
        this.companyAddress.setValue('');
        this.employSituation.disable();
        this.professionalSector.disable();
        this.currentCompany.disable();
        this.laborOld.disable();
        this.companyAddress.disable();
    }
    if (this.isPanama) {
      this.startDate.setValue('');
      this.startDate.enable();
    }
  }

  openInfoModal() {
    const data: GenericModalData = {
      title: 'onboarding_popup_economic_situation_title',
      message: 'onboarding_popup_economic_situation_explain',
      txtButton: 'onboarding_popup_economic_situation_button'
    };

    this.confirmationService.confirm(<ModalDataType>{
      component: PichModalGenericComponent,
      data
    });
  }

  showInfoFundsStatementModal() {
    const data: GenericModalData = {
      title: 'onboarding_title_modal_check',
      message:
        'onboarding_activity_check_has_accept_funds_statement_info_modal',
      txtButton: 'onboarding_popup_received_information_button'
    };
  }

  sendForm() {
    if (this.profesion == false) {
      this.onNextStage1(this.nextStage);
    } else if (this.formActivity.valid) {
      this.onNextStage(this.nextStage);
    } else {
      this.formActivity.markAllAsTouched();
    }
  }

  loadData() {
    this.middlewareService.getUsersLookup().subscribe(
      (data) => {
        this.kycData = data;

        this.formActivity.patchValue({
          currentPosition: this.kycData.occupation
            ? this.kycData.occupation
            : '',
          professionalSector: this.getFilteredProfessionalSectorId(
            this.kycData.economicActivity
          ),
          profession: this.getFilteredProfessionalName(this.kycData.profession),
          currentCompany: data.companyJob,
          laborOld: data.companyYears,
          companyAddress: data.officeAddress,
          employSituation: this.kycData.position ? this.kycData.position : ''
        });
        this.dataLookup = data?.targetCountry;

        if (data.targetCountry === 'L_PANBM') {
          this.isPanama = true;
          this.profesion = true;
          this.sectorPanama = true;
          this.formActivity.addControl(
            'startDate',
            new FormControl(data?.startDate ?? '', [
              Validators.required,
              this.dateValidator
            ])
          );

          /* this.formActivity.addControl(
            'profession',
            new FormControl(data?.profession ?? '', [Validators.required])
          ); */
          this.prevStage = PATHS.personalDataOrigenDestiny;
        } else {
          this.profesion = false;

          return (this.kycData = data);
        }
      },
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  getKYCData1(): KYCData {
    let occupation: string;
    let economicActivity: string;
    let companyJob: string;
    let companyYears: number;
    let officeAddress: string;
    let position: string;
    let startDate: string = null;
    let profession: string = null;

    /* this.formActivity.get('profession').clearValidators(); */
    const form = this.formActivity.value;

    if (this.isPanama) {
      startDate = form.startDate;
    }
    occupation = form.currentPosition;

    switch (occupation) {
      case 'ASALARIADO':
        occupation = occupation;
        economicActivity = form.professionalSector.name;
        position = form.employSituation;
        companyJob = form.currentCompany;
        companyYears = form.laborOld;
        officeAddress = form.companyAddress;
        break;

      case 'INDEPENDIENTE':
        occupation = occupation;
        economicActivity = form.professionalSector.name;
        position = 'INDEPENDIENTE';
        companyJob = form.currentCompany;
        companyYears = form.laborOld;
        officeAddress = form.companyAddress;
        break;

      case 'JUBILADO':
        occupation = occupation;
        economicActivity = 'JUBILADO';
        position = 'No aplica';
        companyJob = 'No aplica';
        companyYears = 0;
        officeAddress = 'No aplica';
        break;
    }

    return {
      occupation: occupation,
      economicActivity: economicActivity,
      position: position,
      companyJob: companyJob,
      companyYears: companyYears,
      officeAddress: officeAddress,
      annualRevenue: this.kycData.annualRevenue,
      mainSourceRevenue: this.kycData.mainSourceRevenue,
      annualSalary: this.kycData.annualSalary,
      annualRent: this.kycData.annualRent,
      annualInvestments: this.kycData.annualInvestments,
      otherAnnualRevenue: this.kycData.otherAnnualRevenue,
      detailOtherRevenue: this.kycData.detailOtherRevenue,
      detailAssets: this.kycData.detailAssets,
      accountPurpose: this.kycData.accountPurpose,
      incomingTransfersMonth: this.kycData.incomingTransfersMonth,
      balanceIncomingTransfersMonth: this.kycData.balanceIncomingTransfersMonth,
      outcomingTransfersMonth: this.kycData.outcomingTransfersMonth,
      balanceOutcomingTransfersMonth:
        this.kycData.balanceOutcomingTransfersMonth,
      expectedInitialDeposit: this.kycData.expectedInitialDeposit,
      sourceWealth: this.kycData.sourceWealth,
      startDate,
      typeOfDeposit: this.kycData.typeOfDeposit,
      amountLimit: this.kycData.amountLimit,
      taxResidence: this.kycData.taxResidence,
      anotherReceptionReason: this.kycData.anotherReceptionReason,
      purposeSendTrans: this.kycData.purposeSendTrans,
      receptionReason: this.kycData.receptionReason,
      anotherPurposeSendTrans: this.kycData.anotherPurposeSendTrans,
      transfAchTxCred: this.kycData.transfAchTxCred,
      transfAchAmountCred: this.kycData.transfAchAmountCred,
      transfAchTxDeb: this.kycData.transfAchTxDeb,
      transfAchAmountDeb: this.kycData.transfAchAmountDeb,
      transfInterTxCred: this.kycData.transfInterTxCred,
      transfInterAmountCred: this.kycData.transfInterAmountCred,
      transfInterTxDeb: this.kycData.transfInterTxDeb,
      transfInterAmountDeb: this.kycData.transfInterAmountDeb,
      transfBppTxCred: this.kycData.transfBppTxCred,
      transfBppAmountCred: this.kycData.transfBppAmountCred,
      transfBppTxDeb: this.kycData.transfBppTxDeb,
      transfBppAmountDeb: this.kycData.transfBppAmountDeb,
      totalTxCred: this.kycData.totalTxCred,
      totalAmountCred: this.kycData.totalAmountCred,
      totalTxDeb: this.kycData.totalTxDeb,
      totalAmountDeb: this.kycData.totalAmountDeb,
      accountDetail: this.kycData.accountDetail,
      step: '1',
      profession: profession
    };
  }

  getKYCData(): KYCData {
    let occupation: string;
    let economicActivity: string;
    let companyJob: string;
    let companyYears: number;
    let officeAddress: string;
    let position: string;
    let startDate: string = null;
    let oldTime = this.formActivity.value.laborOld;

    const form = this.formActivity.value;
    if (this.isPanama) {
      startDate = form.startDate;
    }
    occupation = form.currentPosition;

    switch (occupation) {
      case 'ASALARIADO':
        occupation = occupation;
        economicActivity = form.professionalSector.name;
        position = form.employSituation;
        companyJob = form.currentCompany;
        companyYears = form.laborOld;
        officeAddress = form.companyAddress;
        break;

      case 'INDEPENDIENTE':
        occupation = occupation;
        economicActivity = form.professionalSector.name;
        position = 'INDEPENDIENTE';
        companyJob = form.currentCompany;
        companyYears = form.laborOld;
        officeAddress = form.companyAddress;
        break;

      case 'JUBILADO':
        occupation = occupation;
        economicActivity = 'JUBILADO';
        position = 'No aplica';
        companyJob = 'No aplica';
        companyYears = 0;
        officeAddress = 'No aplica';
        break;
    }

    return {
      occupation: occupation,
      economicActivity: economicActivity,
      position: position,
      companyJob: companyJob,
      companyYears: companyYears,
      officeAddress: officeAddress,
      annualRevenue: this.kycData.annualRevenue,
      mainSourceRevenue: this.kycData.mainSourceRevenue,
      annualSalary: this.kycData.annualSalary,
      annualRent: this.kycData.annualRent,
      annualInvestments: this.kycData.annualInvestments,
      otherAnnualRevenue: this.kycData.otherAnnualRevenue,
      detailOtherRevenue: this.kycData.detailOtherRevenue,
      detailAssets: this.kycData.detailAssets,
      accountPurpose: this.kycData.accountPurpose,
      incomingTransfersMonth: this.kycData.incomingTransfersMonth,
      balanceIncomingTransfersMonth: this.kycData.balanceIncomingTransfersMonth,
      outcomingTransfersMonth: this.kycData.outcomingTransfersMonth,
      balanceOutcomingTransfersMonth:
        this.kycData.balanceOutcomingTransfersMonth,
      expectedInitialDeposit: this.kycData.expectedInitialDeposit,
      sourceWealth: this.kycData.sourceWealth,
      startDate,
      typeOfDeposit: this.kycData.typeOfDeposit,
      amountLimit: this.kycData.amountLimit,
      taxResidence: this.kycData.taxResidence,
      anotherReceptionReason: this.kycData.anotherReceptionReason,
      purposeSendTrans: this.kycData.purposeSendTrans,
      receptionReason: this.kycData.receptionReason,
      anotherPurposeSendTrans: this.kycData.anotherPurposeSendTrans,
      transfAchTxCred: this.kycData.transfAchTxCred,
      transfAchAmountCred: this.kycData.transfAchAmountCred,
      transfAchTxDeb: this.kycData.transfAchTxDeb,
      transfAchAmountDeb: this.kycData.transfAchAmountDeb,
      transfInterTxCred: this.kycData.transfInterTxCred,
      transfInterAmountCred: this.kycData.transfInterAmountCred,
      transfInterTxDeb: this.kycData.transfInterTxDeb,
      transfInterAmountDeb: this.kycData.transfInterAmountDeb,
      transfBppTxCred: this.kycData.transfBppTxCred,
      transfBppAmountCred: this.kycData.transfBppAmountCred,
      transfBppTxDeb: this.kycData.transfBppTxDeb,
      transfBppAmountDeb: this.kycData.transfBppAmountDeb,
      totalTxCred: this.kycData.totalTxCred,
      totalAmountCred: this.kycData.totalAmountCred,
      totalTxDeb: this.kycData.totalTxDeb,
      totalAmountDeb: this.kycData.totalAmountDeb,
      accountDetail: this.kycData.accountDetail,
      step: '1',
      profession: form.profession?.name
    };
  }

  onNextStage(stage: string) {
    if (this.formActivity.valid) {
      this.middlewareService.submitKYCData(this.getKYCData()).subscribe(
        (data) => {
          this.kycDataService.setKYCData(this.getKYCData());
          this.routerC.navigate(['onboarding', stage]);
        },
        (error) => {
          this.setErrorMessages(error.responseCode);
        }
      );
    }
  }
  onNextStage1(stage: string) {
    this.middlewareService.submitKYCData(this.getKYCData1()).subscribe(
      (data) => {
        this.kycDataService.setKYCData(this.getKYCData1());
        this.routerC.navigate(['onboarding', stage]);
      },
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  onBackStage(prevStage: string) {
    if (this.isMultTitulares) {
      if (this.isSecondHolder) {
        this.commonDataService.setIsSecondHolder(false);
        this.ngOnInit();
      } else {
        this.commonDataService.setIsSecondHolder(true);
        this.routerC.navigate(['onboarding', prevStage]);
      }
    } else {
      this.routerC.navigate(['onboarding', prevStage]);
    }
  }

  // private sendGTM() {
  //   const form = this.formActivity.value;
  //   this.gtmService.sendVariableGTM({
  //     pageType: 'Actividad Economica',
  //     ocupacion: form.currentPosition?.name,
  //     cargoActual: form.employSituation?.name,
  //     activos: form.numAssets,
  //     pasivos: form.numLiabilities,
  //     ingresosMensuales: form.numMonthlyIncome,
  //     gastosMensuales: form.numMonthlyExpenses,
  //     otrosIngresos: form.numOtherIncome
  //   });

  //   this.gtmService.trackEvent('onboarding', 'paso 9', 'Actividad Economica');
  // }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
