import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalDataType } from './modal-data-type.interface';
import { ModalService } from './modal.service';

@Injectable()
export class ConfirmationService {
  constructor(private modalService: ModalService) {}

  protected requireConfirmationSource = new Subject<ModalDataType>();
  protected acceptConfirmationSource = new Subject<ModalDataType>();

  requireConfirmation$ = this.requireConfirmationSource.asObservable();
  accept = this.acceptConfirmationSource.asObservable();

  confirm(confirmation: ModalDataType) {
    this.requireConfirmationSource.next(confirmation);
    return this;
  }

  onAccept() {
    this.acceptConfirmationSource.next();
  }

  hiddenModal() {
    this.modalService.hiddenModal();
  }
}
