import { HolderModel } from './holder-model';

export class InitialData {
  hasMultipleHolders: boolean;
  firstHolder: HolderModel;
  secondHolder: HolderModel;
  sourceCountry: string;
  targetCountry: string;
  blnFinancialInfoConsent: boolean;
  blnPersonalDataConsent: boolean;
  blnPersonalDataSharingConsent: boolean;
  blnSocialMediaContactConsent: boolean;
  privacyPolicy: string;

  constructor() {
    this.firstHolder = new HolderModel();
    this.secondHolder = new HolderModel();
    this.sourceCountry = '';
    this.targetCountry = '';
  }
}
