import { Component } from '@angular/core';
import { ModalComponent } from './../../../../vass-core/components/modal/modal.component';
import { GenericModalData } from './../../../model/generic-modal-data.model';

@Component({
  selector: 'app-pich-modal-generic',
  templateUrl: './pich-modal-generic.component.html',
  styleUrls: ['./pich-modal-generic.component.scss']
})
export class PichModalGenericComponent {
  public data: GenericModalData = {
    acceptStyleButton: 'btn btn-01',
    cancelStyleButton: 'btn btn-01'
  };

  constructor(private modalComponent: ModalComponent) {
    if (this.modalComponent.data) {
      this.data = this.modalComponent.data;
      this.data.acceptStyleButton = this.modalComponent.data.acceptStyleButton
        ? this.modalComponent.data.acceptStyleButton
        : 'btn btn-01';
      this.data.cancelStyleButton = this.modalComponent.data.cancelStyleButton
        ? this.modalComponent.data.cancelStyleButton
        : 'btn btn-01';
    }
  }

  public accept() {
    this.modalComponent.accept();
  }

  public cancel() {
    this.modalComponent.reject();
  }
}
