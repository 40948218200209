import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[lowerCase]'
})
export class LowerCaseDirective implements OnInit {
  constructor(private ngControl: NgControl) {}

  ngOnInit() {
    this.toLowerCase();
  }

  @HostListener('input') onKeyUp() {
    this.toLowerCase();
  }

  @HostListener('blur')
  onBlurEvent() {
    this.toLowerCase();
  }

  private toLowerCase() {
    this.ngControl.control?.setValue(
      this.ngControl?.control?.value?.toLowerCase(),
      {
        emitEvent: false
      }
    );
  }
}
