import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClipboardModule } from 'ngx-clipboard';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';

import { HoldersIconsComponent } from './components/holders-icons/holders-icons.component';
import { ErrorModalMessageComponent } from './components/modals/error-modal-message/error-modal-message.component';
import { PichModalGenericComponent } from './components/modals/pich-modal-generic/pich-modal-generic.component';
import { SiBank404PageComponent } from './components/sibank-404-page/sibank-404-page.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';

import { HandleErrorService } from './services/handle-error.service';
import { MiddlewareService } from './services/middleware.service';

import { AccountOwnerGuardGuard } from './guards/account-owner/account-owner-guard.guard';
import { AccountTypeGuardGuard } from './guards/account-type/account-type-guard.guard';
import { ContactDataGuardGuard } from './guards/contact-data/contact-data-guard.guard';
import { InitialDataGuardGuard } from './guards/initial-data/initial-data-guard.guard';
import { NavigationGuard } from './guards/navigation.guard';
import { ViewContractGuard } from './guards/view-contract.guard';

import { HiddenPhonePipe } from './pipes/hidden-phone/hidden-phone.pipe';
import { IbanPipe } from './pipes/iban/iban.pipe';

import { LocalStorageService } from 'ngx-webstorage';
import { DropdownModule } from 'primeng/dropdown';
import { BulletsComponent } from './components/bullets/bullets.component';
import { TagBulletsComponent } from './components/tag-bullets/tag-bullets.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { CleanInputDirective } from './directives/clean-input.directive';
import { DateDirective } from './directives/date/date.directive';
import { DocumentNumberDirective } from './directives/document-number/document-number.directive';
import { LowerCaseDirective } from './directives/lowercase.directive';
import { NoFirstSpaceDirective } from './directives/no-first-space.directive';
import { OnlyLettersDirective } from './directives/only-letters.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ParseCurrencyDirective } from './directives/parse-currency/parse-currency.directive';
import { ShowPasswordDirective } from './directives/show-password/show-password.directive';
import { TrimValueDirective } from './directives/trim-value/trim-value.directive';
import { CryptoService } from './services/crypto/crypto.service';
import { OlimpiaService } from './services/olimpia/olimpia.service';
import { SignatureService } from './services/signature/signature.service';
import { TableModule } from 'primeng/table';
import { HashtagPipe } from './pipes/hashtag/hashtag.pipe';
import { InputNumberModule } from 'primeng/inputnumber';
import { OnlyLettersAndNumbersDirective } from './directives/only-letters-and-numbers.directive';

@NgModule({
  declarations: [
    SiBank404PageComponent,
    ErrorModalMessageComponent,
    IbanPipe,
    PichModalGenericComponent,
    UploadFileComponent,
    OnlyLettersDirective,
    LowerCaseDirective,
    OnlyNumbersDirective,
    NoFirstSpaceDirective,
    CleanInputDirective,
    CleanInputDirective,
    HoldersIconsComponent,
    HiddenPhonePipe,
    DocumentNumberDirective,
    ParseCurrencyDirective,
    ShowPasswordDirective,
    DateDirective,
    TrimValueDirective,
    TooltipComponent,
    BulletsComponent,
    TagBulletsComponent,
    HashtagPipe,
    OnlyLettersAndNumbersDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    ClipboardModule,
    CalendarModule,
    PdfViewerModule,
    TableModule,
    DropdownModule,
    InputNumberModule
  ],
  exports: [
    ErrorModalMessageComponent,
    IbanPipe,
    PichModalGenericComponent,
    UploadFileComponent,
    OnlyLettersDirective,
    LowerCaseDirective,
    OnlyNumbersDirective,
    NoFirstSpaceDirective,
    CleanInputDirective,
    HoldersIconsComponent,
    HiddenPhonePipe,
    DocumentNumberDirective,
    ParseCurrencyDirective,
    ShowPasswordDirective,
    DateDirective,
    TrimValueDirective,
    TooltipComponent,
    BulletsComponent,
    DropdownModule,
    TableModule,
    TagBulletsComponent,
    HashtagPipe,
    InputNumberModule,
    OnlyLettersAndNumbersDirective
  ],
  providers: [
    HandleErrorService,
    NavigationGuard,
    ViewContractGuard,
    AccountOwnerGuardGuard,
    AccountTypeGuardGuard,
    InitialDataGuardGuard,
    ContactDataGuardGuard,
    MiddlewareService,
    MessageService,
    LocalStorageService,
    CryptoService,
    SignatureService,
    OlimpiaService
  ]
})
export class SharedModule {}
