import { Directive, Input, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[appCleanInput]'
})
export class CleanInputDirective {
  @Input() formControlInput: FormControl;
  constructor() {}

  @HostListener('click') onClick() {
    if (this.formControlInput.enabled) {
      this.formControlInput.setValue('');
    }
  }
}
