import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { CommonDataService } from '../../services/common-data.service';
import { environment } from '../../../../environments/environment';
import { PATHS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class AccountTypeGuardGuard implements CanActivate {
  constructor(
    private commonDataService: CommonDataService,
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const userAux = this.commonDataService.getDataRegister();
    if (
      userAux.hasMultipleHolders !== null &&
      userAux.hasMultipleHolders !== undefined
    ) {
      return true;
    } else {
      this.router.navigate(['onboarding', PATHS.welcome]);
    }
  }
}
