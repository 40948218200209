import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '../../../../vass-core/components/modal/modal.component';

@Component({
  selector: 'error-modal-message',
  templateUrl: './error-modal-message.component.html',
  styleUrls: ['./error-modal-message.component.scss']
})
export class ErrorModalMessageComponent implements OnInit {
  public message: string;
  public title: string;
  public txtButton: string;
  public urlImg: string | undefined;
  public redirectTo: boolean | undefined;

  constructor(private modalComponent: ModalComponent) {}

  ngOnInit() {
    this.title = this.modalComponent.data.title;
    this.message = this.modalComponent.data.message;
    this.txtButton = this.modalComponent.data.btnText;
    this.urlImg = this.modalComponent.data?.urlImg;
    this.redirectTo = this.modalComponent.data?.redirectTo;
  }

  public accept() {
    this.modalComponent.accept();
  }
}
