import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatusDocumentUpload } from '../../model/status-document-upload.model';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  @ViewChild('inputFile', { static: false }) inputFile: ElementRef;
  @Output() onChangeImg: EventEmitter<StatusDocumentUpload> =
    new EventEmitter();
  @Input() maxSize = 8;
  @Input() supportedFiles = 'image/jpg,image/png,image/jpeg,application/pdf';

  @Input() documentCode;

  statusFile: StatusDocumentUpload = {} as StatusDocumentUpload;

  constructor(private translate: TranslateService) {}

  ngOnInit() {}

  uploadImg(inputFile: any) {
    const maxBytes = this.maxSize * 1048576;
    const imgData = inputFile.target.files[0];
    const format = imgData.type.split('/');
    if (imgData && this.supportedFiles.indexOf(imgData.type) <= 0) {
      this.onChangeImg.emit({
        documentCode: this.documentCode,
        statusUpload: 'ko',
        img: imgData,
        size: imgData.size,
        type: format[1],
        base64: '',
        errorMessage: 'onboarding_error_invalid_format'
      });
    } else if (imgData && imgData.size > maxBytes) {
      this.onChangeImg.emit({
        documentCode: this.documentCode,
        statusUpload: 'ko',
        img: imgData,
        size: imgData.size,
        type: format[1],
        base64: '',
        errorMessage: 'onboarding_error_maxSize'
      });
    } else {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const splittedCodedFile = (<string>fileReader.result).split(',');
        // Enviar la imagen
        this.onChangeImg.emit({
          documentCode: this.documentCode,
          statusUpload: 'ok',
          img: imgData,
          size: imgData.size,
          type: format[1],
          base64: splittedCodedFile[1],
          errorMessage: ''
        });
      };
      fileReader.readAsDataURL(imgData);
    }
    inputFile.target.value = '';
  }

  openExplorerFile(code: string) {
    this.documentCode = code;
    this.inputFile.nativeElement.click();
  }
}
