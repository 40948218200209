import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { CustomHttpParams } from './custom-http-params.class';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CommonService {
  constructor(private http: HttpClient) {}

  private handleError(error: Response | any): any {
    const errorMsg = 'Error';
    return throwError(error);
  }

  public postVerb<T>(
    endpoint: string,
    data: any,
    loaderUse?: boolean
  ): Observable<T | any> {
    const httpType: boolean = loaderUse ? loaderUse : true;

    return this.http
      .post<T>(endpoint, data, { params: new CustomHttpParams(httpType) })
      .pipe(catchError(this.handleError));
  }

  public putVerb<T>(
    endpoint: string,
    data: any,
    loaderUse?: boolean
  ): Observable<T | any> {
    const httpType: boolean = loaderUse ? loaderUse : true;

    return this.http
      .put<T>(endpoint, data, { params: new CustomHttpParams(httpType) })
      .pipe(catchError(this.handleError));
  }

  public getVerb<T>(
    endpoint: string,
    loaderUse?: boolean
  ): Observable<T | any> {
    const httpType: boolean = loaderUse ? loaderUse : true;

    return this.http
      .get<T>(endpoint, { params: new CustomHttpParams(httpType) })
      .pipe(catchError(this.handleError));
  }

  public deleteVerb<T>(
    endpoint: string,
    loaderUse?: boolean
  ): Observable<T | any> {
    const httpType: boolean = loaderUse ? loaderUse : true;

    return this.http
      .delete<T>(endpoint, { params: new CustomHttpParams(httpType) })
      .pipe(catchError(this.handleError));
  }
}
