export class HolderModel {
  strEmail: string | null;
  strPhone: string | null;
  strName: string | null;
  strDialCode: string | null;
  blnCheckFinancialCreditInformation: boolean;
  blnTreatmentPersonalInformation: boolean;
  blnSharePersonalDataEconomicGroup: boolean;
  blnCheckDataPrivacyPolicy: boolean;
  integrationId: string;
  timeStamp: string;
  otpKey: string;

  constructor() {
    this.strEmail = null;
    this.strPhone = null;
    this.strName = null;
    this.strDialCode = null;
  }
}
