import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { RecoverModel } from '../model/recover.model';

import { NEXT_SCREEN, PATHS } from '../../shared/constants';

import { ENDPOINTS } from '../../shared/constants/endpoints';
import { CommonDataService } from '../../shared/services/common-data.service';
import { CommonService } from '../../vass-core/services/common.service';
import {
  DocumentDto,
  DocumentStatus,
  GeneratedDocuments
} from '../model/params.model';
import { CaseDataService } from '../shared/services/case-data.service';

@Injectable()
export class PichCommonHttpService {
  private userDataRecover: RecoverModel;

  constructor(
    private _commonService: CommonService,
    private caseDataSrv: CaseDataService,
    private commonDataService: CommonDataService
  ) {}

  setStrOppId(caseId: string) {
    this.caseDataSrv.setStrOppId(caseId);
  }

  getStrOppId() {
    return this.caseDataSrv.getStrOppId();
  }

  setOptOpportunyId(optOpportunyId: string) {
    this.caseDataSrv.setOptOpportunyId(optOpportunyId);
  }

  getoptOpportunyId(): string {
    return this.caseDataSrv.getoptOpportunyId();
  }

  // setEmail(email: string) {
  //   this.caseDataSrv.setEmail(email.toLowerCase());
  // }

  public translateCaseStageToRoute(
    caseStage: string,
    nextScreen?: string
  ): string {
    let path = '';
    switch (caseStage) {
      case 'OB_DOC_UPLOAD':
        return PATHS.identityManualConfirm;
      case 'OB_PER_DATA':
        return PATHS.personalData1;
      case 'OB_KYC':
        return PATHS.activity;
      case 'OB_PDF_UPLOAD':
        return PATHS.viewContract;
      case 'OB_DOC_VALIDATION':
        return PATHS.finalStep;
      case 'OB_DOC_REVIEW':
        if (
          nextScreen === NEXT_SCREEN.FINAL_STEP ||
          nextScreen === NEXT_SCREEN.DOCS_AND_PERSONAL
        )
          return PATHS.documentReview;
        else if (nextScreen === NEXT_SCREEN.PERSONAL_DATA)
          return PATHS.personalData1;

      case 'OB_PDF_GEN':
        return PATHS.adobeSignView;
      case 'OB_ACCEPTANCE_PROPOSAL':
        return PATHS.signContract;

      // case 'OB_CANCELLED':
      // case 'OB_CLOSED':
      //   return PATHS.welcome;
      // //   return PATHS.documentReview;
    }
    return path;
  }

  /** Metodo utilizado para guardad en el
   *
   * @param userData datos obtenidos desde el recover.
   */
  saveDataRecover(userData: RecoverModel) {
    this.userDataRecover = userData;
  }

  getDataRecover(): RecoverModel {
    return this.userDataRecover;
  }

  getUsersLookup(): Observable<any> {
    const url = `${environment.apiPath}${ENDPOINTS.users}`;
    return this._commonService.getVerb(url);
  }

  getDocumentsStatus(): Observable<DocumentStatus[]> {
    const url = `${environment.apiPath}${ENDPOINTS.documents}`;
    return this._commonService.getVerb(url);
  }
  getDocumentsToSign(): Observable<GeneratedDocuments[]> {
    const url = `${environment.apiPath}${ENDPOINTS.documents}`;
    return this._commonService.getVerb(url);
  }

  fillDocument(documentDto: DocumentDto): Observable<any> {
    const url = `${environment.apiPath}${ENDPOINTS.documents}`;
    return this._commonService.postVerb(url, documentDto);
  }
}
