export const ENDPOINTS = {
  newOpportunity: 'newOpportunity',
  opportunities: 'opportunities',
  users: 'users/lookup',
  documents: 'documents',
  files: 'files',
  personalInfo: 'users/personal-info',
  countries: 'countries',
  terms: 'users/terms-acceptance',
  kyc: 'users/kyc',
  generatePdf: 'generatepdf',
  sign: 'sign',
  executives: 'executives'
};

export const ENV = 'prod';
