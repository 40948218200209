import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PATHS } from './../shared/constants';

import { SiBank404PageComponent } from '../shared/components/sibank-404-page/sibank-404-page.component';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { PichActivity2Component } from './components/pich-activity-2/pich-activity-2.component';
import { PichActivity3Component } from './components/pich-activity-3/pich-activity-3.component';
import { PichActivityComponent } from './components/pich-activity/pich-activity.component';
import { PichAddressDataComponent } from './components/pich-address-data/pich-address-data.component';
import { PichCompletedComponent } from './components/pich-completed/pich-completed.component';
import { PichContactDataComponent } from './components/pich-contact-data/pich-contact-data.component';
import { PichContractViewComponent } from './components/pich-contract-view/pich-contract-view.component';
import { PichDocumentTypeComponent } from './components/pich-document-type/pich-document-type.component';
import { PichIdentityManualConfirmationComponent } from './components/pich-identity-manual-confirmation/pich-identity-manual-confirmation.component';
import { PichInitialDataComponent } from './components/pich-initial-data/pich-initial-data.component';
import { PichPersonalData1Component } from './components/pich-personal-data-1/pich-personal-data-1.component';
import { PichPersonalData2Component } from './components/pich-personal-data-2/pich-personal-data-2.component';
import { PichSignContractComponent } from './components/pich-sign-contract/pich-sign-contract.component';
import { PichWelcomeComponent } from './components/pich-welcome/pich-welcome.component';
import { PichinchaContainerComponent } from './containers/pichincha-container.component';

import { AccountOwnerGuardGuard } from '../shared/guards/account-owner/account-owner-guard.guard';
import { ContactDataGuardGuard } from '../shared/guards/contact-data/contact-data-guard.guard';
import { IdentityManualConfirmGuard } from '../shared/guards/identity-manual-confirm/identity-manual-confirm.guard';
import { InitialDataGuardGuard } from '../shared/guards/initial-data/initial-data-guard.guard';
import { NavigationGuard } from '../shared/guards/navigation.guard';
import { AccountOwnerComponent } from './components/account-owner/account-owner.component';
import { PichAdobeSignViewComponent } from './components/pich-adobe-sign/pich-adobe-sign.component';
import { PichContractUploadComponent } from './components/pich-contract-upload/pich-contract-upload.component';
import { DocumentReviewComponent } from './components/pich-document-review/pich.document.review.component';
import { PichFinalStepComponent } from './components/pich-final-step/pich-final-step.component';
import { RiskMatrixErrorComponent } from './components/risk-matrix-error/risk-matrix-error.component';
import { VideoIdentificationComponent } from './components/video-identification/video-identification.component';
import { PichOrigenDestinyComponent } from './components/pich-origen-destiny/pich-origen-destiny.component';
import { PichCalculatedTransactionalProfileComponent } from './components/pich-calculated-transactional-profile/pich-calculated-transactional-profile.component';

const routes: Routes = [
  {
    path: '',
    component: PichinchaContainerComponent,
    children: [
      {
        path: PATHS.welcome,
        component: PichWelcomeComponent
      },
      {
        path: PATHS.accountOwner,
        component: AccountOwnerComponent,
        canActivate: [AccountOwnerGuardGuard]
      },
      {
        path: PATHS.initialData,
        component: PichInitialDataComponent,
        canActivate: [InitialDataGuardGuard]
      },
      {
        path: PATHS.contactData,
        component: PichContactDataComponent,
        canActivate: [ContactDataGuardGuard]
      },
      {
        path: PATHS.identityManualConfirm,
        component: PichIdentityManualConfirmationComponent,
        canActivate: [NavigationGuard, IdentityManualConfirmGuard]
      },
      {
        path: PATHS.contractUpload,
        component: PichContractUploadComponent,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.documentReview,
        component: DocumentReviewComponent,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.finalStep,
        component: PichFinalStepComponent,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.documentType,
        component: PichDocumentTypeComponent,
        canActivate: [NavigationGuard]
      },

      {
        path: PATHS.videoIdentification,
        component: VideoIdentificationComponent,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.personalData1,
        component: PichPersonalData1Component,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.personalData2,
        component: PichPersonalData2Component,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.personalDataOrigenDestiny,
        component: PichOrigenDestinyComponent,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.addressData,
        component: PichAddressDataComponent,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.activity,
        component: PichActivityComponent,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.activity2,
        component: PichActivity2Component,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.activity3,
        component: PichActivity3Component,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.activity4,
        component: PichCalculatedTransactionalProfileComponent,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.signContract,
        component: PichSignContractComponent,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.viewContract,
        component: PichContractViewComponent,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.completed,
        component: PichCompletedComponent,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.adobeSignView,
        component: PichAdobeSignViewComponent,
        canActivate: [NavigationGuard]
      },
      {
        path: PATHS.locked,
        component: RiskMatrixErrorComponent
      },
      {
        path: 'password',
        component: CreatePasswordComponent,
        canActivate: [NavigationGuard]
      },
      { path: PATHS.notfound, component: SiBank404PageComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PichinchaContainerRoutingModule {}
