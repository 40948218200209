export interface NewUserModel {
  holders: Array<Holder>;
}

interface Holder {
  strEmail: string;
  strPhone: string;
  blnCheckFinancialCreditInformation: boolean;
  blnTreatmentPersonalInformation: boolean;
  blnSharePersonalDataEconomicGroup: boolean;
  blnCheckDataPrivacyPolicy: boolean;
  integrationId: string;
  timeStamp: string;
  otpKey: string;
  holder: string;
}
