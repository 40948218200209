import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CommonService } from './../../vass-core/services/common.service';

import { environment } from '../../../environments/environment';

import {
  LoginOnBoardingDto,
  LoginResponse
} from '../model/api/login-response.model';
import { MunicipalityModel } from '../model/api/municipality.model';
import { OlimpiaRequestModel } from '../model/api/olimpia-request.model';
import { OpportunityRequest } from '../model/api/opportunity-request.model';
import { OpportunityResponse } from '../model/api/opportunity-response.model';
import { ParamsModel } from '../model/api/params.model';
import { ValidateOtpOpportunityResponse } from '../model/api/validate-otp-opportunity-response.model';
import { DepartmentModel } from '../model/deparment.model';

import {
  FileDTO,
  KYCData,
  PersonalInfoModel,
  PostGeneratePdf,
  SignAdobeDocuments,
  TermsAcceptance
} from '../../pichincha-onboarding/model/params.model';
import { ENDPOINTS } from '../constants/endpoints';
import { CountryModel } from '../model/api/country.model';
import { RecoveryModel } from '../model/api/recovery.model';
import {
  ActionResponse,
  NewUserModel
} from './../../pichincha-onboarding/model/models';
import { CustomHttpParams } from './../../vass-core/services/custom-http-params.class';
import { executivesModel } from '../model/api/executive.model';

@Injectable()
export class MiddlewareService {
  private httpWithoutInterceptor: HttpClient;

  constructor(
    private _commonService: CommonService,
    private http: HttpClient,
    private httpBackend: HttpBackend
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  getBlob(s3reference: string): Observable<any> {
    return this.httpWithoutInterceptor.get(s3reference, {
      responseType: 'blob'
    });
  }

  getDepartments(): Observable<Array<DepartmentModel>> {
    const url = `${environment.apiPath}/onboarding/${environment.version.onboarding}/department`;
    return this._commonService.getVerb(url);
  }

  getDocumentType(): Observable<Array<ParamsModel>> {
    const url = `${environment.apiPath}/onboarding/${environment.version.onboarding}/documentType`;
    return this._commonService.getVerb(url);
  }

  getMunicipality(
    codeDepartment: string
  ): Observable<Array<MunicipalityModel>> {
    const url = `${environment.apiPath}/onboarding/${environment.version.onboarding}/municipality/${codeDepartment}`;
    return this.http.get<Array<MunicipalityModel>>(url);
  }

  generateOtpMail(body: OpportunityRequest): Observable<OpportunityResponse> {
    return this._commonService.postVerb(
      `${environment.apiPath}onboarding/${environment.version.onboarding}/agreement/opportunity/generate`,
      body,
      true
    );
  }

  validateOtpMail(
    otp: string,
    otpProcessId: string
  ): Observable<ValidateOtpOpportunityResponse> {
    const optEndocde = encodeURI(otpProcessId);
    return this._commonService.postVerb<ValidateOtpOpportunityResponse>(
      `${environment.apiPath}opportunities/${optEndocde}/otp`,
      { otp },
      true
    );
  }

  loginOnboarding(body: RecoveryModel): Observable<LoginOnBoardingDto> {
    const url = `${environment.apiPath}${ENDPOINTS.opportunities}`;
    return this.http.post<LoginOnBoardingDto>(url, body, {
      params: new CustomHttpParams(true)
      //   responseType: 'text' as 'json',
    });
  }

  confirmOtpLogin(
    otp: string,
    optOpportunyId: string
  ): Observable<LoginResponse> {
    const url = `${environment.apiPath}${ENDPOINTS.opportunities}/${optOpportunyId}/otp`;
    return this._commonService.postVerb(url, { otp }, true);
  }

  saveOcrData(body: OlimpiaRequestModel) {
    return this._commonService.postVerb(
      `${environment.apiPath}onboarding/${environment.version.onboarding}/person/ocrvalidation`,
      body,
      true
    );
  }

  saveMockOCR(body: {
    opportunityId: string;
    holder: string;
    name: string;
    documentType: string;
    documentNumber: string;
    email: string;
    celular: string;
  }): Observable<any> {
    return this._commonService.postVerb(
      `https://mocks-pibankcolombia.up.railway.app/olimpia/saveMockOcr`,
      body,
      true
    );
  }

  newHolders(body: {
    action_method: string;
    param: NewUserModel;
  }): Observable<ActionResponse> {
    return this._commonService.postVerb(
      `${environment.apiPath}onboarding/${environment.version.onboarding}/agreement/new-2-holders`,
      body,
      true
    );
  }

  getUsersLookup(): Observable<any> {
    const url = `${environment.apiPath}${ENDPOINTS.users}`;
    return this._commonService.getVerb(url);
  }

  setPersonalInfo(body: PersonalInfoModel): Observable<any> {
    const url = `${environment.apiPath}${ENDPOINTS.personalInfo}`;
    return this._commonService.postVerb(url, body, true);
  }

  submitKYCData(body: KYCData): Observable<any> {
    const url = `${environment.apiPath}${ENDPOINTS.kyc}`;
    return this._commonService.postVerb(url, body, true);
  }

  submitTerms(body: TermsAcceptance): Observable<any> {
    const url = `${environment.apiPath}${ENDPOINTS.terms}`;
    return this._commonService.postVerb(url, body, true);
  }

  uploadFile(body: FileDTO): Observable<any> {
    const url = `${environment.apiPath}${ENDPOINTS.files}`;
    return this._commonService.postVerb(url, body, true);
  }

  returnPresignedUrl(referenceName: string): Observable<any> {
    return this._commonService.getVerb(
      `${environment.apiPath}onboarding/${environment.version.onboarding}/files/${referenceName}`
    );
  }

  getCountries(): Observable<Array<CountryModel>> {
    const url = `${environment.apiPath}${ENDPOINTS.countries}`;
    return this._commonService.getVerb(url);
  }

  getFilenameReference(ref: string): Observable<any> {
    const url = `${environment.apiPath}${ENDPOINTS.files}/${ref}`;
    return this._commonService.getVerb(url);
  }

  postGeneratePdf(): Observable<PostGeneratePdf> {
    const url = `${environment.apiPath}${ENDPOINTS.generatePdf}`;
    return this._commonService.postVerb(url, null, true);
  }
  postSignAdobeDocuments(): Observable<string> {
    const url = `${environment.apiPath}${ENDPOINTS.sign}`;
    return this._commonService.postVerb<string>(url, null, true);
  }

  getSignAdobeDocuments(agreementId: string): Observable<SignAdobeDocuments> {
    const url = `${environment.apiPath}${ENDPOINTS.sign}/${agreementId}`;
    return this._commonService.getVerb<SignAdobeDocuments>(url);
  }

  getExecutives(): Observable<Array<executivesModel>> {
    const url = `${environment.apiPath}${ENDPOINTS.executives}`;
    return this._commonService.getVerb(url);
  }

  // processPdf(): Observable<any> {
  //   return this.postGeneratePdf().pipe(
  //     switchMap(() => {
  //       return this.postSignAdobeDocuments().pipe(
  //         switchMap((data) => {
  //           return this.getSignAdobeDocuments(data.agreementId);
  //         })
  //       );
  //     })
  //   );
  // }
  // processPdf(): Observable<any> {
  //   return this.postSignAdobeDocuments().pipe(
  //     switchMap((data) => {
  //       return this.getSignAdobeDocuments(data.agreementId);
  //     })
  //   );
  // }
}
