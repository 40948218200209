import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HandleErrorService } from './handle-error.service';

import { Observable, of, throwError } from 'rxjs';
import {
  delay,
  mergeMap,
  retryWhen,
  timeout,
  tap,
  catchError
} from 'rxjs/operators';
import { isUrlException } from '../utils/url-exception';
import { AuthService } from './auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private accessToken: string;
  constructor(private injector: Injector, private authService: AuthService) {}

  /**
   * Set auth token headers for each request that need it
   * @param request
   * @param next
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const options: any = {};
    this.authService.accessToken$.subscribe(
      (token) => (this.accessToken = token)
    );
    const handleError = this.injector.get(HandleErrorService);

    const customContentType = request?.headers?.get('Content-type');
    if (!customContentType) {
      options.headers = request.headers.set(
        'Content-Type',
        customContentType ? customContentType : 'application/json'
      );
    }

    if (this.accessToken) {
      options.headers = request.headers.set(
        'Authorization',
        `Bearer ${this.accessToken}`
      );
    }

    return next.handle(request.clone(options)).pipe(
      timeout(environment.timeout),
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          handleError.manage200Error(ev);
        }
      }),
      this.retryWithBackOff(request.url),
      catchError((response: any) => {
        if (typeof response.error === 'string') {
          try {
            response.error = JSON.parse(response?.error);
          } catch (e) {}
        }
        if (response instanceof HttpErrorResponse) {
          response['body'] = request.body;
          handleError.manageHttpError(response);
          return throwError(response.error);
        } else {
          handleError.goToPageError();
        }
        return throwError(response);
      })
    );
  }

  private retryWithBackOff(
    url: string,
    delayMs = 1000,
    maxRetry = 1,
    backOffMs = 1000
  ) {
    let retries = maxRetry;
    return (src: Observable<any>) =>
      src.pipe(
        retryWhen((error: Observable<any>) =>
          error.pipe(
            mergeMap((error) => {
              if (
                retries-- > 0 &&
                error.status !== 401 &&
                error.status !== 409 &&
                !isUrlException(url)
              ) {
                const backOffTime = delayMs + (maxRetry - retries) * backOffMs;
                return of(error).pipe(delay(backOffTime));
              }
              return throwError(error);
            })
          )
        )
      );
  }
}
