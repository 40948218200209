import { Injectable } from '@angular/core';
import { USER_DATA_CASE } from './../../../shared/constants';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CaseDataService {
  private strOppId: string;
  private email: string;
  private optOpportunyId: string;

  setStrOppId(strOppId: string) {
    if (!!strOppId) this.strOppId = strOppId;
  }

  getStrOppId() {
    if (environment.testMode) {
      this.strOppId = this.strOppId
        ? this.strOppId
        : sessionStorage.getItem(USER_DATA_CASE);
    }
    return this.strOppId;
  }

  setEmail(email: string) {
    this.email = email;
  }

  getEmail() {
    return this.email;
  }

  setOptOpportunyId(optOpportunyId: string) {
    this.optOpportunyId = optOpportunyId;
  }

  getoptOpportunyId(): string {
    return this.optOpportunyId;
  }
}
