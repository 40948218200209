import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tag-bullets',
  templateUrl: './tag-bullets.component.html',
  styleUrls: ['./tag-bullets.component.scss']
})
export class TagBulletsComponent implements OnInit {
  @Input() label?: string;
  @Input() message?;
  @Input() fontSize?: string;
  @Input() fontFamily?: string;
  @Input() isPrivacyPolicy?: boolean;
  @Input() privacyPolicy?: string;
  @Output() onboardingEvent = new EventEmitter<string>();
  public isActive = false;
  constructor() {}

  ngOnInit(): void {}

  public showTooltip() {
    setTimeout(() => {
      this.isActive = true;
    }, 300);
  }

  public hideTooltip() {
    setTimeout(() => {
      this.isActive = false;
    }, 500);
  }

  emitEvent() {
    this.onboardingEvent.emit('blnCheckDataPrivacyPolicy');
  }
}
