import { AbstractControl, FormControl } from '@angular/forms';

import { of } from 'rxjs';

import { UserValidationUtilES } from './es-user-validation.util';
import { ES_MOBILE_PHONE_REGEXP } from '../constants';

import * as moment from 'moment';

export class CustomValidators {
  /**
   * Method that check if user dni/nie is valid
   * @param formControl
   */
  public static validateDocument(currentLang: any) {
    return (formControl: FormControl) => {
      const userFormControl = formControl.value;
      let isValid = false;
      switch (currentLang) {
        case 'DNI':
          isValid = UserValidationUtilES.validateDNI(userFormControl);
          break;
        case 'NIE':
          isValid = UserValidationUtilES.validateNIE(userFormControl);
          break;
      }
      return of(
        isValid
          ? null
          : {
              validateDocument: true
            }
      );
    };
  }
}

/**
 * comprobar que el email de ambos titulares es diferente
 */
export function haveSameEmail(email: string) {
  return (control: FormControl): { [key: string]: any } => {
    return email?.toLowerCase() === control?.value?.toLowerCase()
      ? { sameEmail: true }
      : null;
  };
}
/**
 * comprobar que el telefono de ambos titulares es diferente
 */
export function haveSamePhone(phone: string) {
  return (group: FormControl): { [key: string]: any } => {
    const secondoHolderEmail = group.value?.number;
    return phone === secondoHolderEmail ? { samePhone: true } : null;
  };
}

/**
 * comprobar que el telefono de ambos titulares es diferente
 */
export function validatorPatternPhone() {
  return (group: FormControl): { [key: string]: any } => {
    const phone = group.value?.number;
    return phone
      ? !new RegExp(ES_MOBILE_PHONE_REGEXP).test(phone)
        ? { pattern: true }
        : null
      : null;
  };
}

export function haveSameValue(value: string) {
  return (control: FormControl): { [key: string]: any } => {
    return value?.toLowerCase() === control?.value?.toLowerCase()
      ? { sameValue: true }
      : null;
  };
}

export function validateDate(
  minDate: Date,
  maxDate: Date,
  exceptionalDates: Array<Date> = []
): any {
  return (formControl: FormControl) => {
    let isValid = true;
    const date = moment(formControl.value, 'YYYY-MM-DD');

    if (
      (formControl.value && !date.isValid()) ||
      date.isBefore(minDate) ||
      date.isAfter(maxDate)
    ) {
      isValid = false;
    } else {
      const findDate = exceptionalDates.find((e) => date.isSame(e));
      if (findDate) {
        return of({
          exceptionalDate: true
        });
      }
    }
    return of(
      isValid
        ? null
        : {
            invalidDate: true
          }
    );
  };
}
export function numericValidator(): any {
  return (formControl: FormControl): { [key: string]: any } => {
    const value = formControl.value;
    if (!value) {
      return null; // No validation error if the field is empty
    }
    const numericRegex = /^[0-9]*$/; // Regular expression to match only numeric values

    return numericRegex.test(value) ? null : { numeric: true };
  };
}
