import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private showHeader$ = new BehaviorSubject<boolean>(true);

  constructor() {}

  setShowHeader(value: boolean) {
    this.showHeader$.next(value);
  }

  showHeader() {
    return this.showHeader$;
  }
}
