import { Observable } from 'rxjs';
import { Component, Type, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from './vass-core/components/modal/confirmation.service';
import { ModalDataType } from './vass-core/components/modal/modal-data-type.interface';
import { ErrorHandler } from './shared/model/error-handler.model';
import { HandleErrorService } from './shared/services/handle-error.service';
import { ErrorModalMessageComponent } from './shared/components/modals/error-modal-message/error-modal-message.component';
import { environment } from '../environments/environment';
import { HeaderService } from './shared/services/header/header.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AuthService } from './shared/services/auth/auth.service';

declare const dataLayer: any[];
declare const require: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private errHndlr: ErrorHandler;

  version = require('../../package.json').version;
  showVersion = false;

  readonly showHeader$: Observable<boolean>;

  constructor(
    private router: Router,
    private translSrv: TranslateService,
    private handleErrorService: HandleErrorService,
    protected confirmationService: ConfirmationService,
    private headerService: HeaderService,
    private gtmService: GoogleTagManagerService,
    private authService: AuthService
  ) {
    this.gtmService.addGtmToDom();
    this.translSrv.setDefaultLang('es');
    this.translSrv.use('es');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const pageType = this.convertUrl(event.url);

        if (pageType) {
          this.gtmService.pushTag({ pageType }).then(() => {
            this.gtmService.pushTag({ event: 'pageView', pageName: event.url });
          });
        }
      }
    });

    this.showHeader$ = this.headerService.showHeader();
  }

  ngOnInit() {
    this.authService.checkAccessTokenInSession();
    this.handleErrorService
      .getNotificationErrorMessage()
      .subscribe((errHandler) => {
        console.log(errHandler);
        this.errHndlr = errHandler;
        this.openModal();
      });

    this.showCurrentVersion();
  }

  private openModal() {
    this.confirmationService.confirm(<ModalDataType>{
      component: ErrorModalMessageComponent,
      data: {
        message: this.errHndlr.message,
        errorCode: this.errHndlr.errorCode,
        btnText: this.errHndlr.btnText
      }
    });
  }

  private showCurrentVersion() {
    if (environment.showVersion) {
      this.showVersion = environment.showVersion;
      console.log(`Version: ${this.version}`);
    }
  }

  convertUrl(url: string) {
    switch (url) {
      case '/onboarding/welcome':
        return 'bienvenida';
      case '/onboarding/initialData':
        return 'Datos Iniciales';
      case '/onboarding/accountOwner':
        return 'Numero titulares';
      case '/onboarding/contactData':
        return 'Datos de contacto';
      case '/onboarding/documentType':
        return 'Tipo de documento';
      case '/onboarding/video-identificacion':
        return 'Video de identificacion';
      case '/onboarding/personalData1':
        return 'Datos Personales 1';
      case '/onboarding/personalData2':
        return 'Datos Personales 2';
      case '/onboarding/addressData':
        return 'Direccion';
      case '/onboarding/activity':
        return 'Actividad Economica';
      case '/onboarding/signContract':
        return 'Firma de contrato';
      case '/onboarding/viewContract':
        return 'Revisión de contrato';
      case '/onboarding/password':
        return 'Contraseña';
      case '/onboarding/completed':
        return 'Registro completado';
      case '/onboarding/bloqueado':
        return 'bloqueado';
      default:
        return '';
    }
  }
}
