import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { PersonalInfoModel } from '../model/params.model';

@Injectable()
export class PersonalDataService {
  private personalData$ = new BehaviorSubject<PersonalInfoModel>({
    firstName: '',
    middleName: '',
    lastName: '',
    secondLastName: '',
    birthDate: '',
    cityOfResidence: '',
    countryOfResidence: '',
    residentialAddress: '',
    countryOfBirth: '',
    nationality: '',
    passportNumber: '',
    expiryDate: '',
    countryOfExpedition: '',
    documentType: '',
    documentNumber: '',
    taxDocumentNumber: '',
    step: '',
    gender: '',
    maritalStatus: '',
    oriDesTable: [],
    createBeneficiaryTable: [],
    requiresBen: null
  });

  setPersonalData(value: PersonalInfoModel) {
    this.personalData$.next(value);
  }

  getPersonalData(): Observable<PersonalInfoModel> {
    return this.personalData$.asObservable();
  }
}
