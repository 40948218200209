import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDate]'
})
export class DateDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onEvent() {
    const valueToTransform = this.el.nativeElement.value;
    this.control.control?.setValue(valueToTransform);
  }
}
