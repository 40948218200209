import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import {
  delay,
  finalize,
  map,
  mergeMap,
  retryWhen,
  take
} from 'rxjs/operators';

import { OLIMPIA_EVENTS, PATHS } from './../../..//shared/constants';

import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { CommonDataService } from './../../../shared/services/common-data.service';
import { GtmService } from './../../../shared/services/gtm/gtm.service';
import { OlimpiaService } from './../../../shared/services/olimpia/olimpia.service';
import { ConfirmationService } from './../../../vass-core/components/modal/confirmation.service';

import { PichBaseComponent } from '../pichincha-base.component';
import { ErrorModalMessageComponent } from './../../../shared/components/modals/error-modal-message/error-modal-message.component';

import { VideoIdentificationResponse } from './../../../shared/model/api/video-identification.model';
import { ModalDataType } from './../../../vass-core/components/modal/modal-data-type.interface';

import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-video-identification',
  templateUrl: './video-identification.component.html',
  styleUrls: ['./video-identification.component.scss']
})
export class VideoIdentificationComponent
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  public prevStage = PATHS.documentType;
  public nextStage = PATHS.personalData1;

  showVideo = false;
  startVideoIdResponse: VideoIdentificationResponse;

  verifyPending = false;

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private commonDataService: CommonDataService,
    private readonly olimpiaService: OlimpiaService,
    private render: Renderer2,
    protected confirmationService: ConfirmationService,
    private gtmService: GtmService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.isSecondHolder = this.commonDataService.isSecondHolder;
    this.isMultTitulares = this.commonDataService.isMultTitulares;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    window.removeEventListener('message', () => {}, true);
  }

  private startVideoIdentification(): Observable<VideoIdentificationResponse> {
    return this.olimpiaService.startVideoIdentification({
      identificationNumber:
        this.userData.clsResponseSavePersonalData.strDocumentNumber,
      documentType:
        this.userData.clsResponseSavePersonalData.strDocumentTypeCode,
      email: this.userData.clsResponseSavePersonalData.strEmail,
      phone: this.userData.clsResponseSavePersonalData.strPhone,
      holder: this.userData.holder,
      opportunityId: this.pichCService.getStrOppId()
    });
  }

  onStart() {
    if (this.startVideoIdResponse) {
      this.showVideo = true;
      this.createIframe();

      window.addEventListener(
        'message',
        this.listenerOlimpiaEvents.bind(this),
        false
      );
    }
  }

  private createIframe() {
    const iframe = this.render.createElement('iframe');
    const content = document.getElementById('iframe-content');

    // eliminar si existe iframe
    if (document.getElementById('iframe')) {
      content.removeChild(document.getElementById('iframe'));
    }

    this.render.setAttribute(iframe, 'id', 'iframe');
    this.render.setAttribute(iframe, 'src', this.startVideoIdResponse.url);
    this.render.setAttribute(iframe, 'title', 'video identificacion');
    this.render.setAttribute(iframe, 'allow', 'geolocation *; camera *;');
    this.render.setAttribute(
      iframe,
      'style',
      'border-style: none; width: 100%; height: 90vh'
    );

    this.render.appendChild(content, iframe);
  }

  private listenerOlimpiaEvents(event) {
    // Se filtra para aceptar únicamente los mensajes provenientes el validador web
    if (event.origin !== environment.olimpiaNotificationsUrl) {
      return;
    }

    if (event?.data?.for === OLIMPIA_EVENTS.COMPLETED) {
      this.verifyVideoIdentification();
    } else if (event?.data?.for === OLIMPIA_EVENTS.TIME_OUT) {
      this.routerC.navigate(['onboarding', PATHS.locked]);
    }
  }

  private verifyVideoIdentification(): void {
    if (!this.verifyPending) {
      this.verifyPending = true;
      this.subscription.add(
        this.olimpiaService
          .verifyVideoIdentification(this.getBodyVerification())
          .pipe(
            finalize(() => {
              this.verifyPending = false;
            }),
            map((data) => {
              if (!data.processCompleted && !data.error) {
                throw new Error('work in progress');
              }
              return data;
            }),
            retryWhen((error: Observable<any>) =>
              error.pipe(
                mergeMap((error) => {
                  return error.status === 400 || error.status === 404
                    ? throwError(error)
                    : of(error);
                }),
                take(10),
                delay(5000)
              )
            )
          )
          .subscribe((data) => {
            this.showVideo = false;
            this.startVideoIdResponse = null;
            if (!data.error) {
              this.sendGTM();
              if (this.isMultTitulares) {
                if (this.commonDataService.isSecondHolder) {
                  this.commonDataService.setIsSecondHolder(false);
                } else {
                  this.commonDataService.setIsSecondHolder(true);
                  return this.ngOnInit();
                }
              }
              this.routerC.navigate(['onboarding', this.nextStage]);
            } else {
              this.showModalErrorVideoIdentification();
            }
          }, this.handlerErrorVerification.bind(this))
      );
    }
  }

  private handlerErrorVerification(error) {
    this.showVideo = false;
    this.startVideoIdResponse = null;
    window.removeEventListener('message', () => {}, true);
    if (error.status === 404) {
      this.showModalErrorVideoIdentification();
    } else {
      return this.routerC.navigate(['onboarding', PATHS.locked]);
    }
  }

  private getBodyVerification() {
    return {
      citizenGuid: this.startVideoIdResponse.citizenGuid,
      agreemenProcessGuid: this.startVideoIdResponse.agreemenProcessGuid,
      holder: this.userData?.holder,
      opportunityId: this.pichCService.getStrOppId(),
      documentType:
        this.userData.clsResponseSavePersonalData.strDocumentTypeCode,
      email: this.userData.clsResponseSavePersonalData.strEmail,
      phone: this.userData.clsResponseSavePersonalData.strPhone,
      identificationNumber:
        this.userData.clsResponseSavePersonalData.strDocumentNumber
    };
  }

  private showModalErrorVideoIdentification() {
    console.log('error');
    this.confirmationService.confirm(<ModalDataType>{
      component: ErrorModalMessageComponent,
      data: {
        message: 'error_ONB2_005',
        btnText: 'error_ONB2_005_btn'
      },
      accept: this.retryVideoVerification.bind(this)
    });
  }

  private retryVideoVerification() {
    this.startVideoIdentification().subscribe((data) => {
      this.startVideoIdResponse = data;
      this.onStart();
    });
  }

  private sendGTM() {
    this.gtmService.sendVariableGTM({
      pageType: 'Video de identificacion'
    });
    this.gtmService.trackEvent(
      'onboarding',
      'paso 5',
      'Video de identificacion'
    );
  }

  onBackStage(prevStage: string) {
    if (this.isMultTitulares) {
      if (this.isSecondHolder) {
        this.commonDataService.setIsSecondHolder(false);
        this.ngOnInit();
        return;
      } else {
        this.commonDataService.setIsSecondHolder(true);
      }
    }
    this.routerC.navigate(['onboarding', prevStage]);
  }
}
