import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pich-stages',
  templateUrl: './pich-stages.component.html',
  styleUrls: ['./pich-stages.component.scss']
})
export class PichStagesComponent implements OnInit, OnChanges {
  progress: any = {
    accountOwner: '5%',
    initialData: '15%',
    contactData: '20%',
    identityManualConfirm: '30%',
    personalData1: '40%',
    personalData2: '50%',
    activity: '60%',
    activity2: '70%',
    activity3: '80%',
    activity4: '80%',
    signContract: '80%',
    adobeSignView: '90%',
    //viewContract: '90%',
    // contractUpload: '95%',
    finalStep: '100%'
  };
  progressReview = {
    documentReview: '50%',
    finalStep: '100%'
  };
  isDocumentReview: boolean;

  progressArray: string[] = [];
  currentPage: number;
  totalPages: number;

  @Output() onBackStage: EventEmitter<string> = new EventEmitter();

  @Input() canBack = false;

  @Input() isPanama = false;

  pathUrl: string;

  constructor(private route: Router) {}

  ngOnInit() {
    // Coger el path activo

    this.pathUrl = this.route.url.split('/')[2];
    if (this.isPanama) this.addPanamaOption();
    this.countPages();
  }

  addPanamaOption() {
    this.progress = {
      accountOwner: '5%',
      initialData: '15%',
      contactData: '20%',
      identityManualConfirm: '30%',
      personalData1: '40%',
      personalData2: '40%',
      personalDataOrigenDestiny: '50%',
      activity: '60%',
      activity2: '70%',
      activity3: '80%',
      activity4: '80%',
      signContract: '80%',
      adobeSignView: '90%',
      //viewContract: '90%',
      // contractUpload: '95%',
      finalStep: '100%'
    };
  }

  ngOnChanges() {
    if (this.isPanama){
     this.addPanamaOption();
      this.countPages();
    } else {
      this.unPanamaOption();
      this.countPages();
    }

  }

  countPages() {
    this.progressArray = Object.keys(
      this.pathUrl === 'documentReview' ? this.progressReview : this.progress
    );

    this.currentPage = this.progressArray.indexOf(this.pathUrl) + 1;

    if (this.pathUrl === 'documentReview') {
      this.isDocumentReview = true;
    }
  }

  backStage() {
    this.onBackStage.emit();
  }

  unPanamaOption(): void {
    this.progress = {
      accountOwner: '5%',
      initialData: '15%',
      contactData: '20%',
      identityManualConfirm: '30%',
      personalData1: '40%',
      personalData2: '40%',
      activity: '60%',
      activity2: '70%',
      activity3: '80%',
      activity4: '80%',
      signContract: '80%',
      adobeSignView: '90%',
      //viewContract: '90%',
      // contractUpload: '95%',
      finalStep: '100%'
    };
  }
}
