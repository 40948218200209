import { NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient
} from '@angular/common/http';

import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateModule,
  TranslateLoader,
  TranslateParser,
  TranslateDefaultParser
} from '@ngx-translate/core';

import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';

import { HttpService } from './services/http.service';
import { CommonService } from './services/common.service';
import { ModalService } from './components/modal/modal.service';
import { LoaderService } from './components/loader/loader.service';
import { ConfirmationService } from './components/modal/confirmation.service';

import { ModalComponent } from './components/modal/modal.component';
import { LoaderComponent } from './components/loader/loader.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  imports: [
    HttpClientModule,
    RouterModule,
    NoopAnimationsModule,
    DialogModule,
    ToastModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      parser: {
        provide: TranslateParser,
        useClass: TranslateDefaultParser
      }
    })
  ],
  exports: [LoaderComponent, ModalComponent, TranslateModule],
  entryComponents: [],
  declarations: [LoaderComponent, ModalComponent],
  providers: [
    HttpClient,
    CommonService,
    LoaderService,
    ModalService,
    ConfirmationService,
    DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpService, multi: true }
  ]
})
export class VassCoreModule {}
