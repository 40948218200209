import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sibank-404-page',
  templateUrl: './sibank-404-page.component.html',
  styleUrls: ['./sibank-404-page.component.scss']
})
export class SiBank404PageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  goToLogin() {
    this.router.navigate(['/']);
  }
}
