import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appShowPassword]'
})
export class ShowPasswordDirective {
  @Input() input: HTMLInputElement;

  constructor(private element: ElementRef) {
    this.addListeners();
  }

  private addListeners() {
    this.element.nativeElement.addEventListener(
      'mousedown',
      this.showPassword.bind(this)
    );
    this.element.nativeElement.addEventListener(
      'mouseup',
      this.hiddenPassword.bind(this)
    );
    this.element.nativeElement.addEventListener(
      'mouseout',
      this.hiddenPassword.bind(this)
    );
    this.element.nativeElement.addEventListener(
      'touchstart',
      this.showPassword.bind(this)
    );
    this.element.nativeElement.addEventListener(
      'touchend',
      this.hiddenPassword.bind(this)
    );
  }

  private showPassword() {
    if (this.input) {
      this.input.type = 'text';
    }
  }

  private hiddenPassword() {
    if (this.input) {
      this.input.type = 'password';
    }
  }
}
