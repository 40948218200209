import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { PichBaseComponent } from '../pichincha-base.component';

import { ParamsModel } from '../../../shared/model/api/params.model';
import { EconomicActivityModel } from '../../../shared/model/economic-activity.model';
import { KYCData } from '../../model/params.model';

import { ACTION, CASE_STAGES, PATHS } from '../../../shared/constants';

import { CommonDataService } from '../../../shared/services/common-data.service';
import { GtmService } from '../../../shared/services/gtm/gtm.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { OnboardingService } from '../../services/onboarding/onboarding.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';

import { MiddlewareService } from '../../../shared/services/middleware.service';
import { filterList } from '../../../shared/utils/filter-list';
import { KYCDataService } from '../../services/kyc.data.service';

@Component({
  selector: 'app-pich-activity-3',
  templateUrl: './pich-activity-3.component.html',
  styleUrls: ['./pich-activity-3.component.scss']
})
export class PichActivity3Component
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  formActivity: FormGroup;
  accountPurpose$: Observable<Array<ParamsModel>>;
  accountPurposeList: ParamsModel[] = [];
  accountPurposeId: string;
  setAccountPurpose: string;

  listSectorProfessional: Array<EconomicActivityModel> = [];
  filteredSectorProfessional = [];

  prevStage = PATHS.activity2;
  nextStage = PATHS.activity4;
  caseStage = CASE_STAGES.kyc;

  isPanama = false;
  noShow: boolean;
  defaultDropDownValue: string = 'Ahorro';

  countries: Array<{ value: string; label: string }>;

  readonly MAX_LENGTH = 16;
  readonly MAX_LENGTH_TEXT_AREA = 255;
  MAX_LENGTH_DECIMAL = 2;

  HTML_VALIDATOR = {
    minLength: 2,
    maxLength: {
      incomingTransfersMonth: 999,
      balanceIncomingTransfersMonth: 9999999999999999,
      numberOutgoingTransfersMonth: 6,
      balanceOutgoingTransfersMonth: 9999999999999999,
      expectedInitialIncomeAmount: 9999999999999999
    }
  };

  kycData: KYCData;
  agreementId: string = '';
  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    protected confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private commonDataService: CommonDataService,
    private readonly onboardingService: OnboardingService,
    private gtmService: GtmService,
    private middlewareService: MiddlewareService,
    private kycDataService: KYCDataService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  get transfersInOtherCountries() {
    return this.formActivity.get('checkIntTransfer');
  }

  ngOnInit() {
    this.loadData();
    this.loadConfigSelects();
    this.subscription = this.onboardingService.getAccountPurposes().subscribe(
      (data) => {
        this.accountPurposeList = data;
        this.initForm();
      },
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadConfigSelects() {
    this.accountPurpose$ = this.onboardingService
      .getAccountPurposes()
      .pipe(retry(5));

    this.subscription.add(
      this.onboardingService
        .getProfessionalSector()
        .pipe(
          map((list) => {
            return list.map((e) => {
              return {
                name: e.name,
                code: e.code,
                label: `${e.code} - ${e.name}`
              };
            });
          })
        )
        .subscribe(
          (data) => {
            this.listSectorProfessional = data;
          },
          (error) => {
            this.setErrorMessages(error.responseCode);
          }
        )
    );
  }

  initForm() {
    this.formActivity = this.formBuilder.group({
      accountPurpose: [null, Validators.required],
      /* incomingTransfersMonth: [
        '',
        [
          Validators.required,
          Validators.max(this.HTML_VALIDATOR.maxLength.incomingTransfersMonth)
        ]
      ],
      balanceIncomingTransfersMonth: [
        '',
        [
          Validators.required,
          Validators.max(
            this.HTML_VALIDATOR.maxLength.balanceIncomingTransfersMonth
          ),
          Validators.pattern(/^\d+(\.\d{1,2})?$/)
        ]
      ],
      numberOutgoingTransfersMonth: [
        '',
        [
          Validators.required,
          Validators.max(
            this.HTML_VALIDATOR.maxLength.numberOutgoingTransfersMonth
          )
        ]
      ],
      balanceOutgoingTransfersMonth: [
        '',
        [
          Validators.required,
          Validators.max(
            this.HTML_VALIDATOR.maxLength.balanceOutgoingTransfersMonth
          ),
          Validators.pattern(/^\d+(\.\d{1,2})?$/)
        ]
      ], */
      expectedInitialIncomeAmount: [
        '',
        [
          Validators.required,
          Validators.max(
            this.HTML_VALIDATOR.maxLength.expectedInitialIncomeAmount
          ),
          Validators.pattern(/^\d+(\.\d{1,2})?$/)
        ]
      ],
      accountDetail: [
        '',
        [Validators.required, Validators.maxLength(this.MAX_LENGTH_TEXT_AREA)]
      ]
    });
  }

  /* get incomingTransfersMonth() {
    return this.formActivity.get('incomingTransfersMonth');
  }
  get balanceIncomingTransfersMonth() {
    return this.formActivity.get('balanceIncomingTransfersMonth');
  }
  get numberOutgoingTransfersMonth() {
    return this.formActivity.get('numberOutgoingTransfersMonth');
  }
  get balanceOutgoingTransfersMonth() {
    return this.formActivity.get('balanceOutgoingTransfersMonth');
  } */
  get expectedInitialIncomeAmount() {
    return this.formActivity.get('expectedInitialIncomeAmount');
  }

  get accountDetail() {
    return this.formActivity.get('accountDetail');
  }

  filterSectorProfessional(event) {
    this.filteredSectorProfessional = filterList(
      this.listSectorProfessional,
      event,
      'label'
    );
  }

  getAction() {
    return ACTION.saveKYC;
  }

  // getAccountPurposeId(accountPurpose: string): string {
  //   const accountPurposeId = this.accountPurposeList.find(
  //     (item) => item.name === accountPurpose
  //   );
  //   return accountPurposeId ? accountPurposeId.id.toString() : '';
  // }

  // setAccountPurposeId(id: string): string {
  //   switch (id) {
  //     case '1':
  //       this.setAccountPurpose = 'Gestión del día a día';
  //       break;
  //     case '2':
  //       this.setAccountPurpose = 'Ahorro';
  //       break;
  //     case '3':
  //       this.setAccountPurpose = 'Obtener financiación';
  //       break;
  //     case '4':
  //       this.setAccountPurpose = 'Inversión';
  //       break;
  //   }
  //   return this.setAccountPurpose;
  // }

  // getBodyPost() {
  //   const form = this.formActivity.value;
  //   const activityParams: KycDataScreen = {
  //     holder: this.isSecondHolder ? HOLDER.SECONDARY : HOLDER.PRIMARY,
  //     strOppId: this.pichCService.getStrOppId(),
  //     strCurrentPosition: form.currentPosition?.name,
  //     strCurrentPositionCode: form.currentPosition?.id,
  //     strOccupation: form.employSituation?.name,
  //     strOccupationCode: form.employSituation?.id,
  //     strProfessionalSectorCode: form.professionalSector
  //       ? form.professionalSector?.code
  //       : '',
  //     strProfessionalSector: form.professionalSector
  //       ? form.professionalSector?.name
  //       : '',
  //     strActivityTypeCode: form.activityType ? form.activityType?.code : '',
  //     strActivityType: form.activityType ? form.activityType?.name : '',
  //     strAccountPurpose: form.accountPurpose,
  //     numAssets: form.numAssets,
  //     numLiabilities: form.numLiabilities,
  //     numMonthlyIncome: form.numMonthlyIncome,
  //     numMonthlyExpenses: form.numMonthlyExpenses,
  //     numOtherIncome: form.numOtherIncome ? form.numOtherIncome : null,
  //     strOtherIncomeDetails: form.strOtherIncomeDetails,
  //     blnHasAcceptFundsStatement: form.blnHasAcceptFundsStatement
  //   };
  //   return new BaseData(this.getAction(), JSON.stringify(activityParams));
  // }

  loadData() {
    this.middlewareService.getUsersLookup().subscribe((data) => {
      this.kycData = data;
      this.formActivity.patchValue({
        accountPurpose: this.kycData.accountPurpose
          ? this.kycData.accountPurpose
          : 'Ahorro',
        /* incomingTransfersMonth: '',
        balanceIncomingTransfersMonth: data.balanceIncomingTransfersMonth,
        numberOutgoingTransfersMonth: data.outcomingTransfersMonth,
        balanceOutgoingTransfersMonth: data.balanceOutcomingTransfersMonth, */
        expectedInitialIncomeAmount: data.expectedInitialDeposit,
        accountDetail: data.accountDetail
      });

      if (
        data?.targetCountry !== undefined &&
        data?.targetCountry === 'L_PANBM'
      )
        this.isPanama = true;
      this.noShow = false;
    });
  }

  sendForm() {
    if (this.formActivity.valid) {
      this.onNextStage(this.nextStage);
    } else {
      this.formActivity.markAllAsTouched();
    }
  }

  private getKYCData(): KYCData {
    const form = this.formActivity.value;

    return {
      occupation: this.kycData.occupation,
      position: this.kycData.position,
      companyJob: this.kycData.companyJob,
      economicActivity: this.kycData.economicActivity,
      companyYears: this.kycData.companyYears,
      officeAddress: this.kycData.officeAddress,
      annualRevenue: this.kycData.annualRevenue,
      mainSourceRevenue: this.kycData.mainSourceRevenue,
      annualSalary: this.kycData.annualSalary,
      annualRent: this.kycData.annualRent,
      annualInvestments: this.kycData.annualInvestments,
      otherAnnualRevenue: this.kycData.otherAnnualRevenue,
      detailOtherRevenue: this.kycData.detailOtherRevenue,
      detailAssets: this.kycData.detailAssets,
      accountPurpose: form.accountPurpose,
      incomingTransfersMonth: 0,
      balanceIncomingTransfersMonth: 0,
      outcomingTransfersMonth: 0,
      balanceOutcomingTransfersMonth: 0,
      expectedInitialDeposit: form.expectedInitialIncomeAmount,
      accountDetail: form.accountDetail,
      sourceWealth: this.kycData.sourceWealth,
      startDate: this.kycData.startDate,
      typeOfDeposit: this.kycData.typeOfDeposit,
      amountLimit: this.kycData.amountLimit,
      taxResidence: this.kycData.taxResidence,
      anotherReceptionReason: this.kycData.anotherReceptionReason,
      purposeSendTrans: this.kycData.purposeSendTrans,
      receptionReason: this.kycData.receptionReason,
      anotherPurposeSendTrans: this.kycData.anotherPurposeSendTrans,
      transfAchTxCred: this.kycData.transfAchTxCred,
      transfAchAmountCred: this.kycData.transfAchAmountCred,
      transfAchTxDeb: this.kycData.transfAchTxDeb,
      transfAchAmountDeb: this.kycData.transfAchAmountDeb,
      transfInterTxCred: this.kycData.transfInterTxCred,
      transfInterAmountCred: this.kycData.transfInterAmountCred,
      transfInterTxDeb: this.kycData.transfInterTxDeb,
      transfInterAmountDeb: this.kycData.transfInterAmountDeb,
      transfBppTxCred: this.kycData.transfBppTxCred,
      transfBppAmountCred: this.kycData.transfBppAmountCred,
      transfBppTxDeb: this.kycData.transfBppTxDeb,
      transfBppAmountDeb: this.kycData.transfBppTxDeb,
      totalTxCred: this.kycData.totalTxCred,
      totalAmountCred: this.kycData.totalAmountCred,
      totalTxDeb: this.kycData.totalTxDeb,
      totalAmountDeb: this.kycData.totalAmountDeb,
      step: '3',
      profession: this.kycData.profession
    };
  }

  onNextStage(stage: string) {
    this.middlewareService.submitKYCData(this.getKYCData()).subscribe(
      (data) => {
        this.routerC.navigate(['onboarding', stage]);
      },
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  onBackStage(prevStage: string) {
    if (this.isMultTitulares) {
      if (this.isSecondHolder) {
        this.commonDataService.setIsSecondHolder(false);
        this.ngOnInit();
      } else {
        this.commonDataService.setIsSecondHolder(true);
        this.routerC.navigate(['onboarding', prevStage]);
      }
    } else {
      this.routerC.navigate(['onboarding', prevStage]);
    }
  }

  private sendGTM() {
    const form = this.formActivity.value;
    this.gtmService.sendVariableGTM({
      pageType: 'Actividad Economica',
      ocupacion: form.currentPosition?.name,
      cargoActual: form.employSituation?.name,
      propositoCuenta: form.accountPurpose,
      activos: form.numAssets,
      pasivos: form.numLiabilities,
      ingresosMensuales: form.numMonthlyIncome,
      gastosMensuales: form.numMonthlyExpenses,
      otrosIngresos: form.numOtherIncome
    });

    this.gtmService.trackEvent('onboarding', 'paso 9', 'Actividad Economica');
  }
}
