import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const TRANSLATION_OBJECT_KEY = `TRANSLATION_OBJECT_`;

export class CustomTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  getTranslation(lang: string): Observable<any> {
    const remoteTranslation$ = this.getRemoteTranslation(lang);
    const cachedTranslation$ = this.getCachedTranslation(lang);
    const fileTranslation$ = this.getFileTranslation(lang);
    return forkJoin([
      fileTranslation$
      // cachedTranslation$,
      // remoteTranslation$,
    ]).pipe(
      map(([file]) => ({ ...file })),
      catchError(() => of({}))
    );
  }

  private getRemoteTranslation(lang: string, timestamp?: string | number) {
    const url = `${environment.apiPath}/i18n/${environment.version.i18n}/translations/${lang}`;

    let params = new HttpParams();
    params = params.append('channel', 'web');
    params = params.append('project', 'onb');

    return this.http.get<any>(url, { params }).pipe(
      map((translation) => {
        if (Object.keys(translation).length === 0) {
          throw new Error('empty object');
        }

        this.localStorageService.store(
          `${TRANSLATION_OBJECT_KEY}${lang}`,
          translation
        );

        return translation;
      }),
      catchError(() => of({}))
    );
  }

  private getFileTranslation(lang: string) {
    const url = `./assets/i18n/strings-${lang}.json?d=${new Date().getTime()}`;

    return this.http.get<any>(url).pipe(
      map((result) => result.translations),
      catchError(() => of({}))
    );
  }

  private getCachedTranslation(lang: string) {
    return of(
      this.localStorageService.retrieve(`${TRANSLATION_OBJECT_KEY}${lang}`)
    ).pipe(
      map((result) => {
        if (!result) {
          throw new Error('cant retrieve cached data');
        }

        return result;
      }),
      catchError(() => of({}))
    );
  }
}
