import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { Subject, Observable, throwError } from 'rxjs';

import { ERROR, OTP_ERROR, PATHS } from '../constants';
import { ErrorHandler } from '../model/error-handler.model';
import { environment } from '../../../environments/environment';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from '../../vass-core/components/modal/confirmation.service';
import { HttpResponse } from '@angular/common/http';
import { isUrlException } from '../utils/url-exception';

declare let dataLayer: any;

@Injectable()
export class HandleErrorService {
  private modalType = '';
  private floatingType = '';
  errorHndlr: ErrorHandler;

  private notificationErrorMessage: Subject<ErrorHandler> = new Subject();

  readonly OTP_ERROR = 409;

  constructor(private router: Router) {}

  // TODO: VER COMO CONTROLAR LOS ERRORES, ¿MOSTRAR UNA MODAL GENÉRICA?
  manageHttpError(response: any) {
    if (isUrlException(response.url)) {
      return;
    }
    if (response.status === this.OTP_ERROR) {
      if (
        response?.error?.message === OTP_ERROR.INVALID ||
        response?.error?.message === OTP_ERROR.NUM_RETRIES_EXCEEDED
      ) {
        return;
      }
      this.errorHndlr = new ErrorHandler(
        `error_validate_otp`,
        this.modalType,
        'error_validate_otp',
        'error_sign_modal_btn'
      );
    } else if (response.error) {
      this.errorHndlr = new ErrorHandler(
        `error_${response.error.responseCode}`,
        this.modalType,
        response.error && response.error.responseCode,
        'error_sign_modal_btn'
      );
    } else {
      this.errorHndlr = new ErrorHandler(
        `onboarding_error_generic`,
        this.modalType,
        null,
        'error_sign_modal_btn'
      );
    }

    this.notificationErrorMessage.next(this.errorHndlr);
  }

  manage200Error(
    response: HttpResponse<{
      message: string;
      error: boolean;
      responseCode: string;
    }>
  ) {
    if (response?.body?.error) {
      const errorCode = response.body && response.body.responseCode;

      if (this.isRiskMatrixCode(errorCode)) {
        return this.router.navigate(['onboarding', PATHS.locked]);
      } else if (
        errorCode === ERROR.RECOVER_DATA ||
        errorCode === ERROR.RECOVER_DATA_2
      ) {
        this.router.navigate(['onboarding', PATHS.welcome]);
      } else if (errorCode === ERROR.OLIMPIA_BAD_REQUEST) {
        return;
      }

      this.errorHndlr = new ErrorHandler(
        `error_${errorCode}`,
        this.modalType,
        errorCode,
        'error_sign_modal_btn'
      );
      this.notificationErrorMessage.next(this.errorHndlr);
    }
  }

  goToPageError() {
    this.router.navigate(['onboarding', PATHS.notfound]);
  }

  private isRiskMatrixCode(errorCode) {
    const listExceptionCode = [
      ERROR.INSPECTOR_BAD_REQUEST,
      ERROR.RISK_MATRIX,
      ERROR.REGISTERED_DOCUMENT,
      ERROR.ONBOARDING_IN_PROGRESS
    ];
    return listExceptionCode.find((code) => errorCode === code);
  }

  getNotificationErrorMessage(): Observable<ErrorHandler> {
    return this.notificationErrorMessage.asObservable();
  }
}
