import { Injectable, isDevMode } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MOCK_ENDPOINTS } from './mock-endponts';

const endpoints = [...MOCK_ENDPOINTS];

@Injectable()
export class MocksInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    for (const endpoint of endpoints) {
      if (
        isDevMode() &&
        request.url.includes(endpoint.url) &&
        request.method === endpoint.method
      ) {
        console.log('******* INTERCEPTOR MOCK ************', endpoint);
        return of(
          new HttpResponse({ status: 200, body: endpoint.response as any })
        );
      }
    }
    return next.handle(request);
  }
}
