import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute
} from '@angular/router';
import { Observable } from 'rxjs';
import { CaseDataService } from '../../pichincha-onboarding/shared/services/case-data.service';
import { environment } from '../../../environments/environment';
import { PichCommonHttpService } from '../../pichincha-onboarding/services/pichincha-common-http.service';
import { PATHS, USER_DATA_CASE } from '../constants';

@Injectable()
export class ViewContractGuard implements CanActivate {
  constructor(
    private caseDataSrv: CaseDataService,
    private router: Router,
    private route: ActivatedRoute,
    private pichCommonService: PichCommonHttpService
  ) {
    /*this.route
     .queryParams
     .subscribe(params => {
     this.continueOnb = params['user'];
     });*/
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const userAux = this.pichCommonService.getDataRecover();
    if (
      environment.testMode ||
      next.queryParams.contract_id ||
      (this.caseDataSrv.getStrOppId() && sessionStorage.getItem(USER_DATA_CASE))
    ) {
      return true;
    } else {
      this.router.navigate(['onboarding', PATHS.welcome]);
      return false;
    }
  }
}
