import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CommonDataService } from '../../../shared/services/common-data.service';
import { DetectedIdService } from '../../../shared/services/detected-id/detected-id.service';
import { GtmService } from '../../../shared/services/gtm/gtm.service';
import { SignatureService } from '../../../shared/services/signature/signature.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';

import {
  ACROBAT_SIGN_ORIGIN,
  ACROBAT_SIGN_STATE,
  PATHS
} from '../../../shared/constants';
import { ApiError } from '../../model/api/api-error';

import { DomSanitizer } from '@angular/platform-browser';
import { delay, finalize, switchMap } from 'rxjs/operators';
import { MiddlewareService } from '../../../shared/services/middleware.service';
import { LoaderService } from '../../../vass-core/components/loader/loader.service';
import {
  GeneratedDocuments,
  SignAdobeDocuments
} from '../../model/params.model';
import { PichBaseComponent } from '../pichincha-base.component';

@Component({
  selector: 'app-pich-adobe-sign',
  templateUrl: './pich-adobe-sign.component.html',
  styleUrls: ['./pich-adobe-sign.component.scss']
})
export class PichAdobeSignViewComponent
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  pdf: any;
  pdfURL: any;
  pdfBlob: any;
  titlePDF: string;

  page = 1;
  rotation = 0;
  zoom = 1.0;
  renderText = true;
  isLoaded = false;
  stickToPage = false;
  showAll = true;
  autoresize = false;
  fitToPage = true;
  orginSz = false;

  isWorking = false;
  msgLoading = '';
  errorContract = false;

  showOtpModal = false;
  errorOtp: ApiError;
  documents: GeneratedDocuments[] = [];
  documentsPdfGeneratedList: GeneratedDocuments[] = [];
  documentsToSign: SignAdobeDocuments;
  agreementId: string;

  count = 0;
  isButtonDisabled: boolean = true;
  userName: string;

  public nextStage = PATHS.finalStep;
  isPanama = false;
  token: string;

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    protected confirmationService: ConfirmationService,
    private commonDataService: CommonDataService,
    private readonly signatureService: SignatureService,
    private readonly detectedIdService: DetectedIdService,
    private gtmService: GtmService,
    private middlewareService: MiddlewareService,
    private sanitizer: DomSanitizer,
    private loaderService: LoaderService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.loadDataAndDocuments();
  }

  loadDocumentToSign(data) {
    this.documentsToSign = data;

    this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(
      data.signingUrl
    );
    if (!window.addEventListener) {
      window['attachEvent'](
        'onmessage',
        this.eventHandlerAcrobatSign.bind(this)
      );
    } else {
      window.addEventListener(
        'message',
        this.eventHandlerAcrobatSign.bind(this),
        false
      );
    }
  }

  private eventHandlerAcrobatSign(e) {
    if (e.origin == ACROBAT_SIGN_ORIGIN) {
      const data = JSON.parse(e.data);
      // console.log('eventHandlerAcrobatSign / data:', data);

      if (ACROBAT_SIGN_STATE.ESIGN === data.type) {
        this.routerC.navigate(['onboarding', this.nextStage]);
      } else if (ACROBAT_SIGN_STATE.REJECT) {
      }
    }
  }

  loadDataAndDocuments() {
    this.canNavigateToBack(true);
    this.loaderService.show();
    this.middlewareService
      .postSignAdobeDocuments()
      .pipe(
        delay(12000),
        switchMap((data: any) =>
          this.middlewareService.getSignAdobeDocuments(data.agreementId).pipe()
        ),
        finalize(() => this.loaderService.hide())
      )
      .subscribe((data: any) => {
        this.loadDocumentToSign(data);
      });

    this.middlewareService
      .getUsersLookup()
      .subscribe((data) => (this.userName = data.firstName));

    this.isPanama = true;
  }

  ngOnDestroy() {
    this.canNavigateToBack(true);
    this.subscription.unsubscribe();
  }

  closeOtpModal() {
    this.showOtpModal = false;
    this.errorOtp = undefined;
  }

  getAction() {
    return PATHS.viewContract;
  }
}
