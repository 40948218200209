import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ReCaptchaV3Service } from 'ng-recaptcha';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField
} from 'ngx-intl-tel-input';
import { finalize, switchMap } from 'rxjs/operators';

import { validateNoSpaces } from '../../../vass-core/validators/no-spaces.validator';

import { EMAIL_REGEXP, HOLDER, PATHS } from '../../../shared/constants';
import { InitialData, NewUserModel } from '../../model/models';

import { PichBaseComponent } from '../pichincha-base.component';

import { CommonDataService } from '../../../shared/services/common-data.service';
import { DetectedIdService } from '../../../shared/services/detected-id/detected-id.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { GtmService } from './../../../shared/services/gtm/gtm.service';
import { MiddlewareService } from './../../../shared/services/middleware.service';

import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { OnboardingService } from '../../services/onboarding/onboarding.service';

@Component({
  selector: 'app-pich-contact-data',
  templateUrl: './pich-contact-data.component.html',
  styleUrls: ['./pich-contact-data.component.scss']
})
export class PichContactDataComponent
  extends PichBaseComponent
  implements OnInit
{
  public formContactData: FormGroup;
  public registerData = new InitialData();

  public nextStage = PATHS.identityManualConfirm;
  public prevStage = PATHS.initialData;

  public isSecondHolder = false;
  public isPanama = false;

  readonly HTML_VALIDATORS = {
    minLength: 1,
    maxLength: {
      strName: 150,
      strEmail: 50,
      strPhone: 15
    }
  };

  CountryISO = CountryISO;
  searchCountryField = [SearchCountryField.Iso2, SearchCountryField.Name];
  PhoneNumberFormat = PhoneNumberFormat.International;
  preferredCountries: CountryISO[] = [CountryISO.Ecuador];

  showOtpModal = false;
  opportunityInProgress = false;
  errorOtp: string;
  errorOpportunityInProgress: string;
  email: string;
  captchaPending = false;
  optOpportunyId: string;

  constructor(
    private formBuilder: FormBuilder,
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private commonDataService: CommonDataService,
    private readonly middlewareService: MiddlewareService,
    private readonly detectedIdService: DetectedIdService,
    private gtmService: GtmService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private onboardingService: OnboardingService,
    protected confirmationService: ConfirmationService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  /**
   * Inicializar el formulario con los datos recibidos desde el servicio de la pantalla anterior.
   */
  ngOnInit() {
    Object.assign(this.registerData, this.commonDataService.getDataRegister());

    if (this.registerData.targetCountry === 'L_PANBM') this.isPanama = true;
    this.initForm();
  }

  initForm() {
    this.formContactData = this.formBuilder.group(
      {
        strEmail: [
          '',
          [
            Validators.required,
            Validators.pattern(EMAIL_REGEXP),
            Validators.maxLength(this.HTML_VALIDATORS.maxLength.strEmail),
            validateNoSpaces
          ]
        ],
        repeatEmail: ['', Validators.required],
        strPhone: ['', [Validators.required]]
      },
      { validators: [this.matchingEmail] }
    );
  }

  get strEmail() {
    return this.formContactData.get('strEmail');
  }
  get repeatEmail() {
    return this.formContactData.get('repeatEmail');
  }
  get strPhone() {
    return this.formContactData.get('strPhone');
  }

  getAction() {
    return 'New2Holders';
  }

  /**
   * Sobre escribe el metodo del componente del que hereda. Comprueba que el formulario es válido y lo guarda en
   * una variable en el servicio CommonDataService.
   * Si el forulario es válido navega a la sigiente pantalla.
   */
  onSubmit() {
    this.formContactData.controls['repeatEmail'].updateValueAndValidity();
    if (this.formContactData.valid) {
      this.sendGTM();
      let formContactDataValue = this.formContactData.value;
      this.commonDataService.setDataRegister(this.registerData);
      this.onNextStage(this.nextStage);
    } else {
      this.formContactData.markAllAsTouched();
    }
  }

  private redirect() {
    this.routerC.navigate(['onboarding', PATHS.welcome]);
  }

  private sendGTM() {
    this.gtmService.sendVariableGTM({
      pageType: 'Datos de contacto',
      strEmail: this.strEmail.value
    });

    this.gtmService.trackEvent('onboard  ing', 'paso 3', 'Datos de contacto');
  }

  generateOtpMail() {
    if (this.formContactData.valid) {
      if (!this.captchaPending) {
        this.email = this.strEmail.value;
        this.captchaPending = true;
        this.recaptchaV3Service
          .execute('recoveryOnb')
          .pipe(
            finalize(() => {
              this.captchaPending = false;
            }),
            switchMap((captcha) => {
              return this.middlewareService.loginOnboarding({
                email: this.strEmail.value,
                captchaResponse: captcha
              });
            })
          )
          .subscribe(
            (data) => {
              this.optOpportunyId = data.otpProcessId;
            },
            (error) => {
              this.setErrorMessages(error.responseCode);
            }
          );
      }
    } else {
      this.formContactData.markAllAsTouched();
    }
  }

  generateOpportunity() {
    if (!this.captchaPending) {
      if (this.formContactData.valid) {
        this.captchaPending = true;
        this.subscription.add(
          this.recaptchaV3Service
            .execute('recoveryOnb')
            .pipe(
              finalize(() => {
                this.captchaPending = false;
              }),
              switchMap((captcha) =>
                this.onboardingService.generateOtp({
                  blnFinancialInfoConsent:
                    this.registerData.blnFinancialInfoConsent,
                  blnPersonalDataConsent:
                    this.registerData.blnPersonalDataConsent,
                  blnPersonalDataSharingConsent:
                    this.registerData.blnPersonalDataSharingConsent,
                  blnSocialMediaContactConsent:
                    this.registerData.blnSocialMediaContactConsent,
                  sourceCountry: this.registerData.sourceCountry,
                  targetCountry: this.registerData.targetCountry,
                  email: this.strEmail.value,
                  prePhone: this.strPhone.value.dialCode,
                  sufPhone: this.strPhone.value.number.replace(/\s+/g, '')
                })
              )
            )
            .subscribe(
              ({ otpProcessId }) => {
                // this.opportunity = otpData;
                this.optOpportunyId = otpProcessId;

                this.showOtpModal = true;
              },
              (error) => {
                this.opportunityInProgress = true;
                this.errorOpportunityInProgress = error;
                this.setErrorMessages(error.responseCode);
              }
            )
        );
      } else {
        this.formContactData.markAllAsTouched();
      }
    }
  }

  validateOtp(otpKey: string) {
    this.subscription.add(
      this.middlewareService
        .validateOtpMail(otpKey, this.optOpportunyId)
        .subscribe(
          (resp) => {
            this.commonDataService.setOptAccessToken(resp);
            this.closeOtpModal();
            this.onSubmit();
          },
          (error) => {
            this.errorOtp = error;
            this.setErrorMessages(error.responseCode);
          }
        )
    );
  }

  closeOtpModal() {
    this.showOtpModal = false;
    this.errorOtp = '';
  }

  // mapperBody(): {
  //   action_method: string;
  //   param: NewUserModel;
  // } {
  //   const firstHolder = this.registerData.firstHolder;
  //   const secondHolder = this.registerData.secondHolder;

  //   const body: NewUserModel = {
  //     holders: [
  //       {
  //         holder: HOLDER.PRIMARY,
  //         strEmail: firstHolder.strEmail?.toLowerCase(),
  //         strPhone: firstHolder.strPhone,
  //         blnCheckFinancialCreditInformation:
  //           firstHolder.blnCheckFinancialCreditInformation,
  //         blnCheckDataPrivacyPolicy: firstHolder.blnCheckDataPrivacyPolicy,
  //         blnTreatmentPersonalInformation:
  //           firstHolder.blnTreatmentPersonalInformation,
  //         blnSharePersonalDataEconomicGroup:
  //           firstHolder.blnSharePersonalDataEconomicGroup,
  //         integrationId: firstHolder.integrationId,
  //         timeStamp: firstHolder.timeStamp,
  //         otpKey: firstHolder.otpKey
  //       }
  //     ]
  //   };
  //   if (this.registerData.hasMultipleHolders) {
  //     body.holders.push({
  //       holder: HOLDER.SECONDARY,
  //       strEmail: secondHolder.strEmail?.toLowerCase(),
  //       strPhone: secondHolder.strPhone,
  //       blnCheckFinancialCreditInformation:
  //         secondHolder.blnCheckFinancialCreditInformation,
  //       blnCheckDataPrivacyPolicy: secondHolder.blnCheckDataPrivacyPolicy,
  //       blnTreatmentPersonalInformation:
  //         secondHolder.blnTreatmentPersonalInformation,
  //       blnSharePersonalDataEconomicGroup:
  //         secondHolder.blnSharePersonalDataEconomicGroup,
  //       integrationId: secondHolder.integrationId,
  //       timeStamp: secondHolder.timeStamp,
  //       otpKey: secondHolder.otpKey
  //     });
  //   }
  //   return {
  //     action_method: this.getAction(),
  //     param: body
  //   };
  // }

  onNextStage(nextStage: string) {
    this.pichCService.setStrOppId(this.optOpportunyId);
    this.routerC.navigate(['onboarding', PATHS.identityManualConfirm]);
  }

  /**
   * Sobre escribe el metodo del padre, para volver a la página anterior. Guarda los datos que ha introducido el
   * usuario para completar el formulario cuando vuelva a este paso.
   */

  onBackStage() {
    this.commonDataService.setDataRegister(this.registerData);
    this.routerC.navigate(['onboarding', PATHS.initialData]);
  }

  matchingEmail(form: FormGroup) {
    const email = form?.value?.strEmail?.toLowerCase();
    const confirmEmail = form?.value?.repeatEmail?.toLowerCase();

    return email === confirmEmail ? null : { invalidRepearEmail: true };
  }

  validateCharSpecial(e: any) {
    return e.charCode >= 48 && e.charCode <= 57;
  }
}
