import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { OTP_ERROR } from '../../../shared/constants';
import { ApiError } from '../../model/api/api-error';

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss']
})
export class OtpModalComponent implements OnInit {
  readonly maxLengthCode = 6;
  otpControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(6),
    Validators.minLength(6)
  ]);

  isVisible = true;
  @Input() title: string;
  @Input() set error(error: ApiError) {
    if (error) {
      this.handlerErrorOtp(error);
    }
  }
  @Input() email: string;

  @Output() onHide = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<string>();
  @Output() onGenerateOtp = new EventEmitter<string>();

  errorMessage = '';

  constructor() {}

  ngOnInit(): void {}

  closeModal() {
    this.onHide.emit();
  }

  generateOtp() {
    this.onGenerateOtp.emit(this.email);
  }

  validateOtp() {
    if (this.otpControl.valid) {
      this.onConfirm.emit(this.otpControl.value);
    }
  }

  private handlerErrorOtp(error: ApiError) {
    if (error.message === OTP_ERROR.INVALID) {
      this.errorMessage = 'onboarding_incomplete_record_error';
    } else if (error.message === OTP_ERROR.NUM_RETRIES_EXCEEDED) {
      this.errorMessage = 'onboarding_otp_error_num_retries_exceeded';
      this.generateOtp();
    } else {
      setTimeout(() => {
        this.closeModal();
      }, 100);
    }
  }
}
