import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

import { LoaderState } from './loader.interface';

@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();
  private loaderSubjectMsg: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  loaderState = this.loaderSubject.asObservable();
  private cont = 0;
  constructor() {}

  show() {
    this.cont++;
    this.loaderSubject.next(<LoaderState>{ show: true });
  }

  hide() {
    this.cont--;
    if (this.cont <= 0) {
      this.loaderSubject.next(<LoaderState>{ show: false });
      this.loaderSubjectMsg.next('');
      this.cont = 0;
    }
  }

  getMessage() {
    return this.loaderSubjectMsg;
  }

  sendMessage(msg: string) {
    this.loaderSubjectMsg.next(msg);
  }
}
