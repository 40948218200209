import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GoogleAnalyticsModel } from '../../model/google-analytics.model';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  private cookies: any = {
    _ga: ''
  };

  constructor(
    private gtmService: GoogleTagManagerService,
    private deviceService: DeviceDetectorService,
    private router: Router
  ) {}

  /** El metodo sirve para enviar eventos a google analytics
   *
   * @param eventCategory Suele ser el objeto con el que se ha interactuado (p. ej., 'Video').
   * @param eventAction Tipo de la interacción (por ejemplo, 'play')
   * @param eventLabel Útil para clasificar los eventos (p. ej., 'Fall Campaign'). NO ES OBLIGATORIO, PERO ES RECOMENDABLE
   * @param eventValue Valor numérico asociado al evento (por ejemplo, 42) NO ES OBLIGATORIO
   */
  trackEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    this.gtmService.pushTag({
      event: eventCategory,
      eAction: eventAction,
      eLabel: eventLabel,
      eValue: eventValue
    });
  }

  /** Este método se utiliza para enviar variables a Google Tag Manager
   *
   * @param item Este objeto es una interface con todos los posibles datos que se tienen que mandar
   */
  sendVariableGTM(item: GoogleAnalyticsModel) {
    const cookiesSplit = document.cookie.split('; ');
    for (const element of cookiesSplit) {
      const cur = element.split('=');
      this.cookies[cur[0]] = cur[1];
    }

    item.clientId = this.cookies._ga;
    item.url = this.url;
    item.dispositivo = this.device;
    item.producto = 'cuenta pibank'; // actualmente siempre es cuenta pibank

    this.gtmService.pushTag(item);
  }

  private get device() {
    if (this.deviceService.isMobile()) {
      return 'movil';
    } else if (this.deviceService.isTablet()) {
      return 'tablet';
    } else if (this.deviceService.isDesktop()) {
      return 'laptop';
    }
    return 'unknown';
  }

  private get url() {
    return this.router.url?.replace('/onboarding', '');
  }
}
