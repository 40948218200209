export function filterList(
  listCompleted: Array<{ code: string; name: string }>,
  find: { query: string },
  key = 'name'
) {
  const filtered = [];
  listCompleted.forEach((e) => {
    if (parseToSearch(e[key]).indexOf(parseToSearch(find?.query)) >= 0) {
      filtered.push(e);
    }
  });

  return filtered;
}

export function filterListProfession(
  listCompleted: Array<{ name: string }>,
  find: { query: string },
  key = 'name'
) {
  const filtered = [];
  listCompleted.forEach((e) => {
    if (parseToSearch(e[key]).indexOf(parseToSearch(find?.query)) >= 0) {
      filtered.push(e);
    }
  });

  return filtered;
}

function parseToSearch(value: string) {
  return value
    .toLocaleLowerCase()
    .trim()
    .replace(/á/g, 'a')
    .replace(/é/g, 'e')
    .replace(/í/g, 'i')
    .replace(/ó/g, 'o')
    .replace(/ú/g, 'u');
}
