import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseData } from '../../model/base-data.model';
import { InitialData } from '../../model/initial-data.model';

import { PichBaseComponent } from '../pichincha-base.component';

import { CommonDataService } from '../../../shared/services/common-data.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { GtmService } from './../../../shared/services/gtm/gtm.service';

import { PATHS } from '../../../shared/constants';

@Component({
  selector: 'app-pich-initial-data',
  templateUrl: './pich-initial-data.component.html',
  styleUrls: ['./pich-initial-data.component.scss']
})
export class PichInitialDataComponent
  extends PichBaseComponent
  implements OnInit
{
  dataRegister = new InitialData();
  action = PATHS.initialData;

  formInitialData: FormGroup;
  title: string;
  isSecondHolder = false;
  firstDocument: boolean;
  secondDocument: boolean;
  isDisabled: boolean = false;
  isPanama: boolean = false;

  privacyPolicy: string;

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    protected confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private commonDataService: CommonDataService,
    private gtmService: GtmService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  /**
   * Se recuperan los datos de registro por si se ha llegado a lapágina a través de la flecha atrás.
   * Se inicializar el formulario con estos datos.
   */
  ngOnInit() {
    Object.assign(this.dataRegister, this.commonDataService.getDataRegister());

    this.createForm();
    this.privacyPolicy = this.dataRegister.privacyPolicy;
    if(this.privacyPolicy === 'L_PANBM') this.isPanama = true;
  }

  /** Inicializar el formulario con los datos que hay por defecto, o con los que se recuperan a través del servicio
   * this.commonDataService.getDataUser().
   *
   */
  private createForm(): void {
    this.formInitialData = this.formBuilder.group({
      blnCheckClientIdentificationProgram: [false, Validators.requiredTrue],
      blnCheckElectronicSignature: [false, Validators.requiredTrue],
      blnCheckDataPrivacyPolicy: [false, Validators.requiredTrue]
    });
  }

  get blnCheckClientIdentificationProgram() {
    return this.formInitialData.get('blnCheckClientIdentificationProgram');
  }
  get blnCheckElectronicSignature() {
    return this.formInitialData.get('blnCheckElectronicSignature');
  }
  get blnCheckDataPrivacyPolicy() {
    return this.formInitialData.get('blnCheckDataPrivacyPolicy');
  }

  public getBodyPost(): BaseData {
    return new BaseData(this.action, this.dataRegister);
  }

  /** Navega a la página sigiente si el formulario es válido
   *  Se guardan los datos en un servicio para que si se vuelve a la pantalla el formulario apareza en el
   *  estado completado.
   *
   */
  public onNextStage(): void {
    if (this.formInitialData.valid) {
      this.gtmService.sendVariableGTM({
        pageType: 'Datos Iniciales'
      });

      this.gtmService.trackEvent('onboarding', 'paso 2', 'Datos Iniciales');
      const formInitialDataValue = this.formInitialData.value;

      this.dataRegister.blnFinancialInfoConsent =
        formInitialDataValue.blnCheckDataPrivacyPolicy;
      this.dataRegister.blnPersonalDataConsent =
        formInitialDataValue.blnCheckDataPrivacyPolicy;
      this.dataRegister.blnPersonalDataSharingConsent =
        formInitialDataValue.blnCheckDataPrivacyPolicy;
      this.dataRegister.blnSocialMediaContactConsent =
        formInitialDataValue.blnCheckDataPrivacyPolicy;

      this.commonDataService.setDataRegister(this.dataRegister);


      this.routerC.navigate(['onboarding', PATHS.contactData]);
    } else {
      this.formInitialData.markAllAsTouched();
    }
  }

  checkDocument(formControl: string, typeDocument?: string): void {
    if (formControl === 'blnCheckDataPrivacyPolicy') {
      this.formInitialData.get(formControl).setValue(true);
    }
  }

  public onBackStage(): void {
    if (this.isSecondHolder) {
      Object.assign(this.dataRegister.secondHolder, this.formInitialData.value);
      this.commonDataService.setDataRegister(this.dataRegister);
      this.commonDataService.setIsSecondHolder(false);
      this.routerC.navigate(['onboarding', PATHS.contactData]);
    } else {
      // si es el primer titular guardar los datos en cache para el primer titular
      // navegar a la pantalla anterior.
      Object.assign(this.dataRegister.firstHolder, this.formInitialData.value);
      this.commonDataService.setDataRegister(this.dataRegister);
      this.routerC.navigate(['onboarding', PATHS.accountOwner]);
    }
  }
}
