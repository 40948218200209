import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-holders-icons',
  templateUrl: './holders-icons.component.html',
  styleUrls: ['./holders-icons.component.scss']
})
export class HoldersIconsComponent implements OnInit {
  @Input() blnMultTitulares: boolean;
  @Input() holder: 'first' | 'second' | 'both';

  constructor() {}

  ngOnInit() {}
}
