// Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { CommonDataService } from './../../../shared/services/common-data.service';
import { DetectedIdService } from './../../../shared/services/detected-id/detected-id.service';
import { GtmService } from './../../../shared/services/gtm/gtm.service';
import { MiddlewareService } from './../../../shared/services/middleware.service';

// Componentes
import { PichBaseComponent } from '../pichincha-base.component';

// Models
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { finalize, switchMap } from 'rxjs/operators';
import { PATHS } from '../../../shared/constants';
import { InitialData } from '../../model/initial-data.model';
import { ConfirmationService } from './../../../vass-core/components/modal/confirmation.service';
import { ApiError } from './../../model/api/api-error';
import { KYCData, PersonalInfoModel } from '../../model/params.model';

@Component({
  selector: 'app-pich-welcome',
  templateUrl: './pich-welcome.component.html',
  styleUrls: ['./pich-welcome.component.scss']
})
export class PichWelcomeComponent extends PichBaseComponent implements OnInit {
  public errorServer = '';
  public dataRegister = {} as InitialData;
  showOtpModal = false;
  showEmailModal = false;
  errorOtp: ApiError;
  email: string;

  captchaPending = false;
  optOpportunyId: string;
  consultTramit = false;
  contactUs: boolean = false;
  visible: boolean = false;
  kycData: KYCData;
  user: PersonalInfoModel;
  statusTransaction: string;
  personalData: PersonalInfoModel;
  blnCheckStatus: boolean;
  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private readonly middlewareService: MiddlewareService,
    private readonly detectedIdService: DetectedIdService,
    private commonDataService: CommonDataService,
    private gtmService: GtmService,
    protected confirmationService: ConfirmationService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    super(routerC, pichCService, confirmationService);
  }

  /**
   * Método onInit de Angular.
   */
  ngOnInit() {
    Object.assign(this.dataRegister, this.commonDataService.getDataRegister());
    this.commonDataService.setIsSecondHolder(false);
  }

  /** Navega a la pantalla siguiente.
   */
  onNextStage() {
    // Saltar a pantalla initialData by Harol Conde
    const hasMultipleHolders: any = {
      firstHolder: {
        strEmail: null,
        strPhone: null,
        strName: null
      },
      secondHolder: {
        strEmail: null,
        strPhone: null,
        strName: null
      },
      hasMultipleHolders: false
    };
    this.routerC.navigate(['onboarding', PATHS.accountOwner]);
    this.commonDataService.setDataRegister(hasMultipleHolders);

    this.gtmService.sendVariableGTM({
      pageType: 'bienvenida'
    });

    this.gtmService.trackEvent('onboarding', 'paso 1', 'Bienvenida');
  }

  openEmailModal() {
    this.showEmailModal = true;
  }

  doLogin(email: string) {
    if (!this.captchaPending) {
      this.email = email;
      this.captchaPending = true;
      this.recaptchaV3Service
        .execute('recoveryOnb')
        .pipe(
          finalize(() => {
            this.captchaPending = false;
          }),
          switchMap((captcha) => {
            return this.middlewareService.loginOnboarding({
              email: email,
              captchaResponse: captcha,
              blnCheckStatus: this.consultTramit
            });
          })
        )
        .subscribe(
          (data) => {
            this.optOpportunyId = data.otpProcessId;
            this.showOtpModal = true;
            this.showEmailModal = false;
            this.closeEmailModal();
          },
          (error) => {
            this.setErrorMessages(error.responseCode);
            this.showEmailModal = false;
          }
        );
    }
  }

  validateOtp(otp: string) {
    this.middlewareService.confirmOtpLogin(otp, this.optOpportunyId).subscribe(
      (data: any) => {
        this.commonDataService.setOptAccessToken(data);
        this.pichCService.setStrOppId(otp);
        this.pichCService.setOptOpportunyId(this.optOpportunyId);
        this.showEmailModal = false;
        this.closeOtpModal();
        this.loadData();
        if (this.consultTramit == false) {
          this.routerC.navigate([
            'onboarding',
            this.pichCService.translateCaseStageToRoute(
              data.opportunityStage,
              data.nextScreen
            )
          ]);
        } else {
          this.visible = true;
          this.statusTransaction;

          this.email;
          this.openStatusModal(false);
        }
      },
      (error) => {
        this.errorOtp = error;
        this.closeOtpModal();
        this.closeStatusModal();
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  closeOtpModal() {
    this.showOtpModal = false;
    this.errorOtp = undefined;
    this.showEmailModal = false;
  }
  closeEmailModal() {
    this.showEmailModal = false;
  }

  openStatusModal(e: any) {
    this.showEmailModal = e;
    this.consultTramit = true;
  }

  closeStatusModal() {
    this.visible = false;
    this.contactUs = false;
    this.consultTramit = false;
  }

  contact() {
    this.contactUs = true;
    this.contactRequired();
    this.closeStatusModal();
  }

  loadData() {
    this.middlewareService.getUsersLookup().subscribe((data: any) => {
      this.personalData = data;
      this.statusTransaction = data.stageName;
    });
  }

  private getPersonalInfo(): PersonalInfoModel {
    return {
      firstName: this.personalData.firstName,
      middleName: ' ',
      lastName: this.personalData.lastName,
      secondLastName: this.personalData.secondLastName,
      birthDate: this.personalData.birthDate,
      cityOfResidence: this.personalData.cityOfResidence,
      countryOfResidence: this.personalData.countryOfResidence,
      residentialAddress: this.personalData.residentialAddress,
      countryOfBirth: this.personalData.countryOfBirth,
      nationality: this.personalData.nationality,
      passportNumber: this.personalData.passportNumber,
      expiryDate: this.personalData.expiryDate,
      countryOfExpedition: this.personalData.countryOfExpedition,
      documentType: 'PASAPORTE',
      documentNumber: this.personalData.documentNumber,
      taxDocumentNumber: this.personalData.taxDocumentNumber,
      gender: this.personalData?.gender,
      maritalStatus: this.personalData?.maritalStatus,
      oriDesTable: this.personalData?.oriDesTable,
      step: '3',
      createBeneficiaryTable: this.personalData?.createBeneficiaryTable,
      requiresBen: this.personalData?.requiresBen,
      contactUs: this.contactUs
      /* opportunyId: this.optOpportunyId */
    };
  }

  contactRequired() {
    this.middlewareService
      .setPersonalInfo(this.getPersonalInfo())
      .subscribe((data) => {
        console.log(data);
      });
  }

  /* openModalStatus(){
    if(this.)
  } */

  // setIntegrationsId(data: Clients) {
  //   this.detectedIdService.setOpportunity(
  //     {
  //       integrationId: data.integrationIdPrimaryOwner,
  //       timeStamp: data.timeStamp,
  //       gtmId: data.gtmPrimaryOwnerId
  //     },
  //     false
  //   );
  //   if (data.integrationIdAdditionalOwner) {
  //     this.detectedIdService.setOpportunity(
  //       {
  //         integrationId: data.integrationIdAdditionalOwner,
  //         timeStamp: data.timeStamp,
  //         gtmId: data.gtmAdditionalOwnerId
  //       },
  //       true
  //     );
  //   }
  // }
}
