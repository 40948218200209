import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ModalService {
  rootViewContainer: any;
  private onHideModal = new Subject<void>();

  constructor(private resolver: ComponentFactoryResolver) {}

  public setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  public addDynamicComponent(customComponent: any) {
    const factory = this.resolver.resolveComponentFactory(customComponent);
    const component = factory.create(this.rootViewContainer.parentInjector);

    this.rootViewContainer.insert(component.hostView);
  }

  public hiddenModal() {
    this.onHideModal.next();
  }

  public getHideModal() {
    return this.onHideModal;
  }
}
