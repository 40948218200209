import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { distinctUntilChanged, retry } from 'rxjs/operators';

import { ACTION, PATHS } from './../../../shared/constants';

import { PichBaseComponent } from '../pichincha-base.component';

import { ParamsModel } from '../../../shared/model/api/params.model';

import { haveSameValue } from '../../../shared/utils/custom-validators';

import { CommonDataService } from '../../../shared/services/common-data.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { GtmService } from './../../../shared/services/gtm/gtm.service';
import { MiddlewareService } from './../../../shared/services/middleware.service';

@Component({
  selector: 'app-pich-document-type',
  templateUrl: './pich-document-type.component.html',
  styleUrls: ['./pich-document-type.component.scss']
})
export class PichDocumentTypeComponent
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  prevStage = PATHS.contactData;
  action = PATHS.documentType;

  form: FormGroup;

  docNumFirstHolder = '';

  documentTypes$: Observable<ParamsModel[]>;
  documentTypes = {
    cc: 1,
    ce: 2
  };

  inspectorError = false;

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private commonDataService: CommonDataService,
    private formBuilder: FormBuilder,
    private readonly middlewareService: MiddlewareService,
    protected confirmationService: ConfirmationService,
    private gtmService: GtmService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.documentTypes$ = this.middlewareService
      .getDocumentType()
      .pipe(retry(5));

    this.initForm();

    this.canNavigateToBack(false);
  }

  initForm() {
    this.form = this.formBuilder.group({
      documentType: ['', Validators.required],
      documentNumber: ['', Validators.required]
    });

    this.documentType.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.documentNumber.setValidators(this.getValidatorsForm(value));
        this.documentNumber.updateValueAndValidity();
      });
  }

  getValidatorsForm(value: { id: number }) {
    let listValidators = [];
    if (value?.id === this.documentTypes.cc) {
      listValidators = [Validators.required, this.validatorDocumentCC];
    } else if (value?.id === this.documentTypes.ce) {
      listValidators = [Validators.required, this.validatorDocumentCE];
    }

    if (this.isSecondHolder) {
      listValidators.push(haveSameValue(this.docNumFirstHolder));
    }
    return listValidators;
  }

  validatorDocumentCC(control: FormControl) {
    const value = control.value && control.value.replace(/\./g, '');
    return value.length < 1 || value.length > 10
      ? { invalidFormat: true }
      : null;
  }

  validatorDocumentCE(control: FormControl) {
    const value = control.value && control.value.replace(/\./g, '');
    return value.length < 5 || value.length > 7
      ? { invalidFormat: true }
      : null;
  }

  get documentType() {
    return this.form.get('documentType');
  }
  get documentNumber() {
    return this.form.get('documentNumber');
  }

  ngOnDestroy() {
    this.canNavigateToBack(true);
  }

  public getAction(): string {
    return ACTION.inspektor;
  }

  /* public getBodyPost() {
    const inskpectorData: InspectorModel = {
      strOppId: this.pichCService.getStrOppId(),
      holder: this.isSecondHolder ? HOLDER.SECONDARY : HOLDER.PRIMARY,
      strDocumentNumber: this.documentNumber.value,
      strCodeDocumentType: this.documentType.value.code,
      strDocumentType: this.documentType.value.name
    };
  }
  */

  sendForm() {
    if (this.form.valid) {
      this.gtmService.sendVariableGTM({
        pageType: 'Tipo de documento'
      });
      this.gtmService.trackEvent('onboarding', 'paso 4', 'Tipo de documento');
      this.saveInspectorData();
    } else {
      this.form.markAllAsTouched();
    }
  }

  saveInspectorData() {
    this.routerC.navigate(['onboarding', PATHS.identityManualConfirm]);
    //     this.routerC.navigate(['onboarding', PATHS.videoIdentification]);
  }

  onBackStage() {
    if (this.isMultTitulares) {
      if (this.isSecondHolder) {
        this.isSecondHolder = false;
        this.commonDataService.setIsSecondHolder(false);
        this.ngOnInit();
      }
    }
  }
}
