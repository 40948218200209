// import { PersonalData } from './personal-data.model';
// import { Address } from './address.model';
import { HolderModel } from './holder-model';
// export class UserCase {
//   validated: boolean;
//   strCaseStage: string;
//   strCaseId: string;
//   strAccountIBAN: string;
//   status: number;
//   message: string;
//   error: boolean;

//   signatoryId?: string;
//   signatureId?: string;
//   contractId?: string;

//   public clsRecoverNewUser: HolderModel;
//   public clsResponseSavePersonalData: PersonalData;
//   public clsResponseAddress: Address;
//   public clsResponseKYC: KYC;
//   public clsResponseGenerateUploadPDFEID: AcceptConditions;
// }

export interface RecoverModel {
  validated: boolean;
  strRol: string;
  strOpportunityStage: string;
  // strOppId: string;
  strAccountType: string;
  strAccountNumber: string;
  status: string;
  oppAcceptanceStatus: string;
  multiOwner: boolean;
  message: string;
  errorCode: string;
  error: boolean;
  holder: string;

  clsResponseSavePersonalData: PersonalDataResponse;
  clsResponseKYC: KYCResponse;
  clsResponseAddress: AddressResponse;
  clsConsents: ConsentsResponse;
  clsContract: ClsContract;
}

export interface PersonalDataResponse {
  canModify: boolean;
  acceptanceStatus: string;
  dtBirhDate: string;
  dtExpeditionDate: string;
  strCountry: string;
  strCountryISO: string;
  strDocumentNumber: string;
  strDocumentType: string;
  strDocumentTypeCode: string;
  strEmail: string;
  strFirstLastName: string;
  strFirstName: string;
  strGender: string;
  strMiddleName: string;
  strNationality: string;
  strNationalityISO: string;
  strPhone: string;
  strSecondLastName: string;
  integrationId: string;
  expeditionDocumentDepartment: string;
}

export interface ConsentsResponse {
  blnProductRegulations: boolean;
  blnPEPs: boolean;
  blnMobileBanking: boolean;
}

export interface AddressResponse {
  strMunicipalityCode: string;
  strMunicipality: string;
  strDepartmentCode: string;
  strDepartment: string;
  strAddressComplement?: string;
  strAddress: string;
}

export interface KYCResponse {
  strProfessionalSectorCode: string;
  strProfessionalSector: string;
  strOtherIncomeDetails: string;
  strOccupation: string;
  strOccupationCode: string;
  strCurrentPosition: string;
  strCurrentPositionCode: string;
  strActivityTypeCode: string;
  strActivityType: string;
  strAccountPurpose: string;
  numOtherIncome: number;
  numMonthlyIncome: number;
  numMonthlyExpenses: number;
  numLiabilities: number;
  numAssets: number;
  blnHasAcceptFundsStatement: boolean;
}

export interface ClsContract {
  signingContractStatus: string;
  contractId: string;
}
