import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() message = '';
  public isActive = false;
  constructor() {}

  ngOnInit(): void {}

  public showTooltip() {
    setTimeout(() => {
      this.isActive = true;
    }, 300);
  }

  public hideTooltip() {
    setTimeout(() => {
      this.isActive = false;
    }, 500);
  }
}
