import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import { finalize, retry, tap } from 'rxjs/operators';

import { AddressResponse } from '../../model/recover.model';
import { ACTION, CASE_STAGES, PATHS } from './../../../shared/constants';

import { PichBaseComponent } from '../pichincha-base.component';
import { PichModalGenericComponent } from './../../../shared/components/modals/pich-modal-generic/pich-modal-generic.component';

import { validateNoSpaces } from '../../../vass-core/validators/no-spaces.validator';
import { filterList } from './../../../shared/utils/filter-list';

import { CommonDataService } from '../../../shared/services/common-data.service';
import { MiddlewareService } from '../../../shared/services/middleware.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { GtmService } from './../../../shared/services/gtm/gtm.service';
import { ConfirmationService } from './../../../vass-core/components/modal/confirmation.service';

@Component({
  selector: 'app-pich-address-data',
  templateUrl: './pich-address-data.component.html',
  styleUrls: ['./pich-address-data.component.scss']
})
export class PichAddressDataComponent
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  prevStage = PATHS.personalData2;
  caseStage = CASE_STAGES.address;

  sameAddressControl: FormControl = new FormControl();
  addressForm: FormGroup;

  HTML_VALIDATORS = {
    minLength: 3,
    maxLength: {
      address: 150
    }
  };

  private nextStep = PATHS.activity;

  departments = [];
  filteredDepartment = [];

  cities = [];
  filteredCities = [];

  constructor(
    private formBuilder: FormBuilder,
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private middlewareService: MiddlewareService,
    private commonDataService: CommonDataService,
    protected readonly confirmationService: ConfirmationService,
    private gtmService: GtmService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.initForm();
    this.getDepartment();
    this.isSecondHolder = this.commonDataService.isSecondHolder;
    this.isMultTitulares = this.commonDataService.isMultTitulares;

    /** si es segundo titular creamos nos subscribimos a cambios del formulario */
    this.subscription.add(
      this.sameAddressControl.valueChanges.subscribe((value) => {
        if (value) {
          this.addressForm.disable();
          this.loadForm(this.commonDataService.addressFirstHolder);
        } else {
          this.addressForm.enable();
          this.loadForm(this.userData.clsResponseAddress);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initForm() {
    this.addressForm = this.formBuilder.group({
      address: [
        '',
        [
          Validators.required,
          validateNoSpaces,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.address)
        ]
      ],
      department: ['', Validators.required, this.checkSelectOption],
      municipality: ['', Validators.required]
    });

    this.subscription.add(
      this.department.valueChanges.subscribe((value) => {
        if (this.department.valid) {
          this.fetchCities(value.code);
        }
      })
    );
  }

  private checkSelectOption(control: AbstractControl) {
    return typeof control.value === 'object'
      ? of(null)
      : of({ noOption: true });
  }

  get address() {
    return this.addressForm.get('address');
  }
  get department() {
    return this.addressForm.get('department');
  }
  get municipality() {
    return this.addressForm.get('municipality');
  }

  private getDepartment() {
    this.middlewareService
      .getDepartments()
      .subscribe((data) => (this.departments = data));
  }

  filterDepartment(event) {
    this.filteredDepartment = filterList(this.departments, event);
  }

  filterCities(event) {
    this.filteredCities = filterList(this.cities, event);
  }

  private fetchCities(code: string) {
    this.municipality.disable();
    this.middlewareService
      .getMunicipality(code)
      .pipe(
        tap((resp) => {
          const municipality = resp.find(
            (e) => e.code === this.municipality.value?.code
          );
          if (!municipality) {
            this.municipality.setValue(resp.length === 1 ? resp[0] : undefined);
          }
        }),
        retry(5),
        finalize(() => {
          this.municipality.enable();
        })
      )
      .subscribe(
        (data) => (this.cities = data),
        (error) => {
          this.showModalError();
        }
      );
  }

  showModalError() {
    this.confirmationService.confirm({
      component: PichModalGenericComponent,
      data: {
        title: 'onboarding_error_load_list_municipality_title',
        message: 'onboarding_error_load_list_municipality_message',
        txtButton: 'onboarding_error_load_list_municipality_btn'
      }
    });
  }

  private loadForm(address: AddressResponse) {
    if (address) {
      this.addressForm.patchValue({
        address: address.strAddress,
        department:
          address.strDepartmentCode && address.strDepartment
            ? {
                code: address.strDepartmentCode,
                name: address.strDepartment
              }
            : undefined,
        municipality:
          address.strMunicipalityCode && address.strMunicipality
            ? {
                code: address.strMunicipalityCode,
                name: address.strMunicipality
              }
            : undefined
      });
    }
  }

  public getAction() {
    return ACTION.saveAddress;
  }
  /*
  public getBodyPost() {
    const body: AddressDataScreen = {
      holder: this.isSecondHolder ? HOLDER.SECONDARY : HOLDER.PRIMARY,
      strOppId: this.pichCService.getStrOppId(),
      strAddress: this.addressForm.value.address,
      strDepartment: this.addressForm.value.department.name,
      strDepartmentCode: this.addressForm.value.department.code,
      strMunicipality: this.addressForm.value.municipality.name,
      strMunicipalityCode: this.addressForm.value.municipality.code
    };
  }
  */

  public onNext() {
    if (
      this.addressForm.valid ||
      (this.isSecondHolder &&
        this.addressForm.disabled &&
        this.sameAddressControl.value)
    ) {
      this.gtmService.sendVariableGTM({
        pageType: 'Direccion',
        direccion: this.addressForm.value.address,
        departamento: this.addressForm.value.department.name,
        ciudad: this.addressForm.value.municipality.name
      });

      this.gtmService.trackEvent('onboarding', 'paso 8', 'Direccion');

      /*  this.subscription.add(
        this.pichCService.postData(this.getBodyPost()).subscribe((data) => {
          if (data.status === '200') {
            if (this.isMultTitulares) {
              if (this.isSecondHolder) {
                this.commonDataService.setIsSecondHolder(false);
                this.routerC.navigate(['onboarding', this.nextStep]);
              } else {
                this.commonDataService.setAddressFirstHolder({
                  strAddress: this.addressForm.value.address,
                  strDepartment: this.addressForm.value.department.name,
                  strDepartmentCode: this.addressForm.value.department.code,
                  strMunicipality: this.addressForm.value.municipality.name,
                  strMunicipalityCode: this.addressForm.value.municipality.code
                });
                this.commonDataService.setIsSecondHolder(true);
                this.ngOnInit();
              }
              return;
            }
            this.routerC.navigate(['onboarding', this.nextStep]);
          }
        })
      );

      */
    } else {
      for (const campo in this.addressForm.controls) {
        if (this.addressForm.controls.hasOwnProperty(campo)) {
          this.addressForm.controls[campo].markAsTouched();
        }
      }
    }
  }

  public onBackStage() {
    if (this.isMultTitulares) {
      if (this.isSecondHolder) {
        this.commonDataService.setIsSecondHolder(false);
        this.addressForm.reset();
        this.addressForm.enable();
        this.ngOnInit();
      } else {
        this.commonDataService.setIsSecondHolder(true);
        this.routerC.navigate(['onboarding', this.prevStage]);
      }
    } else {
      this.routerC.navigate(['onboarding', this.prevStage]);
    }
  }
}
