import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { CaseDataService } from '../../pichincha-onboarding/shared/services/case-data.service';
import { environment } from '../../../environments/environment';
import { PATHS, USER_DATA_CASE } from '../constants';

@Injectable()
export class NavigationGuard implements CanActivate {
  stagesNoBack =
    'identificaciontipodocumentovideoidentificacionsuscripcionfirma';
  matchStage = false;
  continueOnb = false;
  alreadyPassed = false;

  constructor(private caseDataSrv: CaseDataService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }
  /* if (
      (environment.testMode &&
        (sessionStorage.getItem(USER_DATA_CASE) ||
          this.caseDataSrv.getStrOppId())) ||
      this.caseDataSrv.getStrOppId()
    ) {
      return true;
    } else {
      this.router.navigate(['onboarding', PATHS.welcome]);
      return false;
    }
  } */
}
