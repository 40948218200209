import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { PichBaseComponent } from '../pichincha-base.component';

import { EconomicActivityModel } from '../../../shared/model/economic-activity.model';
import { KYCData } from '../../model/params.model';

import { ACTION, CASE_STAGES, PATHS } from '../../../shared/constants';

import { CommonDataService } from '../../../shared/services/common-data.service';
import { GtmService } from '../../../shared/services/gtm/gtm.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { OnboardingService } from '../../services/onboarding/onboarding.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';

import { Observable } from 'rxjs';
import { ParamsModel } from '../../../shared/model/api/params.model';
import { OptionsType } from '../../../shared/model/option-type.model';
import { MiddlewareService } from '../../../shared/services/middleware.service';
import { filterList } from '../../../shared/utils/filter-list';
import { KYCDataService } from '../../services/kyc.data.service';
import { executivesModel } from '../../../shared/model/api/executive.model';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-pich-activity-2',
  templateUrl: './pich-activity-2.component.html',
  styleUrls: ['./pich-activity-2.component.scss'],
  providers: [MessageService]
})
export class PichActivity2Component
  extends PichBaseComponent
  implements OnInit
{
  formActivity: FormGroup;

  listSectorProfessional: Array<EconomicActivityModel> = [];
  filteredSectorProfessional = [];

  typeDeposit: OptionsType[] = [];
  receptionPurposeSendReasonList: OptionsType[] = [];

  prevStage = PATHS.activity;
  nextStage = PATHS.activity3;
  caseStage = CASE_STAGES.kyc;

  public mainSourceIncomesList = [];
  mainSourceIncomesArray: [] = [];
  mainSourceIncomesId: string;
  mainSourceIncomes: string;
  setMainSourceIncomes: string;
  public mainSourceIncomesObj: OptionsType;
  currentPosition$: Observable<Array<ParamsModel>>;

  isPanama = false;
  isPurposeReason = false;
  isPurposeSend = false;

  countries: Array<{ value: string; label: string }>;

  MAX_LENGTH = 16;
  MAX_LENGTH_AMOUNTH_LIMIT = 7;
  MAX_LENGTH_DECIMAL = 2;
  MAX_LENGTH_TEXT_AREA = 1000;
  MAX_LENGTH_TAX_RESIDENCE = 60;
  MAX_QUANTITY = 9999999999999999;
  MAX_QUANTITY_AMOUNTH_MAX = 50000;

  kycData: KYCData;
  totalAnnualIncome: number = 0;
  accountReference: boolean;
  executiveList: executivesModel[] = [];
  other: boolean = false;
  selectExecutive: any;
  stringExecutive: any;
  otherExecutive: any;

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    protected confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private commonDataService: CommonDataService,
    private readonly onboardingService: OnboardingService,
    private gtmService: GtmService,
    private middlewareService: MiddlewareService,
    private kycDataService: KYCDataService,
    public changeDetectorRefs: ChangeDetectorRef,
    private messageService: MessageService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  get transfersInOtherCountries() {
    return this.formActivity.get('checkIntTransfer');
  }

  ngOnInit() {
    this.loadData();
    this.loadConfigSelects();
    this.mainSourceIncomesList = [
      { name: 'SALARIO' },
      { name: 'RENTAS' },
      { name: 'PRACTICA PROFESIONAL' },
      {
        name: 'FIDEICOMISOS O RENDIMIENTOS/DIVIDENDOS DE INVERSIÓN O ACCIONES'
      },
      { name: 'NEGOCIO PROPIO' },
      { name: 'OTRO' }
    ];
    this.initForm();
    this.translate.addLangs(['es']);
  }

  loadConfigSelects() {}

  initForm() {
    this.formActivity = this.formBuilder.group({
      annualIncome: [
        0,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(this.MAX_QUANTITY),
          Validators.pattern('^-?\\d+(\\.\\d{1,4})?$')
        ]
      ],
      mainSourceIncome: [[], Validators.required],
      annualSalaryIncome: [
        '',
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(/^\d+(\.\d{1,2})?$/),
          Validators.max(this.MAX_QUANTITY)
        ]
      ],
      annualRentalIncome: [
        '',
        [
          Validators.required,
          Validators.min(0),
          Validators.max(this.MAX_QUANTITY),
          Validators.pattern(/^\d+(\.\d{1,2})?$/)
        ]
      ],
      annualInvestmentIncome: [
        '',
        [
          Validators.required,
          Validators.min(0),
          Validators.max(this.MAX_QUANTITY),
          Validators.pattern(/^\d+(\.\d{1,2})?$/)
        ]
      ],
      otherAnnualIncome: [
        '',
        [
          Validators.required,
          Validators.min(0),
          Validators.max(this.MAX_QUANTITY),
          Validators.pattern(/^\d+(\.\d{1,2})?$/)
        ]
      ],
      strOtherIncomeDetails: [
        '',
        [Validators.required, Validators.maxLength(this.MAX_LENGTH_TEXT_AREA)]
      ],
      heritageDetails: [
        '',
        [Validators.required, Validators.maxLength(this.MAX_LENGTH_TEXT_AREA)]
      ],
      executiveAccount: false,
      anotherExecutive: [''],
      executive: ['']
    });
    this.subscribeFormChanges();
  }

  get annualIncome() {
    return this.formActivity.get('annualIncome');
  }
  get mainSourceIncome() {
    return this.formActivity.get('mainSourceIncome');
  }
  get annualSalaryIncome() {
    return this.formActivity.get('annualSalaryIncome');
  }
  get annualRentalIncome() {
    return this.formActivity.get('annualRentalIncome');
  }

  get annualInvestmentIncome() {
    return this.formActivity.get('annualInvestmentIncome');
  }
  get otherAnnualIncome() {
    return this.formActivity.get('otherAnnualIncome');
  }
  get strOtherIncomeDetails() {
    return this.formActivity.get('strOtherIncomeDetails');
  }
  get heritageDetails() {
    return this.formActivity.get('heritageDetails');
  }
  get amountLimit() {
    return this.formActivity.get('amountLimit');
  }
  get taxResidence() {
    return this.formActivity.get('taxResidence');
  }

  get anotherReceptionReason() {
    return this.formActivity.get('anotherReceptionReason');
  }

  get executive() {
    return this.formActivity.get('executive');
  }

  get anotherExecutive() {
    return this.formActivity.get('anotherExecutive');
  }

  private subscribeFormChanges() {
    const propertiesToWatch = [
      this.annualSalaryIncome,
      this.annualRentalIncome,
      this.annualInvestmentIncome,
      this.otherAnnualIncome
    ];
    if (this.isPanama) {
      propertiesToWatch.push(this.amountLimit);
    }

    propertiesToWatch.forEach((property) => {
      this.subscription.add(
        property.valueChanges.subscribe((value) => {
          if (value) {
            this.calculateAnnualIncome();
          }
        })
      );
    });
  }

  filterSectorProfessional(event) {
    this.filteredSectorProfessional = filterList(
      this.listSectorProfessional,
      event,
      'label'
    );
  }

  getAction() {
    return ACTION.saveKYC;
  }

  sendForm() {
    if (this.formActivity.valid) {
      this.onNextStage(this.nextStage);
    } else {
      this.formActivity.markAllAsTouched();
    }
  }
  sendForm1() {
    let array = this.formActivity.value.mainSourceIncome;
    array.forEach((i: any) => {});
    if (array.length > 4) {
      this.addSingle();
    } else {
      let radio = this.formActivity.value.executiveAccount;
      if (radio == true) {
        if (this.formActivity.valid) {
          this.onNextStage(this.nextStage);
        } else {
          this.formActivity.markAllAsTouched();
        }
      } else {
        if (this.formActivity.valid) {
          this.onNextStage(this.nextStage);
        } else {
          this.formActivity.markAllAsTouched();
        }
      }
    }
  }

  addSingle() {
    this.messageService.add({
      severity: 'warn',
      summary: 'Alerta',
      detail: 'No se seleccionar mas de 4 fuentes principales de ingresos'
    });
  }

  loadData() {
    this.middlewareService.getUsersLookup().subscribe((data) => {
      const mainSourceRevenueArray = data.mainSourceRevenue?.split(';');

      if (mainSourceRevenueArray) {
        for (let i = 0; i < mainSourceRevenueArray.length; i++) {
          if (mainSourceRevenueArray[i] === 'HERENCIA') {
            mainSourceRevenueArray[i] =
              'FIDEICOMISOS O RENDIMIENTOS/DIVIDENDOS DE INVERSIÓN O ACCIONES';
          }
        }
      }

      const mainSourceRevenueObj = mainSourceRevenueArray?.map((key) => ({
        name: key
      }));

      this.formActivity.patchValue({
        annualIncome: data.annualRevenue,
        mainSourceIncome: mainSourceRevenueObj,
        annualSalaryIncome: data.annualSalary,
        annualRentalIncome: data.annualRent,
        annualInvestmentIncome: data.annualInvestments,
        otherAnnualIncome: data.otherAnnualRevenue,
        strOtherIncomeDetails: data.detailOtherRevenue,
        heritageDetails: data.detailAssets
      });

      if (data.targetCountry === 'L_PANBM') {
        this.isPanama = true;
        this.validateTypeAccount(data);
      }

      return (this.kycData = data);
    });
  }

  validateTypeAccount(data: any) {
    if (this.isPanama) {
      this.typeDeposit = [
        { id: 'Transferencia', name: 'onboarding_title_transnfer' }
      ];
      this.receptionPurposeSendReasonList = [
        { id: 'Salarios', name: 'onboarding_title_wages' },
        {
          id: 'Transferencias Familiares',
          name: 'onboarding_title_familial_transfers'
        },
        {
          id: 'Transferencias entre Cuentas',
          name: 'onboarding_title_account_transfers'
        },
        { id: 'Otros', name: 'onboarding_title_others' }
      ];

      this.formActivity.addControl(
        'typeOfDeposit',
        new FormControl(data?.typeOfDeposit ?? '', Validators.required)
      );
      this.formActivity.addControl(
        'receptionReason',
        new FormControl(data?.receptionReason ?? '', Validators.required)
      );
      this.formActivity.addControl(
        'anotherReceptionReason',
        new FormControl(data?.anotherReceptionReason ?? '', [])
      );
      this.formActivity.addControl(
        'purposeSendTrans',
        new FormControl(data?.purposeSendTrans ?? '', Validators.required)
      );
      this.formActivity.addControl(
        'anotherPurposeSendTrans',
        new FormControl(data?.anotherPurposeSendTrans ?? '', [])
      );
      this.formActivity.addControl(
        'amountLimit',
        new FormControl(data?.amountLimit ?? '', [
          Validators.required,
          Validators.min(1),
          Validators.max(this.MAX_QUANTITY_AMOUNTH_MAX),
          Validators.pattern(/^\d+(\.\d{1,2})?$/)
        ])
      );
      this.formActivity.addControl(
        'taxResidence',
        new FormControl(data?.taxResidence ?? '', [
          Validators.required,
          Validators.maxLength(this.MAX_LENGTH_TAX_RESIDENCE)
        ])
      );
      this.formActivity.addControl(
        'executive',
        new FormControl(data?.executive ?? Validators.required)
      );
      this.formActivity.addControl(
        'anotherExecutive',
        new FormControl('', Validators.required)
      );
    }
  }

  onReasonChanged() {
    if (this.formActivity.value.receptionReason === 'Otros') {
      this.isPurposeReason = true;
      const receptionReason = this.formActivity.get('anotherReceptionReason');
      receptionReason.setValidators([
        Validators.required,
        Validators.maxLength(this.MAX_LENGTH_TEXT_AREA)
      ]);
      receptionReason.updateValueAndValidity();
    } else {
      this.isPurposeReason = false;
      this.removeValidators('anotherReceptionReason');
    }
  }

  onSendPurposeChanged() {
    if (this.formActivity.value.purposeSendTrans === 'Otros') {
      this.isPurposeSend = true;
      const receptionReason = this.formActivity.get('anotherPurposeSendTrans');
      receptionReason.setValidators([
        Validators.required,
        Validators.maxLength(this.MAX_LENGTH_TEXT_AREA)
      ]);
      receptionReason.updateValueAndValidity();
    } else {
      this.isPurposeSend = false;
      this.removeValidators('anotherPurposeSendTrans');
    }
  }

  removeValidators(controlName: string) {
    const control = this.formActivity.get(controlName);
    if (control && control.validator) {
      control.clearValidators();
      control.updateValueAndValidity();
    }
  }

  calculateAnnualIncome() {
    const annualSalaryIncomeValue =
      this.formActivity.get('annualSalaryIncome').value || 0;
    const annualRentalIncomeValue =
      this.formActivity.get('annualRentalIncome').value || 0;
    const annualInvestmentIncomeValue =
      this.formActivity.get('annualInvestmentIncome').value || 0;
    const otherAnnualIncomeValue =
      this.formActivity.get('otherAnnualIncome').value || 0;
    const amountLimit = this.isPanama
      ? this.formActivity.get('amountLimit').value || 0
      : 0;

    this.totalAnnualIncome =
      annualSalaryIncomeValue +
      annualRentalIncomeValue +
      annualInvestmentIncomeValue +
      otherAnnualIncomeValue +
      amountLimit;
    this.formActivity.get('annualIncome').setValue(this.totalAnnualIncome);
  }

  private getKYCData(): KYCData {
    const form = this.formActivity.value;
    this.formActivity.value.mainSourceIncome.forEach((income) => {
      if (
        income.name ===
        'FIDEICOMISOS O RENDIMIENTOS/DIVIDENDOS DE INVERSIÓN O ACCIONES'
      ) {
        income.name = 'HERENCIA';
      }
    });

    const mainSourceIncomeString = this.formActivity.value.mainSourceIncome
      .map((obj) => obj.name)
      .join(';');

    return {
      occupation: this.kycData.occupation,
      position: this.kycData.position,
      companyJob: this.kycData.companyJob,
      economicActivity: this.kycData.economicActivity,
      companyYears: this.kycData.companyYears,
      officeAddress: this.kycData.officeAddress,
      annualRevenue: form.annualIncome,
      mainSourceRevenue: mainSourceIncomeString,
      annualSalary: form.annualSalaryIncome,
      annualRent: form.annualRentalIncome,
      annualInvestments: form.annualInvestmentIncome,
      otherAnnualRevenue: form.otherAnnualIncome,
      detailOtherRevenue: form.strOtherIncomeDetails,
      detailAssets: form.heritageDetails,
      accountPurpose: this.kycData.accountPurpose,
      incomingTransfersMonth: this.kycData.incomingTransfersMonth,
      balanceIncomingTransfersMonth: this.kycData.balanceIncomingTransfersMonth,
      outcomingTransfersMonth: this.kycData.outcomingTransfersMonth,
      balanceOutcomingTransfersMonth:
        this.kycData.balanceOutcomingTransfersMonth,
      expectedInitialDeposit: this.kycData.expectedInitialDeposit,
      sourceWealth: mainSourceIncomeString,
      startDate: this.kycData.startDate,
      typeOfDeposit: form?.typeOfDeposit ?? '',
      amountLimit: form?.amountLimit ?? '',
      taxResidence: form?.taxResidence ?? '',
      receptionReason: form?.receptionReason ?? '',
      anotherReceptionReason: form?.anotherReceptionReason ?? '',
      purposeSendTrans: form?.purposeSendTrans ?? '',
      anotherPurposeSendTrans: form?.anotherPurposeSendTrans ?? '',
      transfAchTxCred: this.kycData.transfAchTxCred,
      transfAchAmountCred: this.kycData.transfAchAmountCred,
      transfAchTxDeb: this.kycData.transfAchTxDeb,
      transfAchAmountDeb: this.kycData.transfAchAmountDeb,
      transfInterTxCred: this.kycData.transfInterTxCred,
      transfInterAmountCred: this.kycData.transfInterAmountCred,
      transfInterTxDeb: this.kycData.transfInterTxDeb,
      transfInterAmountDeb: this.kycData.transfInterAmountDeb,
      transfBppTxCred: this.kycData.transfBppTxCred,
      transfBppAmountCred: this.kycData.transfBppAmountCred,
      transfBppTxDeb: this.kycData.transfBppTxDeb,
      transfBppAmountDeb: this.kycData.transfBppAmountDeb,
      totalTxCred: this.kycData.totalTxCred,
      totalAmountCred: this.kycData.totalAmountCred,
      totalTxDeb: this.kycData.totalTxDeb,
      totalAmountDeb: this.kycData.totalAmountDeb,
      accountDetail: this.kycData.accountDetail,
      step: '2',
      profession: this.kycData.profession
    };
  }
  private getKYCData1(): KYCData {
    const form = this.formActivity.value;

    if (form.executive == 'false') {
      this.stringExecutive = null;
    } else {
      this.stringExecutive = form.executive;
    }

    if (this.stringExecutive == null) {
      this.otherExecutive = form.anotherExecutive;
    } else {
      this.otherExecutive = null;
    }

    this.formActivity.value.mainSourceIncome.forEach((income) => {
      if (
        income.name ===
        'FIDEICOMISOS O RENDIMIENTOS/DIVIDENDOS DE INVERSIÓN O ACCIONES'
      ) {
        income.name = 'HERENCIA';
      }
    });

    const mainSourceIncomeString = this.formActivity.value.mainSourceIncome
      .map((obj) => obj.name)
      .join(';');
    return {
      occupation: this.kycData.occupation,
      position: this.kycData.position,
      companyJob: this.kycData.companyJob,
      economicActivity: this.kycData.economicActivity,
      companyYears: this.kycData.companyYears,
      officeAddress: this.kycData.officeAddress,
      annualRevenue: form.annualIncome,
      mainSourceRevenue: mainSourceIncomeString,
      annualSalary: form.annualSalaryIncome,
      annualRent: form.annualRentalIncome,
      annualInvestments: form.annualInvestmentIncome,
      otherAnnualRevenue: form.otherAnnualIncome,
      detailOtherRevenue: form.strOtherIncomeDetails,
      detailAssets: form.heritageDetails,
      accountPurpose: this.kycData.accountPurpose,
      incomingTransfersMonth: this.kycData.incomingTransfersMonth,
      balanceIncomingTransfersMonth: this.kycData.balanceIncomingTransfersMonth,
      outcomingTransfersMonth: this.kycData.outcomingTransfersMonth,
      balanceOutcomingTransfersMonth:
        this.kycData.balanceOutcomingTransfersMonth,
      expectedInitialDeposit: this.kycData.expectedInitialDeposit,
      sourceWealth: mainSourceIncomeString,
      startDate: this.kycData.startDate,
      typeOfDeposit: form?.typeOfDeposit ?? '',
      amountLimit: form?.amountLimit ?? '',
      taxResidence: form?.taxResidence ?? '',
      receptionReason: form?.receptionReason ?? '',
      anotherReceptionReason: form?.anotherReceptionReason ?? '',
      purposeSendTrans: form?.purposeSendTrans ?? '',
      anotherPurposeSendTrans: form?.anotherPurposeSendTrans ?? '',
      transfAchTxCred: this.kycData.transfAchTxCred,
      transfAchAmountCred: this.kycData.transfAchAmountCred,
      transfAchTxDeb: this.kycData.transfAchTxDeb,
      transfAchAmountDeb: this.kycData.transfAchAmountDeb,
      transfInterTxCred: this.kycData.transfInterTxCred,
      transfInterAmountCred: this.kycData.transfInterAmountCred,
      transfInterTxDeb: this.kycData.transfInterTxDeb,
      transfInterAmountDeb: this.kycData.transfInterAmountDeb,
      transfBppTxCred: this.kycData.transfBppTxCred,
      transfBppAmountCred: this.kycData.transfBppAmountCred,
      transfBppTxDeb: this.kycData.transfBppTxDeb,
      transfBppAmountDeb: this.kycData.transfBppAmountDeb,
      totalTxCred: this.kycData.totalTxCred,
      totalAmountCred: this.kycData.totalAmountCred,
      totalTxDeb: this.kycData.totalTxDeb,
      totalAmountDeb: this.kycData.totalAmountDeb,
      accountDetail: this.kycData.accountDetail,
      step: '2',
      profession: this.kycData.profession,
      executive: this.stringExecutive ?? null,
      anotherExecutive: this.otherExecutive ?? null
    };
  }

  onNextStage(stage: string) {
    if (this.accountReference == true) {
      if (this.other) {
        this.formActivity.get('executive').setValidators(null);
        this.formActivity.patchValue({ executive: null });
        this.formActivity.controls['anotherExecutive'].setValidators([
          Validators.required
        ]);
      } else {
        this.formActivity.get('anotherExecutive').setValidators(null);
        this.formActivity.controls['executive'].setValidators([
          Validators.required
        ]);
      }

      if (this.formActivity.valid) {
        this.middlewareService.submitKYCData(this.getKYCData1()).subscribe(
          (data) => {
            this.routerC.navigate(['onboarding', stage]);
          },
          (error) => {
            this.setErrorMessages(error.responseCode);
          }
        );
      }
    } else {
      if (this.formActivity.valid) {
        this.middlewareService.submitKYCData(this.getKYCData()).subscribe(
          (data) => {
            this.routerC.navigate(['onboarding', stage]);
          },
          (error) => {
            this.setErrorMessages(error.responseCode);
          }
        );
      }
    }
  }

  getExecutives() {
    this.middlewareService.getExecutives().subscribe((data: any) => {
      this.executiveList = data;
      this.executiveList.push({
        strName: 'Otro',
        strId: 'otro',
        numExecutiveCode: 0
      });
    });
  }

  onBackStage(prevStage: string) {
    if (this.isMultTitulares) {
      if (this.isSecondHolder) {
        this.commonDataService.setIsSecondHolder(false);
        this.ngOnInit();
      } else {
        this.commonDataService.setIsSecondHolder(true);
        this.routerC.navigate(['onboarding', prevStage]);
      }
    } else {
      this.routerC.navigate(['onboarding', prevStage]);
    }
  }

  private sendGTM() {
    const form = this.formActivity.value;
    this.gtmService.sendVariableGTM({
      pageType: 'Actividad Economica',
      ocupacion: form.currentPosition?.name,
      cargoActual: form.employSituation?.name,
      propositoCuenta: form.accountPurpose,
      activos: form.numAssets,
      pasivos: form.numLiabilities,
      ingresosMensuales: form.numMonthlyIncome,
      gastosMensuales: form.numMonthlyExpenses,
      otrosIngresos: form.numOtherIncome
    });

    this.gtmService.trackEvent('onboarding', 'paso 9', 'Actividad Economica');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClick() {
    this.accountReference = this.formActivity.value.executiveAccount;
  }
  selectExec() {
    if (this.formActivity.value.executive == 'otro') {
      this.other = true;
    } else {
      this.other = false;
    }
  }
}
