import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import { environment } from '../../../../environments/environment';

import { ENDPOINTS } from '../../../shared/constants/endpoints';
import { NewOpportunity } from '../../../shared/model/api/new-oppotunity.model';
import { ParamsModel } from '../../../shared/model/api/params.model';
import { EconomicActivityModel } from '../../../shared/model/economic-activity.model';
import { CustomHttpParams } from '../../../vass-core/services/custom-http-params.class';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  constructor(private readonly http: HttpClient) {}

  generateOtp(body: NewOpportunity): Observable<{ otpProcessId: string }> {
    const url = `${environment.apiPath}${ENDPOINTS.opportunities}`;
    return this.http.post<{ otpProcessId: string }>(url, body, {
      params: new CustomHttpParams(true)
    });
  }

  getCurrentPositions(): Observable<Array<ParamsModel>> {
    return of([
      { name: 'ASALARIADO', id: 1 },
      { name: 'INDEPENDIENTE', id: 2 },
      { name: 'JUBILADO', id: 3 }
    ]);
  }

  getEmploySituations(): Observable<Array<ParamsModel>> {
    return of([
      { name: 'Asistencial', id: 1 },
      { name: 'Analista', id: 2 },
      { name: 'Analista junior', id: 3 },
      { name: 'Analista senior', id: 4 },
      { name: 'Responsable o coordinador', id: 5 },
      { name: 'Gerente o Director', id: 6 },
      { name: 'CEO', id: 7 },
      { name: 'Otro cargo', id: 8 }
    ]);
  }

  getAccountPurposes(): Observable<Array<ParamsModel>> {
    return of([
      // { name: 'Gestión del día a día', id: 1 },
      { name: 'Ahorro', id: 2 }
      // { name: 'Obtener financiación', id: 3 },
      // { name: 'Inversión', id: 4 }
    ]);
  }

  getEconomicActivity(): Observable<Array<EconomicActivityModel>> {
    return of([
      {
        name: 'Persona natural con actividad comercial',
        code: '1',
        label: 'Persona natural con actividad comercial'
      },
      { name: 'Otras', code: '2', label: 'Otras' },
      { name: 'Rentistas', code: '3', label: 'Rentistas' },
      {
        name: 'Profesional independiente',
        code: '4',
        label: 'Profesional independiente'
      },
      { name: 'Transportador', code: '5', label: 'Transportador' }
    ]);
  }

  getProfessionalSector(): Observable<Array<EconomicActivityModel>> {
    return this.http
      .get<Array<EconomicActivityModel>>(
        '../../../../assets/onboarding.datosProfSector.json'
      )
      .pipe(map((data) => data.map((item) => ({ ...item, label: item.name }))));
  }

  getProfessionalSector1(): Observable<Array<EconomicActivityModel>> {
    return this.http
      .get<Array<EconomicActivityModel>>(
        '../../../../assets/onboarding.datosProfSector1.json'
      )
      .pipe(map((data) => data.map((item) => ({ ...item, label: item.name }))));
  }

  getProfession(): Observable<Array<EconomicActivityModel>> {
    return this.http
      .get<Array<EconomicActivityModel>>(
        '../../../../assets/onbooarding.profesion.json'
      )
      .pipe(map((data) => data.map((item) => ({ ...item, label: item.name }))));
  }
}
