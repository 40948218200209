import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pichincha-container',
  templateUrl: './pichincha-container.component.html',
  styleUrls: ['./pichincha-container.component.scss']
})
export class PichinchaContainerComponent implements OnInit {
  @Input()
  public stage: string = 'welcome';

  constructor() {}

  ngOnInit() {}

  changeStage(newStage) {
    this.stage = newStage;
  }
}
