import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray
} from '@angular/forms';
import { Router } from '@angular/router';

import { PichBaseComponent } from '../pichincha-base.component';

import { CommonDataService } from '../../../shared/services/common-data.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { GtmService } from './../../../shared/services/gtm/gtm.service';

import { ACTION, PATHS } from './../../../shared/constants';
import {
  PersonalBeneficModel,
  PersonalInfoModel
} from './../../model/params.model';

import { MiddlewareService } from '../../../shared/services/middleware.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { PersonalDataService } from '../../services/personal.data.service';
import { OptionsType } from '../../../shared/model/option-type.model';
import { CountryModel } from '../../../shared/model/api/country.model';
import { ToolboxService } from '../../../shared/services/toolbox/toolbox.service';
import { ParamsModel } from '../../../shared/model/api/params.model';
import { OnboardingService } from '../../services/onboarding/onboarding.service';
import {} from '../../../../assets/i18n/strings-es.json';

@Component({
  selector: 'app-pich-personal-data-1',
  templateUrl: './pich-personal-data-1.component.html',
  styleUrls: ['./pich-personal-data-1.component.scss'],
  providers: [ToolboxService]
})
export class PichPersonalData1Component
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  private nextStage = PATHS.personalData2;
  public prevStage = PATHS.identityManualConfirm;
  personalData: PersonalInfoModel;
  isSecondHolder = false;
  isMultTitulares = false;
  isPanama = false;
  isTest = false;

  formData: FormGroup;
  formDataBenefic: FormGroup;

  genderObjList: OptionsType[] = [];
  maritalStatusObjList: OptionsType[] = [];
  maritalStatusObj: OptionsType;
  genderObj: OptionsType;

  HTML_VALIDATORS = {
    minLength: 2,
    maxLength: {
      name: 50,
      firstLastName: 50,
      secondLastName: 50,
      fullAddress: 200,
      addressBen: 200,
      cityOfResidence: 80
    }
  };

  maxDate = new Date(
    new Date().getFullYear() - 18,
    new Date().getMonth(),
    new Date().getDate()
  );
  minDate = new Date(`01/01/${new Date().getFullYear() - 100}`);
  selectedValue: string;
  benefic: boolean = false;
  createBeneficiaryTable: PersonalBeneficModel[] = [];
  countries: CountryModel[] = [];
  currentPositionList: ParamsModel[] = [];
  requiresBen: boolean;
  checkRadio: boolean = false;
  lookBenefic: boolean;
  beneficiarios: FormGroup[] = [];
  agreeBenefic: PersonalBeneficModel[] = [];
  agregarBen: FormArray[];
  mensaje: string =
    'Puede nombrar una o varias personas como beneficiarios. En caso de fallecer todos los titulares, los fondos en esta cuenta personal serán pagados a los beneficiarios designados en esta sección';
  gender: any;
  maritalStatus: any;
  dataRequiresBen: boolean;
  objectBeneficiary: any;
  country: string;
  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private formbuilder: FormBuilder,
    public commonDataService: CommonDataService,
    private gtmService: GtmService,
    private middlewareService: MiddlewareService,
    protected confirmationService: ConfirmationService,
    private personalDataService: PersonalDataService,
    private readonly toolboxService: ToolboxService,
    private readonly onboardingService: OnboardingService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.loadData();
    this.createForm();
    this.loadDropDownData();
    this.canNavigateToBack(false);
    this.isSecondHolder = this.commonDataService.isSecondHolder;
    this.isMultTitulares = this.commonDataService.isMultTitulares;
    this.onboardingService.getCurrentPositions().subscribe((data) => {
      this.currentPositionList = data;
    });
    this.mensaje;
  }

  loadDropDownData() {
    this.toolboxService.getCountries().subscribe(
      (data) => {
        this.countries = data;
        /* this.loadData(); */
      },
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  ngOnDestroy() {
    this.canNavigateToBack(true);
  }

  private createForm() {
    this.formData = this.formbuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.name)
        ]
      ],
      firstLastName: [
        '',
        [
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.firstLastName)
        ]
      ],
      secondLastName: [
        '',
        [
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.secondLastName)
        ]
      ],
      birthDate: ['', [Validators.required, this.dateValidator]],
      cityOfResidence: [
        '',
        [
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.cityOfResidence)
        ]
      ],
      fullAddress: [
        '',
        [
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.fullAddress)
        ]
      ],
      countryOfResidence: this.country,
      createBeneficiaryTable: [],
      fullNameBen: [''],
      birthdateBen: [''],
      addressBen: [''],
      countryNationalityBen: [''],
      countryBirthBen: [''],
      phoneNumberBen: [''],
      occupationBen: [''],
      relationshipBen: [''],
      requiresBen: false
    });
  }

  private createFormBenefic() {
    this.formDataBenefic = this.formbuilder.group({
      fullNameBen: ['', []],
      birthdateBen: ['', ['', [Validators.required, this.dateValidator]]],
      addressBen: [
        '',
        [
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.fullAddress)
        ]
      ],
      countryNationalityBen: [''],
      countryBirthBen: [''],
      phoneNumberBen: [
        '',
        [
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength)
        ]
      ],
      occupationBen: ['', ['']],
      relationshipBen: ['', Validators.required]
    });
  }

  dateValidator(control: any) {
    if (control.value) {
      const regexDate = /^\d{4}-\d{2}-\d{2}$/;

      if (!regexDate.test(control.value)) {
        return { invalidDate: true };
      }
    }

    const [year, month, day] = control.value.split('-').map(Number);
    const date = new Date(year, month - 1, day);

    if (
      date.getFullYear() === year &&
      date.getMonth() + 1 === month &&
      date.getDate() === day
    ) {
      return null;
    } else {
      return { invalidDate: true };
    }
  }
  // confirmDate(control: AbstractControl) {
  //   console.log(control);
  //   this.formData.get('birthDate').markAllAsTouched();
  // }

  get control() {
    return this.formData.controls;
  }

  public getAction(): string {
    return ACTION.savePersonalData;
  }

  loadData() {
    this.middlewareService.getUsersLookup().subscribe((data) => {
      this.dataRequiresBen = data?.requiresBen;
      this.country = data.sourceCountry;

      if (this.dataRequiresBen == true) {
        this.onClick();
        let arrayBenefic = data?.createBeneficiaryTable;
        arrayBenefic.forEach((i: any) => {
          this.objectBeneficiary = i;
          if (i.idBen) {
            this.beneficiarios.push(
              this.formbuilder.group({
                fullNameBen: i.fullNameBen,
                birthdateBen: i.birthdateBen,
                addressBen: i.addressBen,
                countryNationalityBen: i.countryNationalityBen,
                countryBirthBen: i.countryBirthBen,
                phoneNumberBen: i.phoneNumberBen,
                occupationBen: i.occupationBen,
                relationshipBen: i.relationshipBen,
                idBen: i.idBen
              })
            );
          }
        });
      }
      if (data.residentialAddress && data.residentialAddress.length > 0) {
        this.formData.patchValue({
          name: data.firstName,
          firstLastName: data.lastName,
          secondLastName: data.secondLastName,
          birthDate: data.birthDate,
          cityOfResidence: data.cityOfResidence,
          fullAddress: data.residentialAddress,
          countryOfResidence: data.sourceCountry,
          fullNameBen: data?.fullNameBen,
          addressBen: data?.addressBen,
          birthdateBen: '',
          countryNationalityBen: data?.countryNationalityBen,
          countryBirthBen: data?.countryBirthBen,
          phoneNumberBen: data?.phoneNumberBen,
          occupationBen: data?.occupationBen,
          relationshipBen: data?.relationshipBen,
          requiresBen: data?.requiresBen,
          createBeneficiaryTable: data?.createBeneficiaryTable
        });
      }

      this.validateUserTypeAccount(data);
      return (this.personalData = data);
    });
    this.pichCService.getDocumentsStatus().subscribe(
      (data) => {},
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
  }

  validateUserTypeAccount(data: any) {
    if (
      data?.targetCountry !== undefined &&
      data?.targetCountry === 'L_PANBM'
    ) {
      this.genderObjList = [
        { id: 'Masculino', name: 'onboarding_gender_male' },
        { id: 'Femenino', name: 'onboarding_gender_female' }
      ];
      this.maritalStatusObjList = [
        { id: 'Casado', name: 'onboarding_marital_status_married' },
        { id: 'Soltero', name: 'onboarding_marital_status_single' },
        { id: 'Viudo', name: 'onboarding_marital_status_widowed' },
        { id: 'Divorciado', name: 'onboarding_marital_status_divorced' },
        { id: 'Unido', name: 'onboarding_marital_status_united' }
      ];

      this.formData.addControl(
        'gender',
        new FormControl(data?.gender ?? '', Validators.required)
      );
      this.formData.addControl(
        'maritalStatus',
        new FormControl(data?.maritalStatus ?? '', Validators.required)
      );
      this.isPanama = true;
      this.requiresBen = false;
      this.benefic = false;
    } else {
      this.benefic = true;
      this.gender = ' ';
      this.maritalStatus = ' ';
    }
  }

  onBackStage(prevStage: string) {
    this.routerC.navigate(['onboarding', prevStage]);
  }

  private getPersonalInfo(): PersonalInfoModel {
    return {
      firstName: this.formData.value.name,
      middleName: ' ',
      lastName: this.formData.value.firstLastName,
      secondLastName: this.formData.value.secondLastName,
      birthDate: this.formData.value.birthDate,
      cityOfResidence: this.formData.value.cityOfResidence,
      countryOfResidence: this.country,
      residentialAddress: this.formData.value.fullAddress,
      countryOfBirth: this.personalData.countryOfBirth,
      nationality: this.personalData.nationality,
      passportNumber: this.personalData.passportNumber,
      expiryDate: this.personalData.expiryDate,
      countryOfExpedition: this.personalData.countryOfExpedition,
      documentType: 'PASAPORTE',
      documentNumber: this.personalData.documentNumber,
      taxDocumentNumber: this.personalData.taxDocumentNumber,
      gender: this.formData.value?.gender,
      maritalStatus: this.formData.value?.maritalStatus,
      oriDesTable: this.personalData?.oriDesTable,
      step: '1',
      createBeneficiaryTable: this.createBeneficiaryTable,
      requiresBen: this.formData.value.requiresBen
    };
  }

  private getPersonalInfo1(): PersonalInfoModel {
    return {
      firstName: this.formData.value.name,
      middleName: ' ',
      lastName: this.formData.value.firstLastName,
      secondLastName: this.formData.value.secondLastName,
      birthDate: this.formData.value.birthDate,
      cityOfResidence: this.formData.value.cityOfResidence,
      countryOfResidence: this.country,
      residentialAddress: this.formData.value.fullAddress,
      countryOfBirth: this.personalData.countryOfBirth,
      nationality: this.personalData.nationality,
      passportNumber: this.personalData.passportNumber,
      expiryDate: this.personalData.expiryDate,
      countryOfExpedition: this.personalData.countryOfExpedition,
      documentType: 'PASAPORTE',
      documentNumber: this.personalData.documentNumber,
      taxDocumentNumber: this.personalData.taxDocumentNumber,
      gender: this.formData.value?.gender,
      maritalStatus: this.formData.value?.maritalStatus,
      oriDesTable: this.personalData?.oriDesTable,
      step: '1',
      requiresBen: this.formData.value.requiresBen
    };
  }

  onNext() {
    this.requiresBen = this.formData.value.requiresBen;

    if (this.formData.valid) {
      if (this.requiresBen == false) {
        this.middlewareService
          .setPersonalInfo(this.getPersonalInfo1())
          .subscribe(
            (data) => {
              this.personalDataService.setPersonalData(this.getPersonalInfo1());
              this.onNextStage(this.nextStage);
            },
            (error) => {
              this.setErrorMessages(error.responseCode);
            }
          );
      } else {
        this.assignedBeneficiary();
        this.middlewareService
          .setPersonalInfo(this.getPersonalInfo())
          .subscribe(
            (data) => {
              this.personalDataService.setPersonalData(this.getPersonalInfo());
              this.onNextStage(this.nextStage);
            },
            (error) => {
              this.setErrorMessages(error.responseCode);
            }
          );
      }
    } else {
      this.formData.markAllAsTouched();
    }
  }
  onClick() {
    if (this.dataRequiresBen) {
      this.lookBenefic = true;
    } else {
      this.checkRadio = this.formData.value.requiresBen;
      if (this.checkRadio === true) {
        this.lookBenefic = true;
        if (this.beneficiarios.length == 0) {
          this.agregarBeneficiarios();
        }
      } else {
        this.lookBenefic = false;
        this.beneficiarios = [];
        this.onValidator();
      }
    }
  }

  onValidator() {
    this.checkRadio = this.formData.value.requiresBen;
    if (this.checkRadio === true) {
      this.formData.controls['fullNameBen'].setValidators([
        Validators.required,
        Validators.minLength(this.HTML_VALIDATORS.minLength),
        Validators.maxLength(this.HTML_VALIDATORS.maxLength.name)
      ]);
      this.formData.controls['birthdateBen'].setValidators([
        Validators.required,
        this.dateValidator
      ]);

      this.formData.controls['addressBen'].setValidators([
        Validators.required,
        Validators.minLength(this.HTML_VALIDATORS.minLength),
        Validators.maxLength(this.HTML_VALIDATORS.maxLength.fullAddress)
      ]);

      this.formData.controls['countryNationalityBen'].setValidators([
        Validators.required
      ]);

      this.formData.controls['countryBirthBen'].setValidators([
        Validators.required
      ]);

      this.formData.controls['phoneNumberBen'].setValidators([
        Validators.required,
        Validators.minLength(this.HTML_VALIDATORS.minLength)
      ]);

      this.formData.controls['occupationBen'].setValidators([
        Validators.required
      ]);

      this.formData.controls['relationshipBen'].setValidators([
        Validators.required
      ]);
    } else {
      let arrayBen = [
        'fullNameBen',
        'birthdateBen',
        'addressBen',
        'countryNationalityBen',
        'countryBirthBen',
        'phoneNumberBen',
        'occupationBen',
        'relationshipBen'
      ];
      arrayBen.forEach((e: string) =>
        this.formData.controls[e].clearValidators()
      );

      this.formData.patchValue({
        fullNameBen: '',
        birthdateBen: '',
        addressBen: '',
        countryNationalityBen: '',
        countryBirthBen: '',
        phoneNumberBen: '',
        occupationBen: '',
        relationshipBen: ''
      });
    }
  }
  agregarBeneficiarios() {
    this.beneficiarios.push(
      this.formbuilder.group({
        fullNameBen: [
          '',
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.name)
        ],
        birthdateBen: ['', Validators.required, this.dateValidator],
        addressBen: [
          '',
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength),
          Validators.maxLength(this.HTML_VALIDATORS.maxLength.fullAddress)
        ],
        countryNationalityBen: ['', Validators.required],
        countryBirthBen: ['', Validators.required],
        phoneNumberBen: [
          '',
          Validators.required,
          Validators.minLength(this.HTML_VALIDATORS.minLength)
        ],
        occupationBen: ['', Validators.required],
        relationshipBen: ['', Validators.required]
      })
    );
  }
  eliminarBenefic(index: any) {}

  validateBeneficiary() {
    this.formData.valid;
    let isValidate = true;
    this.beneficiarios.forEach((i: any) => {
      if (!i.valid) {
        isValidate = false;
        i.markAllAsTouched();
      }
    });
    return isValidate;
  }

  assignedBeneficiary() {
    this.beneficiarios.forEach((i: any) => {
      this.createBeneficiaryTable.push(i.value);
    });
  }
}
