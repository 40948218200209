import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiError } from '../../../../../pichincha-onboarding/model/api/api-error';
import { OTP_ERROR } from '../../../../../shared/constants';
import { GtmService } from '../../../../../shared/services/gtm/gtm.service';

@Component({
  selector: 'app-pich-modal-confirm-signature',
  templateUrl: './pich-modal-confirm-signature.component.html',
  styleUrls: ['./pich-modal-confirm-signature.component.scss']
})
export class PichModalConfirmSignatureComponent implements OnInit {
  isVisible = true;
  errorMessage = '';
  @Input() set error(error: ApiError) {
    if (error) {
      this.handlerErrorOtp(error);
    }
  }
  @Input() phoneNumber = '';

  @Output() onHide = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<string>();
  @Output() onGenerateOtp = new EventEmitter<void>();

  readonly maxLengthCode = 6;
  otpControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(6),
    Validators.minLength(6)
  ]);

  constructor(private gtmService: GtmService) {}

  ngOnInit() {}

  closeModal() {
    this.onHide.emit();
  }

  generateOtp() {
    this.onGenerateOtp.emit();
  }

  signSMS() {
    if (this.otpControl.valid) {
      this.onConfirm.emit(this.otpControl.value);
    } else {
      this.otpControl.markAsTouched();
    }
  }

  clearInput() {
    this.otpControl.setValue('');
  }

  private handlerErrorOtp(error: ApiError) {
    if (error.message === OTP_ERROR.INVALID) {
      this.errorMessage = 'onboarding_incomplete_record_error';
    } else if (error.message === OTP_ERROR.NUM_RETRIES_EXCEEDED) {
      this.errorMessage = 'onboarding_otp_error_num_retries_exceeded';
      this.generateOtp();
    } else {
      setTimeout(() => {
        this.closeModal();
      }, 100);
    }
  }
}
