import { PichWelcomeComponent } from './pichincha-onboarding/components/pich-welcome/pich-welcome.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: PichWelcomeComponent
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./pichincha-onboarding/pichincha-container-module').then(
        (m) => m.PichinchaContainerModule
      )
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      useHash: true,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
