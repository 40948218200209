import { Injectable } from '@angular/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core';

@Injectable()
export class CustomMissingTranslationHandler
  implements MissingTranslationHandler
{
  handle(params: MissingTranslationHandlerParams) {
    if (params.key.startsWith('error_')) {
      return params.translateService.instant('onboarding_error_generic', {
        bogotaPhone: params.translateService.instant('bogota_phone'),
        otherPhone: params.translateService.instant('other_phone')
      });
    } else {
      return params.key;
    }
  }
}
