import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { PATHS } from '../../../shared/constants';
import { ParamsModel } from '../../../shared/model/api/params.model';
import { EconomicActivityModel } from '../../../shared/model/economic-activity.model';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { GtmService } from '../../../shared/services/gtm/gtm.service';
import { MiddlewareService } from '../../../shared/services/middleware.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { KYCData } from '../../model/params.model';
import { KYCDataService } from '../../services/kyc.data.service';
import { OnboardingService } from '../../services/onboarding/onboarding.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { PichBaseComponent } from '../pichincha-base.component';
import { executivesModel } from '../../../shared/model/api/executive.model';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-pich-calculated-transactional-profile',
  templateUrl: './pich-calculated-transactional-profile.component.html',
  styleUrls: ['./pich-calculated-transactional-profile.component.scss'],
  providers: [MessageService]
})
export class PichCalculatedTransactionalProfileComponent
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  [x: string]: any;
  formActivity: FormGroup;
  accountPurpose$: Observable<Array<ParamsModel>>;
  accountPurposeList: ParamsModel[] = [];
  accountPurposeId: string;
  setAccountPurpose: string;

  listSectorProfessional: Array<EconomicActivityModel> = [];
  filteredSectorProfessional = [];
  kycData: KYCData;
  prevStage = PATHS.activity3;
  nextStage = PATHS.signContract;
  transactionalProfileTable: any = [];

  isPanama = false;
  executiveList: executivesModel[] = [];
  accountReference: boolean;
  other: boolean = false;
  executiveData: boolean;
  sendTotal: number;
  messages: Message[] | undefined;
  selectedBank: string;
  viewMovil: boolean = false;
  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    protected confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private commonDataService: CommonDataService,
    private readonly onboardingService: OnboardingService,
    private gtmService: GtmService,
    private middlewareService: MiddlewareService,
    private kycDataService: KYCDataService,
    private messageService: MessageService
  ) {
    super(routerC, pichCService, confirmationService);
    this.transactionalProfileTable = [
      {
        title: 'onboarding_title_transactional_table_online_bank_ach_tranfer',
        transactionReception: 0,
        amountReception: 0,
        sendTransaction: 0,
        amountSend: 0
      },
      {
        title:
          'onboarding_title_transactional_table_international_transfer_by_form',
        transactionReception: 0,
        amountReception: 0,
        sendTransaction: 0,
        amountSend: 0,
        validateMaxLengthTrans: 1,
        valueMaxTrans: 6
      },
      {
        title:
          'onboarding_title_transactional_table_between_bpp_accounts_transfer_by_form',
        transactionReception: 0,
        amountReception: 0,
        sendTransaction: 0,
        amountSend: 0
      },
      {
        title: 'onboarding_title_transactional_table_total_credit_debit',
        transactionReception: 0,
        amountReception: 0,
        sendTransaction: 0,
        amountSend: 0
      }
    ];
    this.mostrarViewMovil();
  }

  ngOnInit(): void {
    this.loadData();
    this.loadConfigSelects();
    this.subscription = this.onboardingService.getAccountPurposes().subscribe(
      (data) => {
        this.accountPurposeList = data;
        this.initForm();
      },
      (error) => {
        this.setErrorMessages(error.responseCode);
      }
    );
    this.getExecutives();
  }

  validarNumero() {
    let numero = this.transactionalProfileTable.find(
      (i: any) =>
        i.title ==
        'onboarding_title_transactional_table_international_transfer_by_form'
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.mostrarViewMovil();
  }

  private mostrarViewMovil(): void {
    if (window.innerWidth <= 420) {
      this.viewMovil = true;
    } else {
      this.viewMovil = false;
    }
  }

  initForm() {
    this.formActivity = this.formBuilder.group({
      accountPurpose: [null, Validators.required],
      executiveAccount: false,
      anotherExecutive: [''],
      executive: ['']
    });
    this.formActivity.markAllAsTouched();
  }

  calculateTotal(): void {
    const filterArray = this.transactionalProfileTable.filter(
      (item) =>
        item.title !== 'onboarding_title_transactional_table_total_credit_debit'
    );
    const filterTotal = this.transactionalProfileTable.find(
      (item) =>
        item.title === 'onboarding_title_transactional_table_total_credit_debit'
    );

    let transactionReception = 0;
    let amountReception = 0;
    let sendTransaction = 0;
    let amountSend = 0;

    for (const obj of filterArray) {
      transactionReception = obj.transactionReception + transactionReception;
      amountReception = obj.amountReception + amountReception;
      sendTransaction = obj.sendTransaction + sendTransaction;
      amountSend = obj.amountSend + amountSend;
    }
    filterTotal.transactionReception = transactionReception;
    filterTotal.amountReception = amountReception;
    filterTotal.sendTransaction = sendTransaction;
    filterTotal.amountSend = amountSend;

    this.transactionalProfileTable = [...filterArray, filterTotal];
    this.formActivity.controls['accountPurpose'].setValue(
      this.transactionalProfileTable
    );
    if (this.selectedBank == 'L_IBM' && filterTotal.sendTransaction > 6) {
      this.validationTransfer();
    }
    this.sendTotal = filterTotal.sendTransaction;
  }

  loadData() {
    this.middlewareService.getUsersLookup().subscribe((data) => {
      this.kycData = data;
      this.selectedBank = this.kycData?.targetCountry;
      if (this.kycData?.executive != null) {
        this.executiveData = true;
        this.formActivity.patchValue({ executiveAccount: true });
        this.onClick();
        this.formActivity.markAllAsTouched();
      } else {
        this.executiveData = false;
      }
      if (this.selectedBank === 'L_IBM') {
        this.transactionalProfileTable = [
          {
            title:
              'onboarding_title_transactional_table_online_bank_ach_tranfer',
            transactionReception: this.kycData.transfAchTxCred ?? 0,
            amountReception: this.kycData.transfAchAmountCred ?? 0,
            sendTransaction: this.kycData.transfAchTxDeb ?? 0,
            amountSend: this.kycData.transfAchAmountDeb ?? 0
          },
          {
            title:
              'onboarding_title_transactional_table_international_transfer_by_form',
            transactionReception: this.kycData.transfInterTxCred ?? 0,
            amountReception: this.kycData.transfInterAmountCred ?? 0,
            sendTransaction: this.kycData.transfInterTxDeb ?? 0,
            amountSend: this.kycData.transfInterAmountDeb ?? 0,
            validateMaxLengthTrans: 1,
            valueMaxTrans: 6
          },
          {
            title:
              'onboarding_title_transactional_table_between_bpp_accounts_transfer_by_form',
            transactionReception: this.kycData.transfBppTxCred ?? 0,
            amountReception: this.kycData.transfBppAmountCred ?? 0,
            sendTransaction: this.kycData.transfBppTxDeb ?? 0,
            amountSend: this.kycData.transfBppAmountDeb ?? 0
          },
          {
            title: 'onboarding_title_transactional_table_total_credit_debit',
            transactionReception: this.kycData.totalTxCred ?? 0,
            amountReception: this.kycData.totalAmountCred ?? 0,
            sendTransaction: this.kycData.totalTxDeb ?? 0,
            amountSend: this.kycData.totalAmountDeb ?? 0
          }
        ];
      }

      if (this.selectedBank === 'L_PANBM' || this.selectedBank === 'L_PBM') {
        this.transactionalProfileTable = [
          {
            title:
              'onboarding_title_transactional_table_online_bank_ach_tranfer',
            transactionReception: this.kycData.transfAchTxCred ?? 0,
            amountReception: this.kycData.transfAchAmountCred ?? 0,
            sendTransaction: this.kycData.transfAchTxDeb ?? 0,
            amountSend: this.kycData.transfAchAmountDeb ?? 0
          },
          {
            title:
              'onboarding_title_transactional_table_international_transfer_by_form',
            transactionReception: this.kycData.transfInterTxCred ?? 0,
            amountReception: this.kycData.transfInterAmountCred ?? 0,
            sendTransaction: this.kycData.transfInterTxDeb ?? 0,
            amountSend: this.kycData.transfInterAmountDeb ?? 0
            /* validateMaxLengthTrans: 1,
          valueMaxTrans: 6 */
          },
          {
            title:
              'onboarding_title_transactional_table_between_bpp_accounts_transfer_by_form',
            transactionReception: this.kycData.transfBppTxCred ?? 0,
            amountReception: this.kycData.transfBppAmountCred ?? 0,
            sendTransaction: this.kycData.transfBppTxDeb ?? 0,
            amountSend: this.kycData.transfBppAmountDeb ?? 0
          },
          {
            title: 'onboarding_title_transactional_table_total_credit_debit',
            transactionReception: this.kycData.totalTxCred ?? 0,
            amountReception: this.kycData.totalAmountCred ?? 0,
            sendTransaction: this.kycData.totalTxDeb ?? 0,
            amountSend: this.kycData.totalAmountDeb ?? 0
          }
        ];
      }
      if (
        data?.targetCountry !== undefined &&
        data?.targetCountry === 'L_PANBM'
      ) {
        this.isPanama = true;
        this.transactionalProfileTable = [
          {
            title:
              'onboarding_title_transactional_table_online_bank_ach_tranfer',
            transactionReception: this.kycData.transfAchTxCred ?? 0,
            amountReception: this.kycData.transfAchAmountCred ?? 0,
            sendTransaction: this.kycData.transfAchTxDeb ?? 0,
            amountSend: this.kycData.transfAchAmountDeb ?? 0
          },
          {
            title:
              'onboarding_title_transactional_table_international_transfer_by_form',
            transactionReception: this.kycData.transfInterTxCred ?? 0,
            amountReception: this.kycData.transfInterAmountCred ?? 0,
            sendTransaction: this.kycData.transfInterTxDeb ?? 0,
            amountSend: this.kycData.transfInterAmountDeb ?? 0
            /* validateMaxLengthTrans: 1,
          valueMaxTrans: 6 */
          },
          {
            title:
              'onboarding_title_transactional_table_between_bpp_accounts_transfer_by_form',
            transactionReception: this.kycData.transfBppTxCred ?? 0,
            amountReception: this.kycData.transfBppAmountCred ?? 0,
            sendTransaction: this.kycData.transfBppTxDeb ?? 0,
            amountSend: this.kycData.transfBppTxDeb ?? 0
          },
          {
            title: 'onboarding_title_transactional_table_total_credit_debit',
            transactionReception: this.kycData.totalTxCred ?? 0,
            amountReception: this.kycData.totalAmountCred ?? 0,
            sendTransaction: this.kycData.totalTxDeb ?? 0,
            amountSend: this.kycData.totalAmountDeb ?? 0
          }
        ];
      }
      this.formActivity.patchValue({ executive: this.kycData.executive });
      this.validateTypeAccount();
    });
  }

  get anotherExecutive() {
    return this.formActivity.get('anotherExecutive');
  }

  loadConfigSelects() {
    this.accountPurpose$ = this.onboardingService
      .getAccountPurposes()
      .pipe(retry(5));

    this.subscription.add(
      this.onboardingService
        .getProfessionalSector()
        .pipe(
          map((list) => {
            return list.map((e) => {
              return {
                name: e.name,
                code: e.code,
                label: `${e.code} - ${e.name}`
              };
            });
          })
        )
        .subscribe(
          (data) => {
            this.listSectorProfessional = data;
          },
          (error) => {
            this.setErrorMessages(error.responseCode);
          }
        )
    );
  }

  sendForm() {
    if (this.sendTotal > 6 && this.selectedBank == 'L_IBM') {
      this.validationTransfer();
    } else {
      if (this.formActivity.valid) {
        this.onNextStage(this.nextStage);
      } else {
        this.formActivity.markAllAsTouched();
      }
    }
    this.formActivity.markAllAsTouched();
  }

  validationTransfer() {
    this.messages = [
      {
        severity: 'warn',
        summary: 'Warning',
        detail:
          'No puede superar mas de 6 transacciones en el total de transferencias enviadas'
      }
    ];
  }

  onNextStage(stage: string) {
    if (this.accountReference == true) {
      if (this.other) {
        this.formActivity.get('executive').setValidators(null);
        this.formActivity.patchValue({ executive: null });
        this.formActivity.controls['anotherExecutive'].setValidators([
          Validators.required
        ]);
        this.formActivity.controls['anotherExecutive'].updateValueAndValidity();
      } else {
        this.formActivity.get('anotherExecutive').setValidators(null);
        this.formActivity.controls['executive'].setValidators([
          Validators.required
        ]);
        this.formActivity.controls['executive'].updateValueAndValidity();
      }
      if (this.formActivity.valid) {
        this.middlewareService.submitKYCData(this.getKYCData1()).subscribe(
          (data) => {
            this.routerC.navigate(['onboarding', stage]);
          },
          (error) => {
            this.setErrorMessages(error.responseCode);
          }
        );
      } else {
        this.formActivity.markAllAsTouched();
      }
    } else {
      this.middlewareService.submitKYCData(this.getKYCData()).subscribe(
        (data) => {
          this.routerC.navigate(['onboarding', stage]);
        },
        (error) => {
          this.setErrorMessages(error.responseCode);
        }
      );
    }
  }

  private getKYCData(): KYCData {
    const form = this.formActivity.value;

    const filterOnlineBank = this.transactionalProfileTable.find(
      (item) =>
        item.title ===
        'onboarding_title_transactional_table_online_bank_ach_tranfer'
    );

    const filterTransferInternational = this.transactionalProfileTable.find(
      (item) =>
        item.title ===
        'onboarding_title_transactional_table_international_transfer_by_form'
    );

    const filterBetweenAccounts = this.transactionalProfileTable.find(
      (item) =>
        item.title ===
        'onboarding_title_transactional_table_between_bpp_accounts_transfer_by_form'
    );

    const filterTotal = this.transactionalProfileTable.find(
      (item) =>
        item.title === 'onboarding_title_transactional_table_total_credit_debit'
    );

    return {
      occupation: this.kycData.occupation,
      position: this.kycData.position,
      companyJob: this.kycData.companyJob,
      economicActivity: this.kycData.economicActivity,
      companyYears: this.kycData.companyYears,
      officeAddress: this.kycData.officeAddress,
      annualRevenue: this.kycData.annualRevenue,
      mainSourceRevenue: this.kycData.mainSourceRevenue,
      annualSalary: this.kycData.annualSalary,
      annualRent: this.kycData.annualRent,
      annualInvestments: this.kycData.annualInvestments,
      otherAnnualRevenue: this.kycData.otherAnnualRevenue,
      detailOtherRevenue: this.kycData.detailOtherRevenue,
      detailAssets: this.kycData.detailAssets,
      accountPurpose: this.kycData.accountPurpose,
      incomingTransfersMonth: form.incomingTransfersMonth,
      balanceIncomingTransfersMonth: form.balanceIncomingTransfersMonth,
      outcomingTransfersMonth: form.numberOutgoingTransfersMonth,
      balanceOutcomingTransfersMonth: form.balanceOutgoingTransfersMonth,
      expectedInitialDeposit: form.expectedInitialIncomeAmount,
      sourceWealth: this.kycData.sourceWealth,
      startDate: this.kycData.startDate,
      typeOfDeposit: this.kycData.typeOfDeposit,
      amountLimit: this.kycData.amountLimit,
      taxResidence: this.kycData.taxResidence,
      receptionReason: this.kycData.receptionReason,
      anotherReceptionReason: this.kycData.anotherReceptionReason,
      purposeSendTrans: this.kycData.purposeSendTrans,
      anotherPurposeSendTrans: this.kycData.anotherPurposeSendTrans,
      accountDetail: this.kycData.accountDetail,
      transfAchTxCred: filterOnlineBank.transactionReception,
      transfAchAmountCred: filterOnlineBank.amountReception,
      transfAchTxDeb: filterOnlineBank.sendTransaction,
      transfAchAmountDeb: filterOnlineBank.amountSend,
      transfInterTxCred: filterTransferInternational.transactionReception,
      transfInterAmountCred: filterTransferInternational.amountReception,
      transfInterTxDeb: filterTransferInternational.sendTransaction,
      transfInterAmountDeb: filterTransferInternational.amountSend,
      transfBppTxCred: filterBetweenAccounts.transactionReception,
      transfBppAmountCred: filterBetweenAccounts.amountReception,
      transfBppTxDeb: filterBetweenAccounts.sendTransaction,
      transfBppAmountDeb: filterBetweenAccounts.amountSend,
      totalTxCred: filterTotal.transactionReception,
      totalAmountCred: filterTotal.amountReception,
      totalTxDeb: filterTotal.sendTransaction,
      totalAmountDeb: filterTotal.amountSend,
      step: '4',
      profession: this.kycData.profession
    };
  }

  private getKYCData1(): KYCData {
    const form = this.formActivity.value;

    if (form.executive == 'false') {
      this.stringExecutive = null;
    } else {
      this.stringExecutive = form.executive;
    }

    if (this.stringExecutive == null) {
      this.otherExecutive = form.anotherExecutive;
    } else {
      this.otherExecutive = null;
    }

    const filterOnlineBank = this.transactionalProfileTable.find(
      (item) =>
        item.title ===
        'onboarding_title_transactional_table_online_bank_ach_tranfer'
    );

    const filterTransferInternational = this.transactionalProfileTable.find(
      (item) =>
        item.title ===
        'onboarding_title_transactional_table_international_transfer_by_form'
    );

    const filterBetweenAccounts = this.transactionalProfileTable.find(
      (item) =>
        item.title ===
        'onboarding_title_transactional_table_between_bpp_accounts_transfer_by_form'
    );

    const filterTotal = this.transactionalProfileTable.find(
      (item) =>
        item.title === 'onboarding_title_transactional_table_total_credit_debit'
    );

    return {
      occupation: this.kycData.occupation,
      position: this.kycData.position,
      companyJob: this.kycData.companyJob,
      economicActivity: this.kycData.economicActivity,
      companyYears: this.kycData.companyYears,
      officeAddress: this.kycData.officeAddress,
      annualRevenue: this.kycData.annualRevenue,
      mainSourceRevenue: this.kycData.mainSourceRevenue,
      annualSalary: this.kycData.annualSalary,
      annualRent: this.kycData.annualRent,
      annualInvestments: this.kycData.annualInvestments,
      otherAnnualRevenue: this.kycData.otherAnnualRevenue,
      detailOtherRevenue: this.kycData.detailOtherRevenue,
      detailAssets: this.kycData.detailAssets,
      accountPurpose: this.kycData.accountPurpose,
      incomingTransfersMonth: form.incomingTransfersMonth,
      balanceIncomingTransfersMonth: form.balanceIncomingTransfersMonth,
      outcomingTransfersMonth: form.numberOutgoingTransfersMonth,
      balanceOutcomingTransfersMonth: form.balanceOutgoingTransfersMonth,
      expectedInitialDeposit: form.expectedInitialIncomeAmount,
      sourceWealth: this.kycData.sourceWealth,
      startDate: this.kycData.startDate,
      typeOfDeposit: this.kycData.typeOfDeposit,
      amountLimit: this.kycData.amountLimit,
      taxResidence: this.kycData.taxResidence,
      receptionReason: this.kycData.receptionReason,
      anotherReceptionReason: this.kycData.anotherReceptionReason,
      purposeSendTrans: this.kycData.purposeSendTrans,
      anotherPurposeSendTrans: this.kycData.anotherPurposeSendTrans,
      accountDetail: this.kycData.accountDetail,
      transfAchTxCred: filterOnlineBank.transactionReception,
      transfAchAmountCred: filterOnlineBank.amountReception,
      transfAchTxDeb: filterOnlineBank.sendTransaction,
      transfAchAmountDeb: filterOnlineBank.amountSend,
      transfInterTxCred: filterTransferInternational.transactionReception,
      transfInterAmountCred: filterTransferInternational.amountReception,
      transfInterTxDeb: filterTransferInternational.sendTransaction,
      transfInterAmountDeb: filterTransferInternational.amountSend,
      transfBppTxCred: filterBetweenAccounts.transactionReception,
      transfBppAmountCred: filterBetweenAccounts.amountReception,
      transfBppTxDeb: filterBetweenAccounts.sendTransaction,
      transfBppAmountDeb: filterBetweenAccounts.amountSend,
      totalTxCred: filterTotal.transactionReception,
      totalAmountCred: filterTotal.amountReception,
      totalTxDeb: filterTotal.sendTransaction,
      totalAmountDeb: filterTotal.amountSend,
      step: '4',
      profession: this.kycData.profession,
      executive: this.stringExecutive ?? null,
      anotherExecutive: this.otherExecutive ?? null
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getExecutives() {
    this.middlewareService.getExecutives().subscribe((data: any) => {
      this.executiveList = data;
      this.executiveList.push({
        strName: 'Otro',
        strId: 'otro',
        numExecutiveCode: 0
      });
    });
  }

  validateTypeAccount() {
    if (this.accountReference == true) {
      this.formActivity.addControl(
        'executive',
        new FormControl(Validators.required)
      );
      this.formActivity.addControl(
        'anotherExecutive',
        new FormControl(Validators.required)
      );
    }
  }

  onClick() {
    if (this.executiveData == true) {
      this.accountReference = true;
    } else {
      this.accountReference = this.formActivity.value.executiveAccount;
    }
  }

  selectExec() {
    if (this.formActivity.value.executive == 'otro') {
      this.other = true;
    } else {
      this.other = false;
    }
  }
}
