import { Directive } from '@angular/core';

@Directive({
  selector: '[noFirstWhiteSpace]',
  host: { '(input)': 'onInputChange($event)' }
})
export class NoFirstSpaceDirective {
  constructor() {}

  onInputChange(event) {
    if (event) {
      if (event.target.value.substr(0, 1) === ' ') {
        event.target.value = event.target.value.substr(
          0,
          event.target.value.length - 1
        );
        return false;
      } else {
        return true;
      }
    }
  }
}
