import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RecoverModel } from '../model/recover.model';
import { PichCommonHttpService } from '../services/pichincha-common-http.service';

import { ErrorModalMessageComponent } from '../../shared/components/modals/error-modal-message/error-modal-message.component';
import {
  ERROR_GET_REQUIRED_DOCS,
  ERROR_NEW_OPPORTUNITY,
  ERROR_REQUIRED_ORIGINS,
  ERROR_SAVE_KYC_DATA,
  ERROR_SAVE_PERSONAL_DATA,
  ERROR_UPLOAD_DOCUMENT,
  OTP_ERROR,
  PATHS
} from '../../shared/constants';
import { ConfirmationService } from '../../vass-core/components/modal/confirmation.service';
import { ModalDataType } from '../../vass-core/components/modal/modal-data-type.interface';

@Component({
  selector: 'app-pich-base',
  template: '<p>pich base component</p>'
})
export class PichBaseComponent {
  userData: RecoverModel;
  functionOnpopstate: any;

  public subscription: Subscription = new Subscription();
  public otherSelected: boolean;
  responseDataError: string;

  isSecondHolder = false;
  isMultTitulares = false;

  errorData = {
    title: '',
    message: '',
    btnText: '',
    urlImg: '',
    redirectTo: false
  };

  constructor(
    private router: Router,
    private pichCommonService: PichCommonHttpService,
    protected confirmationService: ConfirmationService
  ) {
    this.functionOnpopstate = window.onpopstate;
  }

  /** Sirve para navegar al un path siguiente.
   *
   * @param stage path al que se de sea navegar
   */
  onNextStage(stage: string) {
    this.router.navigate(['onboarding', stage]);
  }

  /** Sirve para navegar al un path anterior.
   *
   * @param stage path al que se de sea navegar
   */
  onBackStage(stage: string) {
    this.router.navigate(['onboarding', stage]);
  }

  /** Devuelve el path en el que se encuentra el usuario.
   *
   */
  getAction() {
    return PATHS.welcome;
  }

  /** Devuelve el objeto que se va a mandar al servidor a través de la función getUserData
   *
   */
  getBodyPost() {
    return {
      action_method: 'Recover',
      param: '{}'
    };
  }

  setErrorMessages(responseErrorData: string) {
    if (responseErrorData) {
      switch (responseErrorData) {
        case ERROR_NEW_OPPORTUNITY.ALREADY_ONGOING_OPPORTUNITY:
          this.errorData = {
            title: 'onboarding_popup_already_ongoing_opportunity_title',
            message: 'onboarding_popup_already_ongoing_opportunity_error',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          this.redirectTo();
          break;
        case ERROR_NEW_OPPORTUNITY.ERROR_CREATING_ACCOUNT:
          this.errorData = {
            title: 'error_creating_account_title',
            message: 'error_creating_account',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_NEW_OPPORTUNITY.ERROR_CREATING_OPPORTUNITY:
          this.errorData = {
            title: 'error_creating_opportunity_title',
            message: 'error_creating_opportunity',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_NEW_OPPORTUNITY.ERROR_CREATING_OPPORTUNITY_CONTACT_ROLE:
          this.errorData = {
            title: 'error_creating_opportunity_contact_role_title',
            message: 'error_creating_opportunity_contact_role',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_NEW_OPPORTUNITY.ERROR_CREATING_OPPORTUNITY_LINE_ITEM:
          this.errorData = {
            title: 'error_creating_opportunity_line_item_title',
            message: 'error_creating_opportunity_line_item',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_NEW_OPPORTUNITY.ERROR_UPDATING_OPPORTUNITY:
          this.errorData = {
            title: 'error_updating_opportunity_title',
            message: 'error_updating_opportunity',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_NEW_OPPORTUNITY.ERROR_CREATING_DOCUMENTS_REQUIRED:
          this.errorData = {
            title: 'error_creating_documents_required_title',
            message: 'error_creating_documents_required',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_NEW_OPPORTUNITY.ERROR_CREATING_DOCUMENTS_REQUIRED2:
          this.errorData = {
            title: 'error_creating_documents_required_title',
            message: 'error_creating_documents_required',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_NEW_OPPORTUNITY.YOU_DONT_HAVE_AN_ACTIVE_PROCESS:
          this.errorData = {
            title:
              'onboarding_popup_already_ongoing_opportunity_error_you_dont_have_an_active_process_title',
            message:
              'onboarding_popup_already_ongoing_opportunity_error_you_dont_have_an_active_process',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_NEW_OPPORTUNITY.INTERNAL_SERVER_ERROR:
          this.errorData = {
            title: '',
            message: 'general_popup_error',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_NEW_OPPORTUNITY.PROCESS_HAS_BEEN_COMPLETED:
          this.errorData = {
            title: 'process_has_been_completed_title',
            message: 'process_has_been_completed',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case OTP_ERROR.OTP_EXPIRED:
          this.errorData = {
            title: 'error_invalid_otp_title',
            message: 'error_invalid_otp',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;

        case OTP_ERROR.INVALID_OTP:
          this.errorData = {
            title: 'error_invalid_otp_title',
            message: 'error_invalid_otp',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;

        case OTP_ERROR.INVALID_OTP2:
          this.errorData = {
            title: 'error_invalid_otp_title',
            message: 'error_invalid_otp',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;

        case OTP_ERROR.ERROR_UPDATING_OPPORTUNITY:
          this.errorData = {
            title: 'error_updating_opportunity_title',
            message: 'error_updating_opportunity',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;

        case OTP_ERROR.INTERNAL_SERVER_ERROR:
          this.errorData = {
            title: 'general_popup_error_title',
            message: 'general_popup_error',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;

        case ERROR_UPLOAD_DOCUMENT.DOCUMENT_NOT_FOUND:
          this.errorData = {
            title: 'error_document_not_found_title',
            message: 'error_document_not_found',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_UPLOAD_DOCUMENT.ERROR_UPDATING_DOCUMENT:
          this.errorData = {
            title: 'error_updating_document_title',
            message: 'error_updating_document',
            btnText: 'error_sign_modal_btn',
            urlImg: '',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_UPLOAD_DOCUMENT.ERROR_UPDATING_OPPORTUNITY:
          this.errorData = {
            title: '',
            message: 'onboarding_error_generic',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;

        case ERROR_GET_REQUIRED_DOCS.OPPORTUNITYID_NOT_SPECIFIED:
          this.errorData = {
            title: 'general_popup_error_title',
            message: 'general_popup_error',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_GET_REQUIRED_DOCS.OPPORTUNITY_NOT_FOUND:
          this.errorData = {
            title: 'general_popup_error_title',
            message: 'general_popup_error',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_GET_REQUIRED_DOCS.DOCUMENT_LIST_IS_EMPTY:
          this.errorData = {
            title: 'general_popup_error_title',
            message: 'general_popup_error',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;

        case ERROR_SAVE_PERSONAL_DATA.MISSING_FIELDS:
          this.errorData = {
            title: 'general_popup_error_title',
            message: 'general_popup_error',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_SAVE_PERSONAL_DATA.OPPORTUNITY_NOT_FOUND:
          this.errorData = {
            title: 'error_opportunity_not_found_title',
            message: 'error_opportunity_not_found',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_SAVE_PERSONAL_DATA.ACCOUNT_NOT_FOUND:
          this.errorData = {
            title: 'error_account_not_found_title',
            message: 'error_account_not_found',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_SAVE_PERSONAL_DATA.ERROR_UPDATING_ACCOUNT:
          this.errorData = {
            title: 'error_account_not_found_title',
            message: 'error_account_not_found',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_SAVE_PERSONAL_DATA.MISSING_VALUES_ACCOUNT_2:
          this.errorData = {
            title: 'general_popup_error_title',
            message: 'general_popup_error',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_SAVE_PERSONAL_DATA.ERROR_UPDATING_OPPORTUNITY:
          this.errorData = {
            title: 'error_updating_opportunity_title',
            message: 'error_updating_opportunity',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_SAVE_PERSONAL_DATA.INTERNAL_SERVER_ERROR:
          this.errorData = {
            title: 'general_popup_error_title',
            message: 'general_popup_error',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;

        case ERROR_SAVE_KYC_DATA.MISSING_FIELDS:
          this.errorData = {
            title: 'general_popup_error_title',
            message: 'general_popup_error',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_SAVE_KYC_DATA.OPPORTUNITY_NOT_FOUND:
          this.errorData = {
            title: 'error_opportunity_not_found_title',
            message: 'error_opportunity_not_found',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_SAVE_KYC_DATA.ACCOUNT_NOT_FOUND:
          this.errorData = {
            title: 'error_account_not_found_title',
            message: 'error_account_not_found',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_SAVE_KYC_DATA.MISSING_VALUES_ACCOUNT_3:
          this.errorData = {
            title: 'general_popup_error_title',
            message: 'general_popup_error',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_SAVE_KYC_DATA.ERROR_UPDATING_ACCOUNT:
          this.errorData = {
            title: 'error_updating_account_title',
            message: 'error_updating_account',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_SAVE_KYC_DATA.ERROR_UPDATING_OPPORTUNITY:
          this.errorData = {
            title: 'error_updating_opportunity_title',
            message: 'error_updating_opportunity',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_SAVE_KYC_DATA.INTERNAL_SERVER_ERROR:
          this.errorData = {
            title: 'general_popup_error_title',
            message: 'general_popup_error',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: true
          };
          this.showModalError(this.errorData);
          break;
        case ERROR_REQUIRED_ORIGINS.ORIGIN_NOT_FOUND_ERROR:
          this.errorData = {
            title: 'error_title_not_found_transfer',
            message: 'error_not_found_info',
            btnText: 'error_sign_modal_btn',
            urlImg: 'error_sign_modal_btn',
            redirectTo: false
          };
          this.showModalError(this.errorData);
          break;
      }
    } else {
      this.errorData = {
        title: 'general_popup_error_title',
        message: 'general_popup_error',
        btnText: 'error_sign_modal_btn',
        urlImg: 'error_sign_modal_btn',
        redirectTo: true
      };
      this.showModalError(this.errorData);
    }
  }
  redirectTo() {
    this.router.navigate(['onboarding', PATHS.welcome]);
  }

  showModalError({ title, message, btnText, urlImg, redirectTo }) {
    this.confirmationService.confirm(<ModalDataType>{
      component: ErrorModalMessageComponent,
      data: {
        title: title,
        message: message,
        btnText: btnText,
        urlImg: urlImg
      }
    });
    if (redirectTo) {
      // this.confirmationService.confirm(redirectTo());
      this.router.navigate(['onboarding', PATHS.welcome]);
    }
  }

  /** En ciertas pantallas el usuario no puede volver a la vista anterior, con este método se gestiona y
   * previene que pueda volver atrás desde el histórico, utilizando las flechas del navegador.
   *
   * @param canNavigate variable que permite o no navegar.
   */
  public canNavigateToBack(canNavigate: boolean = true) {
    if (!canNavigate) {
      history.pushState(null, null, location.href);
    }
    window.onpopstate = canNavigate
      ? this.functionOnpopstate
      : function () {
          history.go(1);
        };
  }
}
