import { ENDPOINTS } from '../constants/endpoints';
import { environment } from './../../../environments/environment';

export function isUrlException(url: string) {
  const urls = [
    `onboarding/${environment.version.onboarding}/department`,
    `onboarding/${environment.version.onboarding}/municipality`,
    `onboarding/${environment.version.onboarding}/agreement/recovery/validate`,
    `onboarding/${environment.version.onboarding}/person/ocrvalidation`,
    `certification/${environment.version.cert}/ONB/signature/otp/validate`,
    `/auth/${environment.version.auth}/password/generate`,
    `${environment.apiPath}${ENDPOINTS.generatePdf}`
  ];
  return urls.find((u) => url.includes(u));
}
