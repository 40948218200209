import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CASE_STAGES } from './../../../shared/constants';

import { PichBaseComponent } from '../pichincha-base.component';

import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { CommonDataService } from './../../../shared/services/common-data.service';
import { DetectedIdService } from './../../../shared/services/detected-id/detected-id.service';
import { GtmService } from './../../../shared/services/gtm/gtm.service';

@Component({
  selector: 'app-pich-completed',
  templateUrl: './pich-completed.component.html',
  styleUrls: ['./pich-completed.component.scss']
})
export class PichCompletedComponent
  extends PichBaseComponent
  implements OnInit
{
  caseStage = CASE_STAGES.success;
  isMultTitulares = false;
  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private commonDataService: CommonDataService,
    private gtmService: GtmService,
    private readonly detectedIdService: DetectedIdService,
    protected confirmationService: ConfirmationService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.sendEvent();
    this.isMultTitulares = this.commonDataService.isMultTitulares;
  }

  sendEvent() {
    this.detectedIdService.getOpportunity(false).subscribe((data) => {
      this.gtmService.sendVariableGTM({
        pageType: 'Registro completado',
        userId: data?.gtmId
      });
      this.gtmService.trackEvent(
        'onboarding',
        'paso 13',
        'Registro completado'
      );
    });
  }

  public onBackStage() {
    if (this.isMultTitulares) {
      this.commonDataService.setIsSecondHolder(true);
      this.commonDataService.multTitulares = true;
    }
    this.routerC.navigate(['onboarding', 'password']);
  }
}
