import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hiddenPhone'
})
export class HiddenPhonePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      return '*** *** **' + value.substr(value.length - 2);
    }
    return '';
  }
}
