import { Component, OnInit, Injector } from '@angular/core';
import { ModalComponent } from '../../../../vass-core/components/modal/modal.component';

@Component({
  selector: 'app-instruction-identity-manual',
  templateUrl: './instruction-identity-manual.component.html',
  styleUrls: ['./instruction-identity-manual.component.scss']
})
export class InstructionIdentityManualComponent implements OnInit {
  constructor(private modalComponent: ModalComponent) {}

  ngOnInit() {}

  public accept() {
    this.modalComponent.accept();
  }
}
