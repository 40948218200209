import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoaderService } from './loader.service';
import { LoaderState } from './loader.interface';

@Component({
  selector: 'angular-loader',
  templateUrl: 'loader.component.html'
})
export class LoaderComponent implements OnInit, OnDestroy {
  @ViewChild('loadingModal', { static: false })
  public loadingModal: LoaderComponent;

  public display = false;
  public message = '';

  private subscription: Subscription;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        this.display = state.show;
      }
    );
    this.subscription.add(
      this.loaderService.getMessage().subscribe((message: string) => {
        this.message = message;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
