import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ContractResponseModel } from '../../model/api/contract-response.model';
import { CustomHttpParams } from './../../../vass-core/services/custom-http-params.class';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {
  constructor(private http: HttpClient) {}

  getContract(contractId: string): Observable<ContractResponseModel> {
    const url = `${environment.apiPath}/certification/${environment.version.cert}/ONB/contract/read/onboarding?contractId=${contractId}`;
    return this.http.get<any>(url, { params: new CustomHttpParams(true) });
  }

  generateOtpToSign(body: {
    integrationId: string;
    timestamp: string;
    name: string;
    mail: string;
    cellPhoneNumber: string;
    operationId: string;
  }): Observable<ContractResponseModel> {
    const url = `${environment.apiPath}/certification/${environment.version.cert}/ONB/signature/otp/generate`;
    return this.http.post<any>(url, body, {
      params: new CustomHttpParams(true),
      responseType: 'text' as 'json'
    });
  }

  validateSignatureOtp(body: {
    opportunityId: string;
    contractId: string;
    hHolder: string;
    integrationId: string;
    timestamp: string;
    otpKey: string;
    operationId: string;
    email?: string;
  }): Observable<ContractResponseModel> {
    const url = `${environment.apiPath}/certification/${environment.version.cert}/ONB/signature/otp/validate`;
    return this.http.post<any>(url, body, {
      params: new CustomHttpParams(true),
      responseType: 'text' as 'json'
    });
  }
}
