import { FormControl } from '@angular/forms';

export function validateNoSpaces(c: FormControl) {
  let allSpacesValidator = null;

  if (c.value) {
    allSpacesValidator =
      c.value.replace(/^\s+|\s+$/g, '') !== ''
        ? null
        : {
            validateNoSpaces: true
          };
  }

  return allSpacesValidator;
}
