import { Component } from '@angular/core';
import { PATHS } from '../../../shared/constants';
import { PichBaseComponent } from '../pichincha-base.component';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StatusDocumentUpload } from '../../../shared/model/status-document-upload.model';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';

import { MiddlewareService } from '../../../../../src/app/shared/services/middleware.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { DocumentStatus } from '../../model/params.model';

@Component({
  selector: 'app-pich-contract-upload',
  templateUrl: './pich-final-step.component.html',
  styleUrls: ['./pich-final-step.component.scss']
})
export class PichFinalStepComponent extends PichBaseComponent {
  public prevStage = PATHS.contactData;
  public nextStage = PATHS.personalData1;

  public identityManualForm: FormGroup;
  public failedAttempts = 0;

  readonly maxSize = 8;

  statusDocuments = {
    w8: {} as StatusDocumentUpload,
    solicitud: {} as StatusDocumentUpload
  };

  public errorMessage = 'error_validate_documents';
  public showError = false;
  readonly maxSizeUpload = 20_000_000;

  riskMatrixError = false;

  opportunityId = new FormControl('', [Validators.required]);
  documents: DocumentStatus[] = [];

  personalData;
  externalUrl: string;
  isPanama = false;
  token: string;

  constructor(
    private routerC: Router,
    protected confirmationService: ConfirmationService,
    private pichCService: PichCommonHttpService,
    private middlewareService: MiddlewareService
  ) {
    super(routerC, pichCService, confirmationService);
  }
  ngOnInit() {
    this.loadData();
    this.token = sessionStorage.getItem('access_token');
  }

  loadData() {
    this.middlewareService.getUsersLookup().subscribe((data) => {
      this.personalData = data;
      if (this.personalData.targetCountry === 'L_IBM') {
        this.externalUrl = 'https://www.intercreditbank.com/';
      }

      if (this.personalData.targetCountry === 'L_PANBM') {
        this.externalUrl = 'https://www.pichinchapanama.com/';
        this.isPanama = true;
      }

      if (this.personalData.targetCountry === 'L_PBM') {
        this.externalUrl = 'https://www.pichinchamiami.com/';
      }
      (error) => {
        this.setErrorMessages(error.responseCode);
      };
    });
  }

  closeToken() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    window.location.href = this.externalUrl;
  }
}
