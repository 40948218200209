export const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ES_MOBILE_PHONE_REGEXP = /^[3]{1}[0-9]{9}$/;

export const SMS_REGXP = /^[0-9a-zA-Z]+$/;
// export const PASSWORD_REGXP = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&()*+,-./:;<=>?@[\]^_`{|}~]).{8}$/;
export const PASSWORD_REGXP =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$"%&()*+,-./:;<=>?@[\]^_`{|}~])(?=\S+$).{8}$/;

export const ERROR = {
  OLIMPIA_BAD_REQUEST: 'ONB2_005',
  INSPECTOR_BAD_REQUEST: 'ONB2_024',
  RISK_MATRIX: 'ONB2_025',
  RECOVER_DATA: 'ONB2_028',
  RECOVER_DATA_2: 'ONB2_029',
  REGISTERED_DOCUMENT: 'ONB2_004',
  ONBOARDING_IN_PROGRESS: 'ONB2_007'
};

export const ERROR_NEW_OPPORTUNITY = {
  ALREADY_ONGOING_OPPORTUNITY: 'ERRNO_001',
  ERROR_CREATING_ACCOUNT: 'ERRNO_002',
  ERROR_CREATING_OPPORTUNITY: 'ERRNO_003',
  ERROR_CREATING_OPPORTUNITY_CONTACT_ROLE: 'ERRNO_004',
  ERROR_CREATING_OPPORTUNITY_LINE_ITEM: 'ERRNO_005',
  ERROR_UPDATING_OPPORTUNITY: 'ERRNO_006',
  ERROR_CREATING_DOCUMENTS_REQUIRED: 'ERRNO_007',
  ERROR_CREATING_DOCUMENTS_REQUIRED2: 'ERRNO_008',
  YOU_DONT_HAVE_AN_ACTIVE_PROCESS: 'ERRNO_009',
  INTERNAL_SERVER_ERROR: 'ERRNO_010',
  PROCESS_HAS_BEEN_COMPLETED: 'ERRNO_011'
};

export const ERROR_GET_REQUIRED_DOCS = {
  OPPORTUNITYID_NOT_SPECIFIED: 'ERRRD_001',
  OPPORTUNITY_NOT_FOUND: 'ERRRD_002',
  DOCUMENT_LIST_IS_EMPTY: 'ERRRD_003'
};

export const ERROR_REQUIRED_ORIGINS = {
  ORIGIN_NOT_FOUND_ERROR: 'ERROF_001'
}

export const ERROR_UPLOAD_DOCUMENT = {
  MISSING_FIELDS: 'ERRUD_001',
  DOCUMENT_NOT_FOUND: 'ERRUD_002',
  ERROR_UPDATING_DOCUMENT: 'ERRUD_003',
  ERROR_UPDATING_OPPORTUNITY: 'ERRUD_004',
  INTERNAL_SERVER_ERROR: 'ERRUD_005'
};
export const OTP_ERROR = {
  INVALID: '902',
  OTP_NOT_GENERATED: '903',
  NUM_RETRIES_EXCEEDED: '904',
  OTP_EXPIRED: 'ERRVO_001',
  INVALID_OTP: 'ERRVO_002',
  INVALID_OTP2: 'ERRVO_003',
  ERROR_UPDATING_OPPORTUNITY: 'ERRVO_004',
  INTERNAL_SERVER_ERROR: 'ERRVO_005'
};

export const ERROR_SAVE_PERSONAL_DATA = {
  MISSING_FIELDS: 'ERRPD_001',
  OPPORTUNITY_NOT_FOUND: 'ERRPD_002',
  ACCOUNT_NOT_FOUND: 'ERRPD_003',
  ERROR_UPDATING_ACCOUNT: 'ERRPD_004',
  MISSING_VALUES_ACCOUNT_2: 'ERRPD_005',
  ERROR_UPDATING_OPPORTUNITY: 'ERRPD_006',
  INTERNAL_SERVER_ERROR: 'ERRPD_007'
};

export const ERROR_GET_PROCESS_INFO = {
  OPPORTUNITYID_NOT_SPECIFIED: 'opportunityId not specified',
  OPPORTUNITY_NOT_FOUND: 'Opportunity not found',
  ERROR_OBTAINING_DATA: 'Error obtaining data'
};
export const ERROR_SAVE_KYC_DATA = {
  MISSING_FIELDS: 'ERRKYC_001',
  OPPORTUNITY_NOT_FOUND: 'ERRKYC_002',
  ACCOUNT_NOT_FOUND: 'ERRKYC_003',
  ERROR_UPDATING_ACCOUNT: 'ERRKYC_004',
  MISSING_VALUES_ACCOUNT_3: 'ERRKYC_005',
  ERROR_UPDATING_OPPORTUNITY: 'ERRKYC_006',
  INTERNAL_SERVER_ERROR: 'ERRKYC_007'
};

export const PATHS = {
  welcome: 'welcome',
  initialData: 'initialData',
  accountOwner: 'accountOwner',
  contactData: 'contactData',
  documentType: 'documentType',
  identityManualConfirm: 'identityManualConfirm',
  videoIdentification: 'video-identification',
  personalData1: 'personalData1',
  personalDataOrigenDestiny: 'personalDataOrigenDestiny',
  personalData2: 'personalData2',
  addressData: 'addressData',
  activity: 'activity',
  activity2: 'activity2',
  activity3: 'activity3',
  activity4: 'activity4',
  signContract: 'signContract',
  viewContract: 'viewContract',
  password: 'password',
  completed: 'completed',
  locked: 'bloqueado',
  notfound: '404',
  contractUpload: 'contractUpload',
  finalStep: 'finalStep',
  documentReview: 'documentReview',
  adobeSignView: 'adobeSignView'
};

export const ACTION = {
  recover: 'Recover2Holders',
  newUser: 'NewUser',
  inspektor: 'inspektor',
  resetPassword: 'ResetPassword2Holders',
  videoIdentification: 'SaveEIDData',
  savePersonalData: 'SavePersonalData2Holders',
  saveAddress: 'SaveAddressData2Holders',
  saveKYC: 'SaveKYC2Holders',
  generatePDF: 'GenerateUploadPDFEID',
  getPDFs: 'GetPDFs',
  signProcess: 'StartSignProcess',
  signDocuments: 'SignDocuments',
  login: 'Logon',
  createClient: 'CreateClient2Holders'
};

export const CASE_STAGES = {
  newUser: 'New User',
  documentCapture: 'Document Capture',
  personalData1: 'Confirm Personal Data 1',
  personalData2: 'Confirm Personal Data 2',
  address: 'Address Entry',
  kyc: 'KYC Questions',
  acceptingDocs: 'Accepting docs',
  success: 'Success signature',
  signDocuments: 'SignDocuments'
};

export const CURRENT_POSITION = {
  EMPLOYMENT: 'ASALARIADO',
  FREELANCE: 'INDEPENDIENTE',
  RETIRED: 'JUBILADO'
};

export const HOLDER = {
  PRIMARY: 'Primary Owner',
  SECONDARY: 'Additional Owner'
};

export const USER_DATA_CASE = 'userDataCase';

export const OLIMPIA_EVENTS = {
  COMPLETED: 'resultData',
  TIME_OUT: 'assistance'
};

export const ACROBAT_SIGN_STATE = {
  ESIGN: 'ESIGN',
  REJECT: 'REJECT',
  PREFILL: 'PREFILL'
};

export const ACROBAT_SIGN_ORIGIN = 'https://secure.na4.adobesign.com';

export const NEXT_SCREEN = {
  FINAL_STEP: 'FINAL',
  PERSONAL_DATA: 'PERSONAL_DATA',
  DOCS_AND_PERSONAL: 'DOCS_&_PERSONAL_DATA'
};
