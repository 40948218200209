import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { finalize } from 'rxjs/internal/operators/finalize';

import { CountryModel } from '../../model/api/country.model';
import { MiddlewareService } from '../middleware.service';

@Injectable({
  providedIn: 'root'
})
export class ToolboxService {
  private countries$ = new BehaviorSubject<Array<CountryModel>>([]);
  private isFetchingCountries = false;

  constructor(private middlewareService: MiddlewareService) {
    this.fetchCountriesIfEmpty();
  }

  private fetchCountriesIfEmpty() {
    if (this.countries$.value.length === 0 && !this.isFetchingCountries) {
      this.fetchCountries();
    }
  }

  private fetchCountries() {
    this.isFetchingCountries = true;

    this.middlewareService
      .getCountries()
      .pipe(
        catchError((error) => {
          console.error('Failed to fetch countries:', error);
          return [];
        }),
        finalize(() => {
          this.isFetchingCountries = false;
        })
      )
      .subscribe((data) => {
        this.countries$.next(data);
      });
  }

  getCountries(): Observable<Array<CountryModel>> {
    return this.countries$.asObservable();
  }
}
