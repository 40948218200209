import { Component, OnDestroy, OnInit } from '@angular/core';
import { HOLDER, PATHS } from '../../../shared/constants';
import { PichBaseComponent } from '../pichincha-base.component';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { StatusDocumentUpload } from '../../../shared/model/status-document-upload.model';
import { MiddlewareService } from '../../../shared/services/middleware.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { DocumentStatus, FileDTO } from '../../model/params.model';

@Component({
  selector: 'app-pich-contract-upload',
  templateUrl: './pich-contract-upload.component.html',
  styleUrls: ['./pich-contract-upload.component.scss']
})
export class PichContractUploadComponent
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  public prevStage = PATHS.contactData;
  public nextStage = PATHS.finalStep;

  public identityManualForm: FormGroup;
  public failedAttempts = 0;

  readonly maxSize = 8;

  statusDocuments = {
    w8: {} as StatusDocumentUpload,
    solicitud: {} as StatusDocumentUpload
  };

  public errorMessage = 'error_validate_documents';
  public showError = false;
  readonly maxSizeUpload = 20_000_000;

  riskMatrixError = false;

  opportunityId = new FormControl('', [Validators.required]);
  documents: DocumentStatus[] = [];

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    private formBuilder: FormBuilder,
    private middlewareService: MiddlewareService,
    protected confirmationService: ConfirmationService,
    private commonDataService: CommonDataService,
    gtmService: GoogleTagManagerService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.crearteForm();
    this.isSecondHolder = this.commonDataService.isSecondHolder;
    this.isMultTitulares = this.commonDataService.isMultTitulares;
    this.pichCService.getDocumentsStatus().subscribe((data) => {
      this.documents = data.filter(
        (doc) =>
          doc.generationType === 'Automática' &&
          (doc.documentCode === 'DOC-07' || doc.documentCode === 'DOC-08')
      );
    });
  }

  private crearteForm() {
    this.identityManualForm = this.formBuilder.group({
      w8: ['', Validators.required],
      solicitud: ['', Validators.required]
    });
  }

  public onUploadFiles() {
    if (this.identityManualForm.valid) {
      let eLabel = 'confirmar documento';
      if (this.isMultTitulares) {
        eLabel = this.isSecondHolder
          ? 'confirmar documento titular 2'
          : 'confirmar documento titular 1';
      }

      //this.saveOlimpiaData();
    }
  }

  get params(): any {
    return {
      opportunityId: this.pichCService.getStrOppId(),
      selfie: this.identityManualForm.value.w8.base64,
      selfieFormat: this.identityManualForm.value.w8.type,
      anverse: this.identityManualForm.value.solicitud.base64,
      anverseFormat: this.identityManualForm.value.solicitud.type,
      reverse: this.identityManualForm.value.reverso.base64,
      reverseFormat: this.identityManualForm.value.reverso.type,
      documentType:
        this.userData.clsResponseSavePersonalData.strDocumentType ===
        'Cédula de extranjería'
          ? 'CE'
          : 'CC',
      IdentificationNumber:
        this.userData.clsResponseSavePersonalData.strDocumentNumber,
      holder: this.isSecondHolder ? HOLDER.SECONDARY : HOLDER.PRIMARY
    };
  }

  private parseFileDto(event: StatusDocumentUpload, type: string): FileDTO {
    return {
      filename: type,
      fileExtension: event.type,
      base64File: event.base64,
      documentType: this.getDocType(type)
    };
  }

  private getDocType(type: string) {
    return type === 'solicitud' ? 'DOC-07' : 'DOC-08';
  }

  private handleError(event: StatusDocumentUpload, type: string) {
    this.identityManualForm.controls[type].setValue('');
    this.statusDocuments[type].status = 'ko';
    this.statusDocuments[type].errorMessage = 'ko';
  }

  docExists(docType: string) {
    return (
      this.documents.filter((doc) => doc.documentCode === docType).length > 0
    );
  }

  uploadImg(event: StatusDocumentUpload, type: string) {
    if (event.statusUpload === 'ok') {
      this.middlewareService
        .uploadFile(this.parseFileDto(event, type))
        .subscribe(
          (data) => {
            this.identityManualForm.controls[type].setValue(event);
            this.statusDocuments[type].status = 'ok';
            this.statusDocuments[type].errorMessage = '';
          },
          (error) => {
            this.handleError(event, type);

            this.setErrorMessages(error.responseCode);
          }
        );
    } else {
      this.handleError(event, type);
    }

    try {
      if (JSON.stringify(this.params).length > 1000000) {
        this.errorMessage =
          'El tamaño de todas las imágenes no puede superar 1Mb, por favor reduce el tamaño de las imágenes.';
      } else {
        this.errorMessage = '';
      }
    } catch (e) {}
  }

  uploadFiles() {
    if (this.identityManualForm.valid) {
      this.routerC.navigate(['onboarding', this.nextStage]);
    }
  }

  public onBackStage(stage: string) {
    if (this.isMultTitulares) {
      this.routerC.navigate(['onboarding', PATHS.contactData]);
    } else {
      this.routerC.navigate(['onboarding', stage]);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
