import {
  Component,
  ViewChild,
  EventEmitter,
  OnDestroy,
  ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmationService } from './confirmation.service';
import { ModalDataType } from './modal-data-type.interface';
import { ModalService } from './modal.service';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnDestroy {
  @ViewChild('modalContainer', { static: false })
  public modalContainer: ModalComponent; // 'modalContainer' is the identifier #modalContainer in the template
  @ViewChild('dynamicContent', { static: false, read: ViewContainerRef })
  viewContainerRef: ViewContainerRef;
  private subscription: Subscription;
  private confirmation: ModalDataType;
  private isAccepted = false;
  private modalInstance: any;
  public display = false;
  public component: any;
  public showHeader = true;
  public header: string;
  public closeOnEscape: boolean;
  public closable: boolean;
  public styleClass: string;
  public defaultButtons: boolean;
  public acceptButton: boolean;
  public cancelButton: boolean;
  public draggable: boolean;
  public data: any;
  constructor(
    private _confirmationService: ConfirmationService,
    private modalService: ModalService
  ) {
    this.subscription = _confirmationService.requireConfirmation$.subscribe(
      (confirmation) => {
        this.clearContent();
        this.confirmation = confirmation;
        this.component = confirmation.component;
        this.header = confirmation.options?.header || '';
        this.showHeader = confirmation?.options?.showHeader
          ? confirmation.options?.showHeader
          : true;

        this.closeOnEscape = confirmation?.options?.closeOnEscape
          ? confirmation.options.closeOnEscape
          : false;

        this.closable = confirmation?.options?.closable
          ? confirmation.options.closable
          : true;

        this.styleClass = confirmation?.options?.styleClass
          ? confirmation.options.styleClass
          : '';

        this.draggable = confirmation?.options?.draggable
          ? confirmation.options.draggable
          : true;
        this.data = confirmation?.data ? confirmation.data : null;
        this.handleButtons(confirmation);
        this.showModal('modalContainer');

        this.confirmation.acceptEvent = new EventEmitter();
        this.confirmation.rejectEvent = new EventEmitter();

        if (this.confirmation.accept) {
          this.confirmation.acceptEvent.subscribe(this.confirmation.accept);
        }

        if (this.confirmation.reject) {
          this.confirmation.rejectEvent.subscribe(this.confirmation.reject);
        }

        this.subscription.add(
          this.modalService
            .getHideModal()
            .subscribe(() => this.hide(this.modalInstance))
        );
      }
    );
  }
  private handleButtons(confirmation: any) {
    this.acceptButton = confirmation?.options?.acceptButton
      ? confirmation.options.acceptButton
      : false;
    this.cancelButton = confirmation?.options?.cancelButton
      ? confirmation.options.cancelButton
      : false;
    if (this.cancelButton || this.acceptButton) {
      this.defaultButtons = true;
    }
  }
  private show(modal: ModalComponent) {
    modal.display = true;
  }
  private hide(modal: ModalComponent) {
    modal.display = false;
  }
  private clearContent() {
    this.viewContainerRef.clear();
    this.isAccepted = false;
  }
  private setOptions(modal: ModalComponent) {
    modal.header = this.header;
    modal.showHeader = this.showHeader;
    modal.closeOnEscape = this.closeOnEscape;
    modal.closable = this.closable;
    modal.styleClass = this.styleClass;
  }
  showModal(modal: string) {
    this.modalService.setRootViewContainerRef(this.viewContainerRef);
    this.modalService.addDynamicComponent(this.component);
    this.modalInstance = this[modal];
    this.setOptions(this.modalInstance);
    this.show(this.modalInstance);
  }
  sendDataFromModal(data: any, options: any) {
    if (this.confirmation.acceptEvent) {
      this.isAccepted = true;
      this.confirmation.acceptEvent.emit(data);
    }
    if (options && options.hide) {
      this.hide(this.modalInstance);
    }
  }
  accept() {
    if (this.confirmation.acceptEvent) {
      this.confirmation.acceptEvent.emit();
    }

    this.isAccepted = true;
    this.hide(this.modalInstance);
    this.confirmation = null;
  }

  reject() {
    this.isAccepted = false;
    this.hide(this.modalInstance);
  }
  onHide() {
    if (!this.isAccepted) {
      this.confirmation.rejectEvent.emit();
      this.confirmation = null;
    }
    this.clearContent();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
