import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDocumentNumber]',
  host: { '(input)': 'onInputChange($event)' }
})
export class DocumentNumberDirective {
  private lastValue = '';
  constructor(
    private ngControl: NgControl,
    private decimalPipe: DecimalPipe,
    private elementRef: ElementRef
  ) {}

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    if (value) {
      if (!value.match(/^[0-9.]*$/)) {
        this.ngControl.control.patchValue(this.lastValue);
        return;
      }

      this.lastValue = value;
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onblur(value: string) {
    value = value.replace(/\./g, '');
    this.ngControl.control.patchValue(value);

    this.element.value = !isNaN(parseInt(value))
      ? this.decimalPipe.transform(value, `1.0-0`)
      : '';
  }

  get element() {
    return this.elementRef.nativeElement;
  }
}
