import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { filter, switchMap } from 'rxjs/operators';

import * as FileSaver from 'file-saver';

import { ConfirmationService } from '../../../vass-core/components/modal/confirmation.service';
import { PichCommonHttpService } from '../../services/pichincha-common-http.service';
import { CommonDataService } from './../../../shared/services/common-data.service';
import { DetectedIdService } from './../../../shared/services/detected-id/detected-id.service';
import { GtmService } from './../../../shared/services/gtm/gtm.service';
import { SignatureService } from './../../../shared/services/signature/signature.service';

import { ApiError } from '../../model/api/api-error';
import { PATHS } from './../../../shared/constants';
import { OperationId } from './../../../shared/model/api/operation-id.enum';

import { MiddlewareService } from '../../../shared/services/middleware.service';
import { DocumentStatus, GeneratePdf } from '../../model/params.model';
import { PichBaseComponent } from '../pichincha-base.component';
import { PichModalGenericComponent } from './../../../shared/components/modals/pich-modal-generic/pich-modal-generic.component';

@Component({
  selector: 'app-pich-contract-view',
  templateUrl: './pich-contract-view.component.html',
  styleUrls: ['./pich-contract-view.component.scss']
})
export class PichContractViewComponent
  extends PichBaseComponent
  implements OnInit, OnDestroy
{
  pdf: any;
  pdfURL: any;
  pdfBlob: any;
  titlePDF: string;

  page = 1;
  rotation = 0;
  zoom = 1.0;
  renderText = true;
  isLoaded = false;
  stickToPage = false;
  showAll = true;
  autoresize = true;
  fitToPage = true;
  orginSz = false;

  isWorking = false;
  msgLoading = '';
  errorContract = false;

  showOtpModal = false;
  errorOtp: ApiError;
  documents: DocumentStatus[] = [];
  documentsURLs: string[] = [];
  count = 0;
  isButtonDisabled: boolean = true;

  public nextStage = PATHS.contractUpload;

  constructor(
    private routerC: Router,
    private pichCService: PichCommonHttpService,
    protected confirmationService: ConfirmationService,
    private commonDataService: CommonDataService,
    private readonly signatureService: SignatureService,
    private readonly detectedIdService: DetectedIdService,
    private gtmService: GtmService,
    private middlewareService: MiddlewareService
  ) {
    super(routerC, pichCService, confirmationService);
  }

  ngOnInit() {
    this.canNavigateToBack(false);

    this.pichCService.getDocumentsStatus().subscribe((data) => {
      this.documents = data.filter((doc) => doc.generationType !== 'Manual');
      this.downloadSrcPdf();
    });
  }

  downloadSrcPdf() {
    this.middlewareService
      .getFilenameReference(this.documents[this.count].s3Reference)
      .subscribe((data) => {
        this.middlewareService
          .getBlob(data.downloadUrl)
          .subscribe((pdfData) => {
            this.generateContract(data, pdfData, this.documents[this.count]);
          });
      });
  }

  ngOnDestroy() {
    this.canNavigateToBack(true);
    this.subscription.unsubscribe();
  }

  generateContract(data: string, blob: any, doc: DocumentStatus) {
    this.pdf = data;
    this.titlePDF = doc.documentName;
    this.pdfBlob = blob;
    const url = URL.createObjectURL(blob);
    this.pdfURL = url;
  }

  pdfLoaded() {
    console.log('here');
  }

  public downloadPdf() {
    if (this.titlePDF && this.pdfBlob) {
      this.titlePDF = this.titlePDF + '.pdf';
      if (window.navigator && window.navigator['msSaveOrOpenBlob']) {
        const newBlob = new Blob([this.pdfBlob], { type: 'application/pdf' });
        window.navigator['msSaveOrOpenBlob'](newBlob, this.titlePDF);
        return;
      } else {
        FileSaver.saveAs(
          new File([this.pdfBlob], this.titlePDF, { type: 'application/pdf' }),
          this.titlePDF
        );
      }
      this.isButtonDisabled = false;
    }
  }

  generateOtp(showModal: boolean) {
    this.subscription.add(
      this.detectedIdService
        .getOpportunity(this.isSecondHolder)
        .pipe(
          filter((value) => value !== undefined),
          switchMap((opportunity) =>
            this.signatureService.generateOtpToSign({
              integrationId: opportunity.integrationId,
              timestamp: opportunity.timeStamp,
              name: this.userData.clsResponseSavePersonalData.strFirstName
                ? this.userData.clsResponseSavePersonalData.strFirstName
                : this.userData.clsResponseSavePersonalData.strFirstLastName,
              mail: this.userData.clsResponseSavePersonalData.strEmail,
              cellPhoneNumber:
                this.userData.clsResponseSavePersonalData.strPhone,
              operationId: String(OperationId.contractSign)
            })
          )
        )
        .subscribe(() => {
          if (showModal) {
            this.showOtpModal = true;
          }
        })
    );
  }

  signDocumentsWithOTP(otpKey: string) {
    this.subscription.add(
      this.detectedIdService
        .getOpportunity(this.isSecondHolder)
        .pipe(
          switchMap((opportunity) =>
            this.signatureService.validateSignatureOtp({
              opportunityId: this.pichCService.getStrOppId(),
              contractId: this.userData.clsContract.contractId,
              hHolder: this.userData.holder,
              integrationId: opportunity.integrationId,
              timestamp: opportunity.timeStamp,
              otpKey: otpKey,
              operationId: String(OperationId.contractSign),
              email: this.userData.clsResponseSavePersonalData.strEmail
            })
          )
        )
        .subscribe(
          () => {
            if (otpKey) {
              if (this.isMultTitulares) {
                if (this.commonDataService.isSecondHolder) {
                  this.routerC.navigate(['onboarding', 'password']);
                  this.commonDataService.setIsSecondHolder(false);
                } else {
                  this.commonDataService.setIsSecondHolder(true);
                  this.ngOnInit();
                  window.scrollTo(0, 0);
                }
                this.closeOtpModal();
              } else {
                this.routerC.navigate(['onboarding', 'password']);
              }
            }
          },
          (error) => {
            this.setErrorMessages(error.responseCode);
            this.errorOtp = error;
          }
        )
    );
  }

  closeOtpModal() {
    this.showOtpModal = false;
    this.errorOtp = undefined;
  }

  getAction() {
    return PATHS.viewContract;
  }

  onNextStage() {
    if (this.count < this.documents.length - 1) {
      this.count++;
      this.isButtonDisabled = true;
      this.downloadSrcPdf();
    } else {
      this.routerC.navigate(['onboarding', this.nextStage]);
    }
    // if (!this.isWorking) {
    //   // this.gtmService.sendVariableGTM({
    //   //   pageType: 'Revisión de contrato'
    //   // });
    //   // this.gtmService.trackEvent(
    //   //   'onboarding',
    //   //   'paso 11',
    //   //   'Revisión de contrato'
    //   // );
    //   //this.generateOtp(true);
    // }
  }

  showModalError() {
    this.errorContract = true;
    this.confirmationService.confirm({
      component: PichModalGenericComponent,
      data: {
        title: 'onboarding_error_load_contract_title',
        message: 'onboarding_error_load_contract_message',
        txtButton: 'onboarding_error_load_contract_btn'
      },
      accept: (data) => {
        this.ngOnInit();
      }
    });
  }
}
